import React from "react";

interface CoinsIconProps {
	className?: string;
}

const CoinsIcon: React.FC<CoinsIconProps> = ({ className }) => (
	<svg
		className={`fill-current ${className}`}
		width="18"
		height="18"
		viewBox="0 0 18 18"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_3248_2507)">
			<path
				d="M10.7398 13.3809V15.4328C10.7398 16.435 8.64204 17.5505 5.62965 17.5505C2.61925 17.5505 0.519531 16.435 0.519531 15.4328V13.3809C1.53361 14.2438 3.42057 14.8125 5.62965 14.8125C7.83873 14.8125 9.7257 14.2438 10.7398 13.3809Z"
				fill="#E2CC0D"
			/>
			<path
				d="M10.7398 9.84668V11.8987C10.7398 12.9008 8.64204 14.0163 5.62965 14.0163C2.62124 14.0163 0.523512 12.9028 0.519531 11.9026V9.84668C1.53361 10.7116 3.42057 11.2803 5.62965 11.2803C7.83873 11.2803 9.7257 10.7116 10.7398 9.84668Z"
				fill="#E2CC0D"
			/>
			<path
				d="M10.7398 8.36766C10.7398 9.36979 8.64204 10.4853 5.62965 10.4853C2.62522 10.4853 0.527493 9.37377 0.519531 8.37362V8.36766C0.519531 8.36567 0.519531 8.36567 0.519531 8.36567C0.523512 7.36353 2.62124 6.24805 5.62965 6.24805C8.64204 6.24805 10.7398 7.36552 10.7398 8.36766Z"
				fill="#E2CC0D"
			/>
			<path
				d="M17.4805 2.56782C17.4805 3.56995 15.3808 4.68742 12.3704 4.68742C9.35798 4.68742 7.26025 3.56995 7.26025 2.56782C7.26025 1.56567 9.35798 0.450195 12.3704 0.450195C15.3808 0.450195 17.4805 1.56567 17.4805 2.56782Z"
				fill="#E2CC0D"
			/>
			<path
				d="M17.4805 4.04883V6.10282C17.4765 7.10495 15.3788 8.21845 12.3704 8.21845C12.084 8.21845 11.8017 8.20453 11.5233 8.18465C11.3702 6.91408 9.64033 5.88807 7.26025 5.56199V4.04883C8.27432 4.91179 10.1613 5.48245 12.3704 5.48245C14.5795 5.48245 16.4664 4.91179 17.4805 4.04883Z"
				fill="#E2CC0D"
			/>
			<path
				d="M17.4804 7.58008V9.63606C17.4764 10.6362 15.3787 11.7517 12.3703 11.7517C12.0879 11.7517 11.8096 11.7378 11.5352 11.7179V8.98188C11.8115 9.00177 12.0879 9.0137 12.3703 9.0137C14.5794 9.0137 16.4663 8.44503 17.4804 7.58008Z"
				fill="#E2CC0D"
			/>
			<path
				d="M17.4804 11.1133V13.1653C17.4784 13.6663 16.9535 14.1953 16.0527 14.5989C15.152 15.0045 13.8755 15.2829 12.3703 15.2829C12.0859 15.2829 11.8076 15.269 11.5352 15.2491V12.5151C11.8115 12.535 12.0879 12.5469 12.3703 12.5469C14.5794 12.5469 16.4663 11.9762 17.4804 11.1133Z"
				fill="#E2CC0D"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3248_2507">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default CoinsIcon;
