import LinkButton from "@/common/components/design/buttons/link-button";
import Typography from "@/common/components/design/texts/typography";
import FightIdLogo from "@/common/components/logo/fightid";
import { useUserStore } from "@/common/hooks/use-user-store";
import { useSignUpMutation } from "@/common/services/auth.api";
import { Routes } from "@/common/types/routes";
import logger from "@/common/utils/logger";
import VerifyEmailForm from "@/modules/login/components/form/verify/verify-email-form";
import { useLoadingStore } from "@/modules/login/hooks/use-loading";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

function VerifyEmailView() {
	const navigate = useNavigate();
	const otpVerified = useLoadingStore((store) => store.otpVerified);
	const userEmail = useUserStore((store) => store.user.email);

	const [resendOtp, { isLoading }] = useSignUpMutation();

	useEffect(() => {
		if (otpVerified) {
			navigate(Routes.AuthLoading);
		}
	}, [navigate, otpVerified]);

	const handleResendOtp = async () => {
		logger.info(userEmail, "onRegisterSubmit");

		try {
			// Prevent multiple emails
			if (isLoading) return;

			const signupPromise = resendOtp({ email: userEmail });

			toast.promise(signupPromise, {
				loading: "Sending OTP email...",
				success: (data) => {
					if (data.error) {
						throw "Failed to send OTP email. Please try again.";
					}

					return "OTP Email Sent";
				},
				error: (error) => {
					return error;
				},
			});
		} catch (error) {
			logger.error(error);
			toast.error("OTP Email Failed", {
				description: "Please try again.",
			});
		}
	};

	return (
		<div className="w-full flex flex-col gap-y-8 py-4 h-full justify-end md:justify-start">
			<div className="text-center px-6 space-y-2">
				<Typography variant="subtitle" className="font-bold text-xl">
					WELCOME TO
				</Typography>
				<div className="flex justify-center">
					<FightIdLogo width={340} height={60} />
				</div>
			</div>

			<div className="flex flex-col justify-end md:justify-start items-center pb-6 mt-14 space-y-4">
				<Typography variant="subtitle">Enter one-time passcode</Typography>
				<Typography variant="body">
					Enter the 6-digit code we sent to your email.
				</Typography>

				<VerifyEmailForm />

				<div className="flex flex-col gap-y-0">
					<Typography variant="body">Didn’t get a passcode?</Typography>
					<LinkButton
						className="underline h-auto"
						size="sm"
						onClick={handleResendOtp}
					>
						Re-send one-time password
					</LinkButton>
				</div>
			</div>
		</div>
	);
}

export default VerifyEmailView;
