import { cn } from "@/common/lib/utils";
import { Button, ButtonProps } from "@/common/components/ui/button";
import { Loader2 } from "lucide-react";

interface MainButtonProps extends ButtonProps {
	children: React.ReactNode;
	isLoading?: boolean;
	isLoadingLabel?: string;
}

function MainButton({
	children,
	isLoading = false,
	isLoadingLabel = "Loading...",
	className,
	type = "button",
	variant = "default",
	size = "default",
	disabled,
	...props
}: MainButtonProps) {
	return (
		<Button
			type={type}
			variant={variant}
			className={cn(
				"uppercase w-full text-base h-16 font-bold max-w-[400px]",
				"disabled:bg-zinc-500/90",
				"rounded-none",
				className
			)}
			disabled={disabled || isLoading}
			{...props}
		>
			{isLoading && <Loader2 className="animate-spin" />}
			{isLoading ? isLoadingLabel : children}
		</Button>
	);
}

export default MainButton;
