import { WelcomeBanner } from "@/common/components/banner/welcome-banner";
import { UserBalance } from "@/common/components/user/user-balance";
import { OldQuest } from "@/common/types/entities/quest/quest";
import { QuestCard } from "../../components/cards/quest";

const mockFight: OldQuest[] = [
	{
		id: "1",
		status: "OPEN",
		deadline: "ENDS IN 12 DAYS",
		title: "ENABLE NOTIFICATIONS",
		description: "Get Exclusive Updates and New Quests",
		reward: {
			amount: 20,
			currency: "$FF",
			status: "CLAIMABLE",
			totalPrize: 2000000,
		},
		event: {
			details: {
				connection: "Connect your wallet",
			},
			time: "Starts in 3 hours",
			instructions:
				"Unlock the Power of Web3! Connect Your Wallet to Get Started",
			cta: {
				label: "Connect Wallet",
				url: "/claim-connect",
			},
		},
		bgImage: "",
		sponsor: "UFC-Strike",
	},
	{
		id: "2",
		status: "UPCOMING",
		deadline: "Starts in 3 hours",
		title: "Connect Your Wallet",
		description: "Unlock the Power of Web3! Connect Your Wallet to Get Started",
		reward: {
			amount: 100,
			currency: "$FF",
			status: "NOT_CLAIMABLE",
			totalPrize: 200000,
		},
		bgImage: "",
		sponsor: "UFC-Strike",
		event: {
			details: {
				connection: "Connect your wallet",
			},
			time: "Starts in 3 hours",
			instructions:
				"Unlock the Power of Web3! Connect Your Wallet to Get Started",
			cta: {
				label: "Connect Wallet",
				url: "/claim-connect",
			},
		},
	},
	{
		id: "4",
		status: "COMPLETED",
		deadline: "Ends in 4 hours",
		title: "Connect Your Wallet",
		description: "Unlock the Power of Web3! Connect Your Wallet to Get Started",
		reward: {
			amount: 100,
			currency: "$FF",
			status: "CLAIMABLE",
			totalPrize: 200000,
		},
		sponsor: "UFC-Strike",
		bgImage: "",
		event: {
			details: {
				connection: "Connect your wallet",
			},
			time: "Starts in 3 hours",
			instructions:
				"Unlock the Power of Web3! Connect Your Wallet to Get Started",
			cta: {
				label: "Connect Wallet",
				url: "/claim-connect",
			},
		},
	},
	{
		id: "3",
		status: "COMPLETED",
		deadline: "Ends in 3 hours",
		title: "Connect Your Wallet",
		description: "Unlock the Power of Web3! Connect Your Wallet to Get Started",
		reward: {
			amount: 100,
			currency: "$FF",
			status: "CLAIMED",
			totalPrize: 200000,
		},
		sponsor: "UFC-Strike",
		bgImage: "",
		event: {
			details: {
				connection: "Connect your wallet",
			},
			time: "Starts in 3 hours",
			instructions:
				"Unlock the Power of Web3! Connect Your Wallet to Get Started",
			cta: {
				label: "Connect Wallet",
				url: "/claim-connect",
			},
		},
	},
	{
		id: "5",
		status: "EXPIRED",
		deadline: "Quest ended",
		title: "Select your pick for next weekend fight",
		description: "",
		reward: {
			amount: 100,
			currency: "$FF",
			status: "CLAIMABLE",
			totalPrize: 200000,
		},
		sponsor: "UFC-Strike",
		bgImage: "",
		event: {
			details: {
				connection: "Connect your wallet",
			},
			time: "Starts in 3 hours",
			instructions:
				"1. Unlock the Power of Web3! Connect Your Wallet to Get Started",
			cta: {
				label: "Connect Wallet",
				url: "/claim-connect",
			},
		},
	},
];

function QuestsHomeView() {
	return (
		<div className="flex flex-col w-full h-full gap-y-4">
			<UserBalance ffBalance="100" fightBalance="25" />
			<WelcomeBanner
				title={"GET READY FOR THE NEXT $FIGHT AIRDROP!"}
				description={"COMPLETE MORE QUESTS FOR BIGGER REWARDS!"}
			/>
			<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{mockFight.map((quest) => (
					<QuestCard key={quest.id} quest={quest} onSelect={() => {}} />
				))}
			</div>
		</div>
	);
}

export default QuestsHomeView;
