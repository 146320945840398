import { backUrl } from "@/environment";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	LoginRequestDto,
	LoginResponseDto,
	GetSiwaResponseDto,
	PostSiwaCallbackRequestDto,
	PostSiwaCallbackResponseDto,
} from "../types/entities/auth/dto/login.dto";
import {
	SignUpRequestDto,
	SignUpResponseDto,
} from "../types/entities/auth/dto/signup.dto";
import {
	VerifyTokenRequestDto,
	VerifyTokenResponseDto,
} from "../types/entities/auth/dto/verify-token.dto";
import {
	OAuthLoginRequestDto,
	OAuthLoginResponseDto,
	OAuthTokenRequestDto,
	OAuthTokenResponseDto,
} from "../types/entities/auth/dto/oauth.dto";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${backUrl}/`,
	}),
	endpoints: (builder) => ({
		logInUser: builder.mutation<LoginResponseDto, LoginRequestDto>({
			query: (body) => ({
				url: `auth/login`,
				method: "POST",
				body,
			}),
		}),
		signUp: builder.mutation<SignUpResponseDto, SignUpRequestDto>({
			query: (body) => ({
				url: `auth/signin`,
				method: "POST",
				body,
			}),
		}),
		verifyToken: builder.mutation<
			VerifyTokenResponseDto,
			VerifyTokenRequestDto
		>({
			query: (body) => ({
				url: `auth/verifyToken`,
				method: "POST",
				body,
			}),
		}),
		getSiwa: builder.query<GetSiwaResponseDto, void>({
			query: () => ({
				url: `auth/siwa`,
				method: "GET",
			}),
		}),
		siwaCallback: builder.mutation<
			PostSiwaCallbackResponseDto,
			PostSiwaCallbackRequestDto
		>({
			query: (body) => ({
				url: `auth/siwa/callback`,
				method: "POST",
				body,
			}),
		}),
		exchangeOktaToken: builder.mutation<
			PostSiwaCallbackResponseDto,
			{ token: string }
		>({
			query: (body) => ({
				url: `auth/exchangeToken`,
				method: "POST",
				body,
			}),
		}),
		initiateOAuthLogin: builder.mutation<
			OAuthLoginResponseDto,
			OAuthLoginRequestDto
		>({
			query: (credentials) => ({
				url: "/oauth/authorize",
				method: "GET",
				params: {
					client_id: credentials.clientId,
					redirect_uri: credentials.redirectUri,
					scope: credentials.scope,
					state: credentials.state,
				},
				headers: {
					Authorization: `Bearer ${credentials.token}`,
				},
			}),
		}),
		exchangeCodeForTokens: builder.mutation<
			OAuthTokenResponseDto,
			OAuthTokenRequestDto
		>({
			query: (credentials) => ({
				url: "/oauth/token",
				method: "POST",
				body: credentials,
			}),
		}),
		refreshToken: builder.mutation<OAuthTokenResponseDto, OAuthTokenRequestDto>(
			{
				query: (credentials) => ({
					url: "/oauth/token",
					method: "POST",
					body: {
						...credentials,
						grantType: "refresh_token",
					},
				}),
			}
		),
		revokeToken: builder.mutation<void, { token: string }>({
			query: ({ token }) => ({
				url: "/oauth/revoke",
				method: "POST",
				body: { token },
			}),
		}),
	}),
});

export const {
	useLogInUserMutation,
	useSignUpMutation,
	useVerifyTokenMutation,
	useLazyGetSiwaQuery,
	useSiwaCallbackMutation,
	useExchangeOktaTokenMutation,
	useInitiateOAuthLoginMutation,
	useExchangeCodeForTokensMutation,
	useRefreshTokenMutation,
	useRevokeTokenMutation,
} = authApi;
