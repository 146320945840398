import Typography from "@/common/components/design/texts/typography";
import MainButton from "@/common/components/design/buttons/button";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useOAuth } from "../../hooks/use-oauth";
import { useEffect } from "react";
import { Routes } from "@/common/types/routes";
import OAuthScopeList from "../../components/oauth/oauth-scope-list";
import { useUserStore } from "@/common/hooks/use-user-store";
import { logger } from "@/common/utils/logger";
import BaseCard from "@/common/components/design/card/base-card";
import { useOAuthStore } from "../../hooks/use-oauth-store";

function OAuthConsentView() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const user = useUserStore((store) => store.user);
	const { initiateOAuthFlow, isLoading, isAuthenticated } = useOAuth();
	const clearOAuthParams = useOAuthStore((state) => state.clearParams);

	const clientId = searchParams.get("client_id");
	const redirectUri = searchParams.get("redirect_uri");
	const scope = searchParams.get("scope");
	const state = searchParams.get("state");

	// Clear params when component unmounts or if invalid params
	useEffect(() => {
		if (!clientId || !redirectUri) {
			clearOAuthParams();
			navigate(Routes.Landing);
		}

		return () => {
			clearOAuthParams();
		};
	}, [clientId, redirectUri, navigate, clearOAuthParams]);

	const handleConsent = async (approved: boolean) => {
		if (!clientId || !redirectUri) return;

		try {
			await initiateOAuthFlow({
				clientId,
				redirectUri,
				scope: scope || "",
				state: state || "",
				approved,
			});
		} catch (error) {
			logger.error(error, "OAuth flow failed");
			clearOAuthParams();
			// Redirect back to partner with error
			window.location.href = `${redirectUri}?error=access_denied&state=${state}`;
		}
	};

	if (!clientId || !redirectUri) {
		return null;
	}

	return (
		<div className="w-full flex flex-col gap-y-8 py-4 h-full justify-start">
			<div className="flex flex-col items-center justify-center text-center px-4">
				<Typography
					variant="title-small"
					className="text-[1.125rem] leading-[1.75rem] font-semibold"
				>
					Authorize Access
				</Typography>
				<Typography variant="body" className="mt-2">
					Do you want to allow this application to access your FightFi account?
				</Typography>

				{isAuthenticated && (
					<div className="mt-4 text-sm text-muted-foreground">
						<span>Authenticated as {user.email}</span>
					</div>
				)}

				<BaseCard className="flex flex-col mt-8 gap-4 p-4 min-h-fit">
					<div className="w-full max-w-md">
						<OAuthScopeList scopes={scope?.split(" ") || []} />
					</div>

					<div className="flex gap-4 mt-8">
						<MainButton
							onClick={() => handleConsent(false)}
							variant="outline"
							disabled={isLoading}
						>
							Deny
						</MainButton>
						<MainButton
							onClick={() => handleConsent(true)}
							disabled={isLoading}
							isLoading={isLoading}
							isLoadingLabel="Authorizing..."
						>
							Allow
						</MainButton>
					</div>
				</BaseCard>
			</div>
		</div>
	);
}

export default OAuthConsentView;
