import ShareLogo from "@/common/components/icons/share";
import TelegramLogo from "@/common/components/icons/telegram";
import XLogo from "@/common/components/icons/x";
import { Card } from "@/common/components/ui/card";
import { toast } from "sonner";

interface SocialCardProps {
	icon: React.ReactNode;
	href: string;
	label: string;
	onClick?: () => void;
}

function SocialCard({ icon, href, label, onClick }: SocialCardProps) {
	return (
		<Card
			className="bg-black hover:bg-black/60 transition-colors cursor-pointer aspect-square flex items-center justify-center w-20 max-w-20 h-20 flex-1 border-none rounded-none"
			onClick={() => (onClick ? onClick() : window.open(href, "_blank"))}
		>
			<span className="sr-only">{label}</span>
			<div className="w-full h-full flex items-center justify-center">
				{icon}
			</div>
		</Card>
	);
}

const ShareText =
	"Claim your FIGHT ID, earn reputation, airdrop entries! Join me on ";

export default function SocialCards({
	referralCode,
}: {
	referralCode: string;
}) {
	const baseUrl = typeof window !== "undefined" ? window.location.origin : "";
	const shareUrl = `${baseUrl}/signup?code=${referralCode}`;

	const handleCopyLink = async () => {
		await navigator.clipboard.writeText(shareUrl);
		toast("Referral link copied!");
	};

	return (
		<div className="flex gap-2 justify-evenly">
			<SocialCard
				icon={
					<XLogo className="w-[2.5rem] h-[2.5rem] text-white" />
				}
				href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
					ShareText + shareUrl + "&s=x"
				)}`}
				label="Share on X (Twitter)"
			/>
			<SocialCard
				icon={
					<TelegramLogo className="w-[2.5rem] h-[2.5rem] text-white" />
				}
				href={`https://t.me/share/url?url=${encodeURIComponent(
					ShareText + shareUrl + "&s=t"
				)}`}
				label="Share on Telegram"
			/>
			<SocialCard
				icon={
					<ShareLogo className="w-[2.5rem] h-[2.5rem] text-white" />
				}
				href="#"
				label="Copy link"
				onClick={handleCopyLink}
			/>
		</div>
	);
}
