import Button from "@/common/components/design/buttons/button";
import { useUserStore } from "@/common/hooks/use-user-store";
import { useWalletStore } from "@/common/hooks/use-wallet-store";
import { useClaimPacksMutation } from "@/common/services/lightsout.api";
import logger from "@/common/utils/logger";
import { useWallet } from "@/modules/(quest)/referrals/hooks/useWallet";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

interface ClaimButtonProps {
	claimPoints: number;
	// onClaimSuccess?: (pointsAwarded: number) => void;
}

export const ClaimButton = ({
	claimPoints,
}: // onClaimSuccess,
ClaimButtonProps) => {
	const isWalletConnected = useWalletStore((store) => store.wallet.loggedIn);
	const updateFFBalance = useUserStore((store) => store.addFFBalance);
	const { signMessage } = useWallet();

	const [claimPacks, { isLoading: isClaimingLoading }] =
		useClaimPacksMutation();

	const handleClaimNFTsClick = async () => {
		try {
			const signedMessage = await signMessage();

			const response = await claimPacks({
				signatures: signedMessage.signatures,
				signedMessage: signedMessage.message,
				userWallet: signedMessage.wallet || "",
			});

			if (response.error) {
				toast.error(response.error.data.message);
			} else {
				if (response.data?.data.pointsAwarded) {
					updateFFBalance(response.data.data.pointsAwarded);
					// onClaimSuccess?.(response.data.data.pointsAwarded);
				}
			}
		} catch (error) {
			logger.error(error, "lights-out | claim-nfts");
		}
	};

	if (!isWalletConnected) return null;

	return (
		<Button
			onClick={handleClaimNFTsClick}
			disabled={claimPoints === 0 || isClaimingLoading}
			className="md:max-w-none bg-primary text-white mt-8"
		>
			{isClaimingLoading ? (
				<Loader2 className="animate-spin" />
			) : (
				<span className="text-xl font-semibold font-variant-numeric-tabular">
					Claim {claimPoints} FF
				</span>
			)}
		</Button>
	);
};
