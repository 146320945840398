import { createApi } from "@reduxjs/toolkit/query/react";
import { CampForgeQuestClaimResponseDto, CampForgeQuestsResponseDto } from "../types/entities/forgegg/dto/camps-quest.dto";
import { ClaimHistoryResponseDto } from "../types/entities/forgegg/dto/claim-history.dto";
import { ClaimResponseDto } from "../types/entities/forgegg/dto/claim.dto";
import { ClaimableResponseDto } from "../types/entities/forgegg/dto/claimable.dto";
import { TwitterGiveawayResponseDto } from "../types/entities/forgegg/dto/twitter-giveaway.dto";
import { interceptor } from "./interceptor/query.interceptor";

export const forgeggApi = createApi({
	reducerPath: "forgeggApi",
	baseQuery: interceptor,
	tagTypes: ["ClaimablePoints", "ClaimHistory", "TwitterGiveaway", "CampQuestHistory", "CampQuestClaimable"],
	endpoints: (builder) => ({
		getClaimablePoints: builder.query<ClaimableResponseDto, void>({
			query: () => ({
				url: `forgegg/quests/claimable`,
				method: "GET",
			}),
			providesTags: ["ClaimablePoints"],
		}),
		getClaimPointsHistory: builder.query<ClaimHistoryResponseDto, void>({
			query: () => ({
				url: `forgegg/quests/claim-history`,
				method: "GET",
			}),
			providesTags: ["ClaimHistory"],
		}),
		claimPoints: builder.mutation<ClaimResponseDto, void>({
			query: () => ({
				url: "forgegg/quests/claim",
				method: "POST",
			}),
			invalidatesTags: ["ClaimablePoints", "ClaimHistory"],
		}),
		getTwitterTicketQuest: builder.query<TwitterGiveawayResponseDto, void>({
			query: () => ({
				url: `forgegg/quests/twitter/ticket-sync`,
				method: "GET",
			}),
			providesTags: ["TwitterGiveaway"],
		}),
    getCampQuestHistory: builder.query<CampForgeQuestsResponseDto, void>({
      query: () => ({
        url: `forgegg/quests/camps/history`,
        method: "GET",
      }),
      providesTags: ["CampQuestHistory"],
    }),
    getCampQuestClaimable: builder.query<CampForgeQuestsResponseDto, void>({
      query: () => ({
        url: `forgegg/quests/camps/claimable`,
        method: "GET",
      }),
      providesTags: ["CampQuestClaimable"],
    }),
    claimCampQuest: builder.mutation<CampForgeQuestClaimResponseDto, void>({
      query: () => ({
        url: `forgegg/quests/camps/claim`,
        method: "POST",
      }),
      invalidatesTags: ["CampQuestClaimable", "CampQuestHistory"],
    }),
	}),
});

export const {
	useGetClaimablePointsQuery,
	useGetClaimPointsHistoryQuery,
	useClaimPointsMutation,
	useGetTwitterTicketQuestQuery,
  useGetCampQuestHistoryQuery,
  useGetCampQuestClaimableQuery,
  useClaimCampQuestMutation,
} = forgeggApi;
