import { useGetAllActiveQuestsQuery } from "@/common/services/quests.api";
import { TwitterGiveaway } from "../promo-banner/twitter-giveaway";
import { useUserStore } from "@/common/hooks/use-user-store";
import { JoinCampBanner } from "../promo-banner/join-camp";
import { QUEST_IDS } from "@/common/constants/quest-ids";
export function PromoBanner() {
	const { data } = useGetAllActiveQuestsQuery();

	// TODO: if user has camp then show camp quest, otherwise show camp banner
	const campMember = useUserStore((store) => store.user.campMember);
	const promoBanner = data?.data.find((quest) => quest.isPromoBanner);

	if (!promoBanner) {
		return null;
	}

	if (!campMember && promoBanner.id === QUEST_IDS.CAMP_FORGE_BANNER) {
		return <JoinCampBanner quest={promoBanner} />;
	}

	if (promoBanner.id === QUEST_IDS.TWITTER_TICKET_GIVEAWAY_FEB) {
		return <TwitterGiveaway quest={promoBanner} />;
	}

	return null;
}
