import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import SettingsView from "./views/settings/settings";

const profileRoutes: RouteObject[] = [
	{
		path: Routes.Notifications,
		element: (
			<Layout>
				<SettingsView />
			</Layout>
		),
	},
];

export default profileRoutes;
