import { Card, CardHeader } from "@/common/components/ui/card";
import { Skeleton } from "@/common/components/ui/skeleton";

export default function QuestCardSkeleton() {
	return (
		<>
			<Card className="relative bg-black w-full border-none rounded-none">
				<CardHeader />
				<div className="flex items-end justify-between p-6 h-[150px]">
					<div className="space-y-4">
						<Skeleton className="h-6 w-48 bg-zinc-800" />
						<Skeleton className="h-4 w-32 bg-zinc-800" />
					</div>
				</div>
			</Card>
			<Card className="relative bg-black w-full border-none rounded-none">
				<CardHeader />
				<div className="flex items-end justify-between p-6 h-[150px]">
					<div className="space-y-4">
						<Skeleton className="h-6 w-48 bg-zinc-800" />
						<Skeleton className="h-4 w-32 bg-zinc-800" />
					</div>
				</div>
			</Card>
			<Card className="relative bg-black w-full border-none rounded-none">
				<CardHeader />
				<div className="flex items-end justify-between p-6 h-[150px]">
					<div className="space-y-4">
						<Skeleton className="h-6 w-48 bg-zinc-800" />
						<Skeleton className="h-4 w-32 bg-zinc-800" />
					</div>
				</div>
			</Card>
		</>
	);
}
