import { useUserStore } from "@/common/hooks/use-user-store";
import { useNavigate } from "react-router-dom";
import { Routes } from "@/common/types/routes";

export function AuthWrapper({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();

	const user = useUserStore((state) => state.user);

	const isAuthenticated = user.email !== "" && user.token !== "";

	if (!isAuthenticated) {
		navigate(Routes.Signup);
	}

	// useEffect(() => {
	// 	// Prevent multiple navigation attempts in the same render cycle
	// 	if (navigationPerformed.current) return;

	// 	const checkAuth = async () => {
	// 		try {
	// 			if (!isAuthenticated) {
	// 				// Only redirect to login if we're not already on an auth route
	// 				const isAuthRoute = [
	// 					Routes.Login,
	// 					Routes.Signup,
	// 					Routes.VerifyEmail,
	// 					Routes.AuthLoading,
	// 					Routes.ClaimFightID,
	// 				].includes(location.pathname as Routes);

	// 				if (!isAuthRoute && location.pathname !== "/") {
	// 					navigationPerformed.current = true;
	// 					navigate(Routes.Signup);
	// 				}
	// 			}
	// 		} catch (error) {
	// 			logger.error(error, "Auth Wrapper");
	// 			clear();
	// 		}
	// 	};

	// 	checkAuth();

	// 	// Reset the navigation flag when location changes
	// 	return () => {
	// 		navigationPerformed.current = false;
	// 	};
	// }, [isAuthenticated, location.pathname, navigate, clear]);

	return <>{children}</>;
}
