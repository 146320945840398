import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import ReferFriendsView from "./views/refer-friends/refer-friends";

const referralsRoutes: RouteObject[] = [
	{
		path: Routes.QuestReferrals,
		element: (
			<Layout>
				<ReferFriendsView />
			</Layout>
		),
	},
];

export default referralsRoutes;
