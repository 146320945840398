import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import KPIsView from "./views/home/kpis";

const adminRoutes: RouteObject[] = [
	{
		path: Routes.AdminKPIs,
		element: (
			<Layout>
				<KPIsView />
			</Layout>
		),
	},
];

export default adminRoutes;
