import MainButton from "@/common/components/design/buttons/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/common/components/ui/form";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "@/common/components/ui/input-otp";
import logger from "@/common/utils/logger";
import { zodResolver } from "@hookform/resolvers/zod";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useLoadingStore } from "../../../hooks/use-loading";
import { useState } from "react";
import { useVerifyTokenMutation } from "@/common/services/auth.api";
import { useUserStore } from "@/common/hooks/use-user-store";
import { toast } from "sonner";

const otpFormSchema = z.object({
	otp: z.string().min(6),
});

export default function VerifyEmailForm() {
	const [verifyToken] = useVerifyTokenMutation();
	const [isLoading, setIsLoading] = useState(false);
	const user = useUserStore((store) => store.user);
	const updateUser = useUserStore((store) => store.updateUser);
	const verifyOtp = useLoadingStore((store) => store.updateOtpVerified);

	const otpForm = useForm<z.infer<typeof otpFormSchema>>({
		resolver: zodResolver(otpFormSchema),
		defaultValues: {
			otp: "",
		},
	});

	const onFormSubmit = async (values: z.infer<typeof otpFormSchema>) => {
		setIsLoading(true);
		logger.info(values, "onRegisterSubmit");

		try {
			const response = await verifyToken({
				email: user.email,
				token: values.otp,
			});

			if (!response.data?.data.accessToken) {
				throw new Error("Invalid OTP");
			}

			updateUser(
				{
					...user,
					token: response.data?.data.accessToken!,
					id: response.data?.data.userId!,
					verified: response.data?.data.verified!,
					notification: response.data?.data.notification!,
					username: response.data?.data.username!,
				},
				response.data?.data.accessToken
			);
			verifyOtp(true);
		} catch (error) {
			logger.error(error, "onRegisterSubmit");
			toast.error("Invalid OTP");
			verifyOtp(false);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-y-4 items-center justify-center w-full">
			<Form {...otpForm}>
				<form
					onSubmit={otpForm.handleSubmit(onFormSubmit)}
					className="space-y-4 w-full"
				>
					<FormField
						control={otpForm.control}
						name="otp"
						render={({ field }) => (
							<FormItem className="w-full flex flex-col items-center justify-center">
								<FormControl>
									<InputOTP
										maxLength={6}
										pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
										{...field}
									>
										<InputOTPGroup className="text-primary-foreground">
											<InputOTPSlot index={0} />
											<InputOTPSlot index={1} />
										</InputOTPGroup>
										<InputOTPSeparator />
										<InputOTPGroup className="text-primary-foreground">
											<InputOTPSlot index={2} />
											<InputOTPSlot index={3} />
										</InputOTPGroup>
										<InputOTPSeparator />
										<InputOTPGroup className="text-primary-foreground">
											<InputOTPSlot index={4} />
											<InputOTPSlot index={5} />
										</InputOTPGroup>
									</InputOTP>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<MainButton
						type="submit"
						className="w-full"
						isLoadingLabel="Verifying"
						isLoading={isLoading}
						disabled={!otpForm.formState.isValid}
					>
						Sign Up
					</MainButton>
				</form>
			</Form>
		</div>
	);
}
