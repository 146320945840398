import { SidebarTrigger } from "../../ui/sidebar";
import { FFBalance } from "../../user/ff-balance-navbar";
import FightIdLogo from "../../logo/fightid";
import { NavLink } from "react-router-dom";
import { Routes } from "@/common/types/routes";
import CampsGlovesIcon from "../../icons/camps-gloves-icon";
import { cn } from "@/common/lib/utils";
import { useQuestsStore } from "@/common/hooks/use-quests-store";
import { QUEST_IDS, QuestId } from "@/common/constants/quest-ids";

const getNavItems = (isQuestEnabled: (questId: QuestId) => boolean) => [
	{
		name: "Camps Leaderboard",
		icon: CampsGlovesIcon,
		path: Routes.Camps,
		// Only show the Camps link if the CAMP_FORGE quest is enabled
		enabled: isQuestEnabled(QUEST_IDS.CAMP_FORGE),
	},
];

function AuthHeader() {
	const isQuestEnabled = useQuestsStore((state) => state.isQuestEnabled);

	// Generate the nav items using the quest store
	const navItems = getNavItems(isQuestEnabled);

	return (
		<header className="sticky top-0 z-30 shrink-0 flex h-16 w-full backdrop-blur-sm bg-background/70">
			<div className="w-full px-4 sm:px-6">
				<div className="mx-auto max-w-7xl flex items-center h-full">
					<div className="flex flex-row gap-0">
						<NavLink to={Routes.Home}>
							<FightIdLogo width={130} height={22} />
						</NavLink>

						{/* <span className="text-[1.7rem] leading-[1.875rem] font-bold uppercase ">
							FIGHTID
						</span> */}
					</div>
					<div className="relative ml-auto flex-1 md:grow-0"></div>
					<div className="flex items-center gap-4">
						<nav className="hidden md:block">
							<div className="flex justify-between items-center h-16">
								{navItems
									.filter((item) => item.enabled)
									.map((item) => (
										<NavLink
											key={item.name}
											to={item.path}
											className={() =>
												cn(
													"flex items-center justify-center flex-1 py-2 text-white uppercase text-sm text-center"
												)
											}
										>
											<item.icon className="h-6 w-6 mb-1" />
											<span className="text-xs uppercase">{item.name}</span>
										</NavLink>
									))}
							</div>
						</nav>
						<FFBalance />
						<SidebarTrigger />
					</div>
				</div>
			</div>
		</header>
	);
}

export default AuthHeader;
