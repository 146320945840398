import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useUserStore } from "@/common/hooks/use-user-store";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "@/common/types/routes";
import logger from "@/common/utils/logger";
import { auth0AuthorizationParams } from "@/common/config/auth0.config";
import { useExchangeOktaTokenMutation } from "@/common/services/auth.api";

export function OktaAuthWrapper({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		user,
		getAccessTokenWithPopup,
		getAccessTokenSilently,
		isAuthenticated,
		isLoading,
	} = useAuth0();
	const updateUser = useUserStore((state) => state.updateUser);

	const [exchangeToken] = useExchangeOktaTokenMutation();

	useEffect(() => {
		const initOktaAuth = async () => {
			// Only process authentication on the AuthLoading route
			if (location.pathname !== Routes.AuthLoading) {
				return;
			}

			if (isLoading) return;

			try {
				if (isAuthenticated && user) {
					// User authenticated via Okta
					let token;
					try {
						// First attempt silent token retrieval
						token = await getAccessTokenSilently({
							authorizationParams: auth0AuthorizationParams,
						});
					} catch (error) {
						// If silent retrieval fails, try with popup
						logger.warn(
							"Silent token retrieval failed, trying popup",
							"Okta Auth Wrapper"
						);
						token = await getAccessTokenWithPopup({
							authorizationParams: auth0AuthorizationParams,
						});
					}

					if (!token) {
						logger.error("No token found", "Okta Auth Wrapper");
						navigate(Routes.Signup);
						return;
					}

					// exchange auth0 token for jwt token and user data
					const userInfo = await exchangeToken({ token });

					if (userInfo.error) {
						logger.error(userInfo.error);
						throw new Error();
					}

					// Update user with Okta credentials
					updateUser(userInfo.data.data, userInfo.data.data.accessToken);

					// Redirect to home or another appropriate page after successful auth
					// navigate(Routes.Home);
				} else if (!isAuthenticated && !isLoading) {
					// User did not authenticate via Okta
					logger.info("User not authenticated via Okta", "Okta Auth Wrapper");
				}
			} catch (error) {
				logger.error(error, "Okta Auth Wrapper");
				navigate(Routes.Signup);
			}
		};

		initOktaAuth();
	}, [
		isAuthenticated,
		user,
		getAccessTokenSilently,
		getAccessTokenWithPopup,
		isLoading,
	]);

	return <>{children}</>;
}
