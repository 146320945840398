import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import CoinsIcon from "@/common/components/icons/coins";
import { useGetCampsLeaderboardQuery } from "@/common/services/camps.api";
import { Routes } from "@/common/types/routes";
import FighterCard from "@/modules/camps/components/fighter-card";
import SkeletonFighterCard from "@/modules/camps/components/skeleton-fighter-card";
import { useNavigate } from "react-router-dom";
import { useGetQuestByIdQuery } from "@/common/services/quests.api";
import { QUEST_IDS } from "@/common/constants/quest-ids";
import { Loader2 } from "lucide-react";

function ChooseCampView() {
	const navigate = useNavigate();

	const {
		data: campsLeaderboard,
		isLoading,
		error,
	} = useGetCampsLeaderboardQuery();

	const { data: questDetails, isLoading: questDetailsLoading } =
		useGetQuestByIdQuery(QUEST_IDS.CAMP_FORGE);

	const handleJoinCamp = async (campId: string) => {
		navigate(Routes.CampsDetails.replace(":campId", campId));
	};

	// Generate skeleton cards for loading state
	const renderSkeletonCards = () => {
		return Array(10)
			.fill(null)
			.map((_, index) => <SkeletonFighterCard key={index} />);
	};

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit">
				<div className="flex flex-row gap-4 items-start">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2 max-w-3xl">
						<Typography variant="title">
							CHOOSE YOUR FIGHTER AND JOIN THE BATTLE!
						</Typography>
						<Typography variant="body">
							Pick your fighter, join their camp for the week, and earn
							rewards—for both you and your champion!
						</Typography>
						<Typography
							variant="body"
							className="text-terciary flex flex-row gap-2 items-center"
						>
							<CoinsIcon />{" "}
							{questDetailsLoading ? (
								<Loader2 className="animate-spin h-4" />
							) : (
								<>{questDetails?.data?.points}</>
							)}{" "}
							$FF if this fighter wins!
						</Typography>
					</div>
				</div>

				{isLoading ? (
					<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-6">
						{renderSkeletonCards()}
					</div>
				) : error ? (
					<div className="text-center py-8">
						<Typography variant="body">
							Failed to load fighters. Please try again later.
						</Typography>
					</div>
				) : (
					<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-6">
						{campsLeaderboard?.data.map((camp) => (
							<FighterCard
								key={camp.campId}
								fighter={camp}
								onJoin={() => handleJoinCamp(camp.campId)}
							/>
						))}
					</div>
				)}
			</BaseCard>
		</div>
	);
}

export default ChooseCampView;
