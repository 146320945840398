import Layout from "@/modules/login/layout";
import RouteError from "./route-error";

/**
 * Error element that preserves the login layout
 */
export function LoginLayoutErrorElement() {
	return (
		<Layout>
			<RouteError />
		</Layout>
	);
}
