import React from "react";

interface ShareLogoProps {
	className?: string;
}

const ShareLogo: React.FC<ShareLogoProps> = ({ className }) => (
	<svg
		className={className}
		width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"
	>
<path d="M14.0955 25.9145L10.0891 29.9209C8.42813 31.5818 5.73833 31.5818 4.07876 29.9212C2.41886 28.2613 2.41886 25.5712 4.07843 23.9117L12.0926 15.8975C13.7521 14.2379 16.4422 14.2379 18.1018 15.8975C18.655 16.4508 19.552 16.4508 20.1053 15.8975C20.6585 15.3443 20.6585 14.4473 20.1053 13.894C17.3392 11.1279 12.8552 11.1279 10.0891 13.894L2.075 21.9081C-0.691098 24.6742 -0.691098 29.1582 2.075 31.9243C4.84077 34.6919 9.32508 34.6919 12.0926 31.9243L16.099 27.9179C16.6523 27.3647 16.6523 26.4677 16.099 25.9144C15.5458 25.3612 14.6487 25.3613 14.0955 25.9145Z" fill="white"/>
<path d="M31.925 2.07452C29.1589 -0.691587 24.6735 -0.691587 21.9074 2.07452L17.1006 6.88129C16.5474 7.43453 16.5474 8.33155 17.1006 8.88478C17.6539 9.43802 18.5509 9.43802 19.1041 8.88478L23.9109 4.078C25.5705 2.41837 28.2619 2.41837 29.9215 4.078C31.5811 5.73757 31.5811 8.42764 29.9215 10.0872L21.107 18.9018C19.4474 20.5615 16.7574 20.5615 15.0978 18.9018C14.5446 18.3486 13.6475 18.3486 13.0943 18.9018C12.5411 19.4551 12.5411 20.3521 13.0943 20.9053C15.8604 23.6714 20.3444 23.6714 23.1105 20.9053L31.925 12.0908C34.6911 9.32466 34.6911 4.84062 31.925 2.07452Z" fill="white"/>
	</svg>
);

export default ShareLogo;
