import Typography from "@/common/components/design/texts/typography";
// import FistIcon from "../icons/fist-icon";
import { useUserStore } from "@/common/hooks/use-user-store";

function FightIdReady() {
	const fightIdUsername = useUserStore((store) => store.user.username);

	return (
		<div className="flex flex-col items-center text-center justify-center w-full gap-6 min-h-[80vh]">
			{/* <FistIcon className=" text-primary-foreground" /> */}

			<Typography variant="title" className="text-4xl leading-9 text-primary">
				Your fight id <br /> is Ready!
			</Typography>

			<Typography
				variant="title-small"
				className="text-2xl font-semibold  text-primary"
			>
				{fightIdUsername}.fight
			</Typography>
		</div>
	);
}

export default FightIdReady;
