import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import HomeView from "./views/home/home";

const homeRoutes: RouteObject[] = [
	{
		path: Routes.Home,
		element: (
			<Layout>
				<HomeView />
			</Layout>
		),
	},
];

export default homeRoutes;
