import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import WalletConnectView from "./views/connection/connection";

const walletConnectRoutes: RouteObject[] = [
	{
		path: Routes.QuestWalletConnect,
		element: (
			<Layout>
				<WalletConnectView />
			</Layout>
		),
	},
];

export default walletConnectRoutes;
