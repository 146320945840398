import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import TwitterTicketGiveawayView from "./views/connection/twitter-ticket-giveaway";

const ticketsTwitterRoutes: RouteObject[] = [
	{
		path: Routes.QuestTicketsTwitter,
		element: (
			<Layout>
				<TwitterTicketGiveawayView />
			</Layout>
		),
	},
];

export default ticketsTwitterRoutes;
