import { Skeleton } from "@/common/components/ui/skeleton";

interface CampListSkeletonProps {
	count?: number;
}

export function CampListSkeleton({ count = 8 }: CampListSkeletonProps) {
	return (
		<>
			{Array(count)
				.fill(0)
				.map((_, index) => (
					<div
						key={`skeleton-${index}`}
						className="flex items-center justify-between p-4 rounded-sm bg-bgcontainer"
					>
						<div className="flex items-center gap-4">
							<Skeleton className="w-8 h-6 rounded" />
							<Skeleton className="w-10 h-10 rounded-full" />
							<Skeleton className="w-32 h-6 rounded" />
						</div>
						<Skeleton className="w-24 h-6 rounded" />
					</div>
				))}
		</>
	);
}
