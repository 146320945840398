import { useUserStore } from "@/common/hooks/use-user-store";
import Typography from "@/common/components/design/texts/typography";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "@/common/components/ui/avatar";

export function UserInfo() {
	const email = useUserStore((store) => store.user.email);
	const userCamp = useUserStore((store) => store.user.campMember);
	const getFightUsername = useUserStore((store) => store.getFightUsername);

	return (
		<div className="flex flex-col w-full bg-bgcontainer py-4 md:py-8 px-4 md:px-12 gap-y-4">
			{/* First row - Username and space for future user holdings */}
			<div className="flex flex-row justify-between w-full">
				<Typography
					variant="title"
					className="relative z-20 break-all max-w-full text-2xl font-semibold"
				>
					{getFightUsername()}
				</Typography>
				{/* Space reserved for future user holdings */}
				<div className="flex-shrink-0"></div>
			</div>

			{/* Second row - Email and Camp (avatar + name) */}
			<div className="flex flex-row justify-between w-full items-center">
				<Typography
					variant="caption"
					className="break-all max-w-full font-normal text-sm text-white/70"
				>
					{email}
				</Typography>
				<div className="flex flex-row items-center gap-x-2">
					<Avatar>
						<AvatarImage
							src={userCamp?.camp?.fighterImage}
							className="object-scale-down"
						/>
						{userCamp?.camp?.campName && (
							<AvatarFallback>{userCamp?.camp?.campName[0]}</AvatarFallback>
						)}
					</Avatar>
					<Typography
						variant="body"
						className="break-all max-w-full font-normal text-sm text-white"
					>
						{userCamp?.camp?.campName}
					</Typography>
				</div>
			</div>
		</div>
	);
}
