import { ReactNode } from "react";
import { CardFooter, CardHeader } from "@/common/components/ui/card";
import { Loader2 } from "lucide-react";
import BaseCard from "../card/base-card";

interface StatsCardProps {
	title: string;
	isLoading?: boolean;
	value?: ReactNode;
	className?: string;
}

function StatsCard({ title, isLoading, value, className }: StatsCardProps) {
	return (
		<BaseCard className={` w-full ${className}`}>
			<CardHeader className="font-medium text-xl uppercase p-0 pt-4 px-6">
				{title}
			</CardHeader>
			<CardFooter className="p-0 pt-2 pb-4 px-6 font-medium text-3xl text-terciary">
				{isLoading ? <Loader2 className="w-4 h-4 animate-spin mb-1" /> : value}
			</CardFooter>
		</BaseCard>
	);
}

export default StatsCard;
