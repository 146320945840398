import Typography from "@/common/components/design/texts/typography";
import FightIdLogo from "@/common/components/logo/fightid";
import { useUserStore } from "@/common/hooks/use-user-store";
import { Routes } from "@/common/types/routes";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignUpForm from "../../components/form/signup-form";
import { useLoadingStore } from "../../hooks/use-loading";
import SendingEmail from "../../components/loading-screens/sending-email";
import { useOAuth } from "../../hooks/use-oauth";

function SignUpView() {
	// invite code and source from query if available
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const updateReferral = useUserStore((store) => store.updateReferral);
	const clearUserStore = useUserStore((store) => store.clear);

	const loadingState = useLoadingStore((store) => store.loadingState);
	const initializeData = useLoadingStore((store) => store.initializeData);
	const { disconnect } = useWallet();
	const { checkStoredOAuthParams } = useOAuth();

	useEffect(() => {
		if (searchParams.has("code")) {
			updateReferral(
				searchParams.get("code") || "",
				searchParams.get("s") || ""
			);
		}
	}, [searchParams]);

	useEffect(() => {
		// clear zustand and token
		const clearAll = () => {
			disconnect();
			clearUserStore();
			localStorage.removeItem("token");
			initializeData();
		};

		clearAll();
	}, []);

	useEffect(() => {
		if (loadingState === "otpSent") {
			const timer = setTimeout(() => {
				navigate(Routes.VerifyEmail);
			}, 3000);

			// Cleanup timer on unmount
			return () => clearTimeout(timer);
		}
	}, [navigate, loadingState]);

	// Check for OAuth parameters after successful authentication
	useEffect(() => {
		checkStoredOAuthParams();
	}, [checkStoredOAuthParams]);

	if (loadingState === "otpSent") {
		return <SendingEmail />;
	}

	return (
		<div className="w-full flex flex-col gap-y-8 py-4 h-full justify-end md:justify-start">
			{/* Top empty section */}
			{/* <div className="flex-1 md:hidden" /> */}

			{/* Middle section with title */}
			<div className="text-center px-6 space-y-2">
				<Typography variant="subtitle" className="font-bold text-xl">
					WELCOME TO
				</Typography>
				<div className="flex justify-center">
					<FightIdLogo width={340} height={60} />
				</div>
			</div>

			{/* Bottom section with text and input */}
			<div className="flex flex-col justify-end md:justify-start items-center pb-6 mt-14 space-y-4">
				<Typography variant="body" className="max-w-[400px]">
					Claim your FIGHT ID, earn reputation, airdrop entries, and join the
					fighters fan club!
				</Typography>

				<SignUpForm />
			</div>
		</div>
	);
}

export default SignUpView;
