import Button from "@/common/components/design/buttons/button";
import CheckCompletedIcon from "@/common/components/icons/check-completed";
import GoChevronIcon from "@/common/components/icons/go-chevron";

interface QuestButtonProps {
	label: string;
	isCompleted: boolean;
	isLoading: boolean;
	onClick: () => void;
}

function QuestButton({
	label,
	isCompleted,
	isLoading,
	onClick,
}: QuestButtonProps) {
	return (
		<Button
			className="w-full bg-primary flex justify-between [&_svg]:size-6"
			disabled={isLoading || isCompleted}
			onClick={onClick}
			variant={isCompleted ? "default" : "ghost"}
		>
			<span className="text-lg uppercase">{label}</span>
			{isCompleted ? (
				<CheckCompletedIcon className="w-6 h-6" />
			) : (
				<GoChevronIcon className="w-6 h-6" />
			)}
		</Button>
	);
}

export default QuestButton;
