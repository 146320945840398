import { cn } from "@/common/lib/utils";
import { Card } from "../../ui/card";

type DesignCardProps = {
	className?: string;
	onClick?: () => void;
	children: React.ReactNode;
};

export default function BaseCard({
	className,
	onClick,
	children,
}: DesignCardProps) {
	return (
		<Card
			className={cn(
				"bg-bgcontainer border-none rounded-none w-full",
				className
			)}
			onClick={onClick}
		>
			{children}
		</Card>
	);
}
