import MainButton from "@/common/components/design/buttons/button";
import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { useUserStore } from "@/common/hooks/use-user-store";
import {
  useGetCampDetailsQuery,
  useJoinCampMutation,
} from "@/common/services/camps.api";
import { Routes } from "@/common/types/routes";
import {
  getCountryFlagUrl,
  getCountryName,
} from "@/common/utils/country-helpers";
import logger from "@/common/utils/logger";
import { ArrowRight } from "lucide-react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StatItem from "../../components/stat-item";

function CampDetailsView() {
	const { campId } = useParams<{ campId: string }>();
	const navigate = useNavigate();
	const userCampMember = useUserStore((state) => state.user.campMember);

	// Redirect if user already belongs to a camp
	useEffect(() => {
		if (userCampMember) {
			navigate(Routes.Camps);
		}
	}, [navigate, userCampMember]);

	const {
		data: campDetails,
		isLoading,
		error,
	} = useGetCampDetailsQuery(campId);

	const [joinCamp, { isLoading: isJoining }] = useJoinCampMutation();

	const handleJoinCamp = async () => {
		if (!campId) return;

		try {
			await joinCamp(campId).unwrap();
			// Navigate to home with joinedCamp query parameter to trigger user data refetch
			navigate(`${Routes.Home}?joinedCamp=${campDetails?.data?.fighterName}`);
		} catch (error) {
			logger.error(error, "Failed to join camp");
		}
	};

	if (isLoading) {
		return (
			<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
				<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit bg-black md:bg-bgcontainer">
					<div className="flex flex-row gap-4">
						<div>
							<NavBackButton to={Routes.CampChoose} />
						</div>
						<div className="flex flex-col gap-2 w-full">
							{/* Title skeleton */}
							<div className="h-8 bg-gray-700/50 rounded-md animate-pulse w-3/4"></div>
							{/* Description skeleton */}
							<div className="h-4 bg-gray-700/50 rounded-md animate-pulse w-full mt-2"></div>
							<div className="h-4 bg-gray-700/50 rounded-md animate-pulse w-5/6 mt-1"></div>
						</div>
					</div>

					<div className="flex flex-col md:flex-row gap-8 mt-4">
						{/* Fighter Image skeleton */}
						<div className="w-full md:w-1/2 relative flex flex-col">
							<div className="flex flex-col w-full bg-gray-800/50 rounded-lg overflow-hidden h-64 md:h-96 animate-pulse"></div>

							{/* Join button skeleton on desktop - below image */}
							<div className="hidden md:flex mt-6 justify-center">
								<div className="h-16 bg-gray-700/50 rounded-md animate-pulse w-full"></div>
							</div>
						</div>

						{/* Fighter Info skeleton - Right side */}
						<div className="w-full md:w-1/2 flex flex-col">
							{/* Fighter name skeleton */}
							<div className="hidden md:block h-8 bg-gray-700/50 rounded-md animate-pulse w-3/4 mb-4"></div>

							{/* Weight class and record skeleton */}
							<div className="mb-6 hidden md:flex flex-col gap-2">
								<div className="h-4 bg-gray-700/50 rounded-md animate-pulse w-1/3"></div>
								<div className="h-4 bg-gray-700/50 rounded-md animate-pulse w-1/4"></div>
							</div>

							{/* Stats title skeleton */}
							<div className="h-6 bg-gray-700/50 rounded-md animate-pulse w-1/2 mb-4"></div>

							{/* Stats items skeleton */}
							<div className="flex flex-col gap-4">
								{[1, 2, 3, 4, 5].map((i) => (
									<div key={i} className="flex flex-col gap-1">
										<div className="h-3 bg-gray-700/50 rounded-md animate-pulse w-1/4"></div>
										<div className="h-4 bg-gray-700/50 rounded-md animate-pulse w-1/3"></div>
									</div>
								))}
							</div>

							{/* Join button skeleton on mobile */}
							<div className="md:hidden flex justify-center mt-6">
								<div className="h-16 bg-gray-700/50 rounded-md animate-pulse w-full"></div>
							</div>
						</div>
					</div>
				</BaseCard>
			</div>
		);
	}

	if (error || !campDetails?.data) {
		return (
			<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
				<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit">
					<div className="flex flex-row gap-4">
						<div>
							<NavBackButton to={Routes.CampChoose} />
						</div>
						<div className="flex flex-col gap-2">
							<Typography variant="title">FIGHTER NOT FOUND</Typography>
							<Typography variant="body">
								Sorry, we couldn't find this fighter. Please try again later.
							</Typography>
						</div>
					</div>
				</BaseCard>
			</div>
		);
	}

	const fighter = campDetails.data;
	const record = `${fighter.wins}-${fighter.losses}-${fighter.draws}`;

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit bg-black md:bg-bgcontainer">
				{/* Header with back button and title */}
				<div className="flex flex-row gap-4 items-start">
					<div>
						<NavBackButton to={Routes.CampChoose} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">
							{fighter.fighterName.toUpperCase()}'S CAMP
						</Typography>
						<Typography variant="body">
							Join the camp of the {fighter.category || "UFC"}{" "}
							{fighter.fighterName ? fighter.fighterName : "Champion"} known for
							his unmatched grappling and strategic fight IQ. Help{" "}
							{fighter.fighterName
								? fighter.fighterName.split(" ")[0]
								: "the fighter"}{" "}
							conquer his next challenge and earn exclusive rewards.
						</Typography>
					</div>
				</div>

				{/* Main content area */}
				<div className="flex flex-col md:flex-row gap-8 mt-6">
					{/* Fighter Image - Left side */}
					<div className="w-full md:w-1/2 relative flex flex-col">
						{fighter.fighterImage ? (
							<div className="w-full overflow-hidden relative">
								<img
									src={fighter.fighterImage}
									alt={fighter.fighterName}
									className="w-full h-full object-cover"
								/>
								{/* Gradient overlay at the bottom of the image */}
								<div className="absolute bottom-0 left-0 right-0 h-[60px] bg-gradient-to-t from-black to-transparent"></div>
							</div>
						) : (
							<div className="w-full h-64 bg-gray-800 flex items-center justify-center">
								<Typography variant="subtitle">
									{fighter.fighterName}
								</Typography>
							</div>
						)}

						{/* Join button on desktop - below image */}
						<div className="hidden md:flex mt-6 justify-center">
							<MainButton
								className="uppercase w-full h-16 font-semibold text-xl [&_svg]:size-6"
								onClick={handleJoinCamp}
								isLoading={isJoining}
								isLoadingLabel="JOINING CAMP..."
								disabled={!!userCampMember}
							>
								{!userCampMember ? (
									<>
										<span>JOIN {fighter.fighterName.toUpperCase()}'S CAMP</span>
										<ArrowRight className="h-6 w-6 ml-2" />
									</>
								) : (
									<>Already Joined a camp</>
								)}
							</MainButton>
						</div>
					</div>

					{/* Fighter Info - Right side */}
					<div className="w-full md:w-1/2 flex flex-col">
						{/* Country flag and fighter name */}
						<div className="items-center gap-2 mb-2 hidden md:flex">
							{fighter.country && (
								<div className="w-6 h-4 overflow-hidden rounded-sm">
									<img
										src={getCountryFlagUrl(fighter.country)}
										alt={`${fighter.country} flag`}
										className="w-full h-full object-cover"
										onError={(e) => {
											e.currentTarget.style.display = "none";
										}}
										loading="lazy"
									/>
								</div>
							)}
							<span>{getCountryName(fighter.country || "")}</span>
						</div>

						<Typography variant="title" className="text-2xl hidden md:block">
							{fighter.fighterName.toUpperCase()}
						</Typography>

						{/* Weight class and record */}
						<div className="mb-6 hidden md:flex flex-col gap-2">
							<Typography variant="body" className="uppercase ">
								{fighter.category || "WEIGHT CLASS"}
							</Typography>
							<Typography variant="body" className="">
								{record}
							</Typography>
						</div>

						{/* Fighter stats */}
						<div className="mb-6 text-center md:text-left">
							<Typography
								variant="subtitle"
								className="uppercase mb-4 pb-2 text-lg"
							>
								FIGHTER'S STATS
							</Typography>

							<div className="flex flex-col gap-4">
								<StatItem label="RECORD" value={record} />
								{fighter.age && <StatItem label="AGE" value={fighter.age} />}
								{fighter.height && (
									<StatItem label="HEIGHT" value={`${fighter.height} FT`} />
								)}
								{fighter.weight && (
									<StatItem label="WEIGHT" value={`${fighter.weight} LBS`} />
								)}
								{fighter.reach && (
									<StatItem label="REACH" value={`${fighter.reach} IN`} />
								)}
							</div>
						</div>

						{/* Join button on mobile - at the bottom of fighter info */}
						<div className="md:hidden flex justify-center">
							<MainButton
								className="uppercase w-full h-16 font-semibold text-xl mt-auto [&_svg]:size-6"
								onClick={handleJoinCamp}
								isLoading={isJoining}
								isLoadingLabel="JOINING CAMP..."
								disabled={!!userCampMember}
							>
								{!userCampMember ? (
									<>
										<span>JOIN {fighter.fighterName.toUpperCase()}'S CAMP</span>
										<ArrowRight className="h-6 w-6 ml-2" />
									</>
								) : (
									<>Already Joined a camp</>
								)}
							</MainButton>
						</div>
					</div>
				</div>
			</BaseCard>
		</div>
	);
}

export default CampDetailsView;
