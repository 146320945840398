import { useCallback } from "react";
import { useOAuthStore } from "./use-oauth-store";
import { useUserStore } from "@/common/hooks/use-user-store";
import { logger } from "@/common/utils/logger";
import { useInitiateOAuthLoginMutation } from "@/common/services/auth.api";
import { OAuthLoginRequestDto } from "@/common/types/entities/auth/dto/oauth.dto";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";
import { Routes } from "@/common/types/routes";

export function useOAuth() {
	const navigate = useNavigate();
	const { params: oauthParams, clearParams } = useOAuthStore();
	const user = useUserStore((state) => state.user);
	const [initiateOAuthLoginMutation] = useInitiateOAuthLoginMutation();

	const initiateOAuthFlow = useCallback(
		async ({
			clientId,
			redirectUri,
			scope,
			state,
			approved,
		}: OAuthLoginRequestDto & { approved: boolean }) => {
			if (!approved) {
				clearParams();
				window.location.href = `${redirectUri}?error=access_denied&state=${state}`;
				return;
			}

			try {
				const result = await initiateOAuthLoginMutation({
					clientId,
					redirectUri,
					scope,
					state,
					token: user.token || "",
				});

				if ("error" in result) {
					const error = result.error as FetchBaseQueryError;
					const errorMessage =
						typeof error.data === "object" &&
						error.data &&
						"message" in error.data
							? (error.data.message as string)
							: "OAuth flow failed";
					throw new Error(errorMessage);
				}

				clearParams();
				window.location.href = `${redirectUri}?code=${result.data.data.code}${
					result.data.data.state ? `&state=${result.data.data.state}` : ""
				}`;
			} catch (error) {
				logger.error(error, "OAuth flow failed");
				clearParams();
				window.location.href = `${redirectUri}?error=server_error&state=${state}`;
			}
		},
		[initiateOAuthLoginMutation, user.token, clearParams]
	);

	const checkStoredOAuthParams = useCallback(() => {
		if (oauthParams && user.token) {
			if (!user.username) {
				navigate(Routes.ClaimFightID);
			} else {
				const searchParams = new URLSearchParams({
					client_id: oauthParams.clientId,
					redirect_uri: oauthParams.redirectUri,
					...(oauthParams.scope && { scope: oauthParams.scope }),
					...(oauthParams.state && { state: oauthParams.state }),
				});
				navigate(`/oauth/consent?${searchParams.toString()}`);
			}
		}
	}, [user.token, user.username, navigate, oauthParams]);

	return {
		initiateOAuthFlow,
		isLoading: false,
		isAuthenticated: !!user.token,
		checkStoredOAuthParams,
	};
}
