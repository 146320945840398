export const backUrl = import.meta.env.VITE_APP_BACK_URL;
export const appEnvironment: "prod" | "stage" | "dev" = import.meta.env
	.VITE_APP_ENVIRONMENT;

export const rainbowClientID = import.meta.env.VITE_APP_RAINBOW_CLIENT_ID;
export const walletConnectProjectID = import.meta.env
	.VITE_APP_WALLETCONNECT_PROJECT_ID;

export const flowNetwork = import.meta.env.VITE_APP_FLOW_NETWORK;
export const flowToken = import.meta.env.VITE_APP_FLOW_TOKEN;

export const flowAccessNodeApi = import.meta.env.VITE_APP_FLOW_ACCESSNODE_API;
export const flowDiscoveryWallet = import.meta.env
	.VITE_APP_FLOW_DISCOVERY_WALLET;
export const flowDiscoveryAuthnEndpoint = import.meta.env
	.VITE_APP_FLOW_DISCOVERY_AUTHN_ENDPOINT;
export const flowDiscoveryAuthnInclude = import.meta.env
	.VITE_APP_FLOW_DISCOVERY_AUTHN_INCLUDE;

export const auth0Domain = import.meta.env.VITE_APP_AUTH0_DOMAIN;
export const auth0ClientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID;
export const auth0Audience = import.meta.env.VITE_APP_AUTH0_AUDIENCE;

export const publicVapidKey = import.meta.env.VITE_APP_PUBLIC_VAPID_KEY;

export const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN;
export const sentrySampleRate = import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE;
export const sentrySessionSampleRate = import.meta.env.VITE_APP_SENTRY_SESSION_SAMPLE_RATE;
export const sentryErrorSampelRate = import.meta.env.VITE_APP_SENTRY_ERROR_SAMPLE_RATE;