import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import ForgeggHomeView from "./views/forgegg-home/forgegg-home";

const forgeggRoutes: RouteObject[] = [
	{
		path: Routes.QuestForgegg,
		element: (
			<Layout>
				<ForgeggHomeView />
			</Layout>
		),
	},
];

export default forgeggRoutes;
