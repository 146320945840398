import { Button } from "@/common/components/ui/button";
import { Routes } from "@/common/types/routes";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

function LandingView() {
	return (
		<>
			<Helmet>
				<title>Fight.id - Claim Your UFC Strike Fan Rewards</title>
				<meta
					name="description"
					content="Claim your Fight.id and get access to exclusive UFC Strike fan rewards. Join now to unlock unique benefits and opportunities."
				/>
				<meta
					property="og:title"
					content="Fight.id - Claim Your UFC Strike Fan Rewards"
				/>
				<meta
					property="og:description"
					content="Claim your Fight.id and get access to exclusive UFC Strike fan rewards. Join now to unlock unique benefits and opportunities."
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href={window.location.href} />
			</Helmet>

			<div className="flex flex-col h-full w-full py-14">
				<div className="flex-1 flex justify-center px-4">
					<div className="flex flex-col gap-8 w-full max-w-sm md:max-w-full items-center">
						<h1 className="text-3xl md:text-6xl leading-[2.75rem] font-bold uppercase">
							Claim your{" "}
							<span className="italic relative inline-block">
								Fight.id
								<div className="absolute bottom-1 md:-bottom-0 -z-10 left-0 w-full">
									<svg
										width="100%"
										height="12"
										viewBox="0 0 216 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="drop-shadow-[0_0_8px_#FF4001]"
									>
										<path
											d="M2.34146 0H216L213.659 12H0L2.34146 0Z"
											fill="#FF4001"
										/>
									</svg>
								</div>
							</span>{" "}
							and access exclusive ufc strike fan rewards
						</h1>

						<Button
							className="uppercase text-xl h-16 font-semibold max-w-[400px] w-full md:w-fit"
							asChild
						>
							<NavLink to={Routes.Signup}>Claim Now</NavLink>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default LandingView;
