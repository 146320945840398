import Typography from "@/common/components/design/texts/typography";
import { PromoBanner } from "../../components/cards/promo-banner";
import { QuestCardsListWrapper } from "../../components/cards/quest-cards-list-wrapper";
import { CampJoinDialog } from "../../components/dialogs/camp-join-dialog";
import { UserInfo } from "../../components/user/user-info";

function HomeView() {
	return (
		<div className="flex flex-col w-full h-full gap-y-10 justify-start items-center md:items-start">
			{/* Camp Join Dialog - RTK Query will handle cache invalidation */}
			<CampJoinDialog />

			<div className="flex flex-col md:flex-row justify-normal md:justify-between gap-6 w-full">
				<UserInfo />

				{/* <UserBalance /> */}
			</div>

			<PromoBanner />

			<div className="flex flex-col gap-4 items-center w-full">
				<Typography variant="title-small" className="text-3xl mt-4 uppercase">
					Your Quests!
				</Typography>

				<QuestCardsListWrapper />
			</div>
		</div>
	);
}

export default HomeView;
