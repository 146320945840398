import { cn } from "@/common/lib/utils";
import { Routes } from "@/common/types/routes";
import { NavLink } from "react-router-dom";
import CampsGlovesIcon from "../../icons/camps-gloves-icon";
import HomeIcon from "../../icons/home-icon";
import { useQuestsStore } from "@/common/hooks/use-quests-store";
import { QUEST_IDS, QuestId } from "@/common/constants/quest-ids";

const getNavItems = (isQuestEnabled: (questId: QuestId) => boolean) => [
	{
		name: "Home",
		icon: HomeIcon,
		path: Routes.Home,
		// Home is always enabled
		enabled: isQuestEnabled(QUEST_IDS.CAMP_FORGE),
	},
	{
		name: "Camps Leaderboard",
		icon: CampsGlovesIcon,
		path: Routes.Camps,
		// Only show the Camps link if the CAMP_FORGE quest is enabled
		enabled: isQuestEnabled(QUEST_IDS.CAMP_FORGE),
	},
];

function NavFooter() {
	const isQuestEnabled = useQuestsStore((state) => state.isQuestEnabled);

	// Generate the nav items using the quest store
	const navItems = getNavItems(isQuestEnabled);

  if (navItems.every((item) => !item.enabled)) {
    return null;
  }

	return (
		<nav className="fixed bottom-0 left-0 right-0 z-50 bg-[#090909] block md:hidden">
			<div className="flex justify-between items-center h-16">
				{navItems
					.filter((item) => item.enabled)
					.map((item) => (
						<NavLink
							key={item.name}
							to={item.path}
							className={({ isActive }) =>
								cn(
									"flex flex-col items-center justify-center flex-1 py-2 text-white uppercase text-sm text-center",
									isActive && [
										"text-red-500",
										"after:content-['']",
										"after:absolute",
										"after:h-[0px]",
										"after:bg-red-500",
										"relative",
										"after:shadow-[0_0_10px_2px_rgba(239,68,68,0.7)]",
										"bg-[radial-gradient(50%_75%_at_50%_0%,rgba(255,64,1,0.25)_0%,rgba(255,64,1,0)_100%)]",
									]
								)
							}
						>
							<item.icon className="h-6 w-6 mb-1" />
							<span className="text-xs uppercase">{item.name}</span>
						</NavLink>
					))}
			</div>
		</nav>
	);
}

export default NavFooter;
