import { createSlice } from "@reduxjs/toolkit";

const routeSlice = createSlice({
	name: "route",
	initialState: {
		redirectTo: "",
	},
	reducers: {
		setRedirectTo: (state, action) => {
			state.redirectTo = action.payload;
		},
	},
});

export const { setRedirectTo } = routeSlice.actions;

export default routeSlice.reducer;
