import * as Sentry from "@sentry/react";
import { appEnvironment, backUrl, sentryDsn, sentryErrorSampelRate, sentrySampleRate, sentrySessionSampleRate } from "./environment";

Sentry.init({
  dsn: sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  
  // Tracing
  tracesSampleRate: sentrySampleRate, //  Capture 100% of the transactions
  environment: appEnvironment,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", backUrl],

  // Session Replay
  replaysSessionSampleRate: sentrySessionSampleRate, // Sets the sample rate at 10% on prod, 100% on dev.
  replaysOnErrorSampleRate: sentryErrorSampelRate, // Change the sample rate to 100% when sampling sessions where errors occur.
});
