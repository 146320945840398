import { OktaAuthWrapper } from "@/common/components/auth/auth-wrapper-okta";
import { UsernameGuard } from "@/common/components/auth/username-guard";
// import Typography from "@/common/components/design/texts/typography";
// import { auth0AuthorizationParams } from "@/common/config/auth0.config";
import { Loader2 } from "lucide-react";

// Show a loading view while the user is being loaded, including their username.fight
function LoadingUserView() {
	return (
		<UsernameGuard>
			<OktaAuthWrapper>
				<div className="flex flex-col items-center text-center justify-center w-full gap-y-4 ">
					<div className="text-center">
						<Loader2 className="w-16 h-16 animate-spin text-primary mb-4" />
					</div>
				</div>
			</OktaAuthWrapper>
		</UsernameGuard>
	);
}

export default LoadingUserView;
