/**
 * Utility functions for handling country names and codes
 */

// Mapping of country names to ISO 3166-1 alpha-2 codes
const countryNameToCode: Record<string, string> = {
	Afghanistan: "af",
	Albania: "al",
	Algeria: "dz",
	Angola: "ao",
	Argentina: "ar",
	Armenia: "am",
	Australia: "au",
	Austria: "at",
	Azerbaijan: "az",
	Bahrain: "bh",
	Bangladesh: "bd",
	Belarus: "by",
	Belgium: "be",
	Bolivia: "bo",
	"Bosnia and Herzegovina": "ba",
	Brazil: "br",
	Bulgaria: "bg",
	Cambodia: "kh",
	Cameroon: "cm",
	Canada: "ca",
	Chile: "cl",
	China: "cn",
	Colombia: "co",
	"Costa Rica": "cr",
	Croatia: "hr",
	Cuba: "cu",
	Cyprus: "cy",
	Czechia: "cz",
	"Czech Republic": "cz",
	Denmark: "dk",
	"Dominican Republic": "do",
	Ecuador: "ec",
	Egypt: "eg",
	"El Salvador": "sv",
	Estonia: "ee",
	Ethiopia: "et",
	Fiji: "fj",
	Finland: "fi",
	France: "fr",
	Georgia: "ge",
	Germany: "de",
	Ghana: "gh",
	Greece: "gr",
	Guatemala: "gt",
	Haiti: "ht",
	Honduras: "hn",
	"Hong Kong": "hk",
	Hungary: "hu",
	Iceland: "is",
	India: "in",
	Indonesia: "id",
	Iran: "ir",
	Iraq: "iq",
	Ireland: "ie",
	Israel: "il",
	Italy: "it",
	"Ivory Coast": "ci",
	Jamaica: "jm",
	Japan: "jp",
	Jordan: "jo",
	Kazakhstan: "kz",
	Kenya: "ke",
	Kuwait: "kw",
	Latvia: "lv",
	Lebanon: "lb",
	Libya: "ly",
	Lithuania: "lt",
	Luxembourg: "lu",
	Madagascar: "mg",
	Malaysia: "my",
	Mali: "ml",
	Malta: "mt",
	Mexico: "mx",
	Moldova: "md",
	Monaco: "mc",
	Mongolia: "mn",
	Montenegro: "me",
	Morocco: "ma",
	Mozambique: "mz",
	Myanmar: "mm",
	Namibia: "na",
	Nepal: "np",
	Netherlands: "nl",
	"New Zealand": "nz",
	Nicaragua: "ni",
	Nigeria: "ng",
	"North Korea": "kp",
	"North Macedonia": "mk",
	Norway: "no",
	Oman: "om",
	Pakistan: "pk",
	Panama: "pa",
	"Papua New Guinea": "pg",
	Paraguay: "py",
	Peru: "pe",
	Philippines: "ph",
	Poland: "pl",
	Portugal: "pt",
	"Puerto Rico": "pr",
	Qatar: "qa",
	Romania: "ro",
	Russia: "ru",
	Rwanda: "rw",
	"Saudi Arabia": "sa",
	Senegal: "sn",
	Serbia: "rs",
	Singapore: "sg",
	Slovakia: "sk",
	Slovenia: "si",
	"South Africa": "za",
	"South Korea": "kr",
	Spain: "es",
	"Sri Lanka": "lk",
	Sudan: "sd",
	Sweden: "se",
	Switzerland: "ch",
	Syria: "sy",
	Taiwan: "tw",
	Tajikistan: "tj",
	Tanzania: "tz",
	Thailand: "th",
	Tunisia: "tn",
	Turkey: "tr",
	Turkmenistan: "tm",
	Uganda: "ug",
	Ukraine: "ua",
	"United Arab Emirates": "ae",
	"United Kingdom": "gb",
	"United States": "us",
	Uruguay: "uy",
	USA: "us",
	Uzbekistan: "uz",
	Venezuela: "ve",
	Vietnam: "vn",
	Yemen: "ye",
	Zimbabwe: "zw",
};

// Reverse mapping of ISO codes to country names
// Note: For duplicate codes (like "us" for both "United States" and "USA"),
// the last entry in the countryNameToCode object will be used
const countryCodeToName: Record<string, string> = Object.entries(
	countryNameToCode
).reduce((acc, [name, code]) => {
	acc[code] = name;
	return acc;
}, {} as Record<string, string>);

/**
 * Converts a country name to its ISO 3166-1 alpha-2 code
 * @param countryName Full country name
 * @returns ISO country code or the original string converted to lowercase if not found
 */
export const getCountryCode = (countryName: string): string => {
	// If it's already a 2-letter code, return it as is
	if (countryName?.length === 2) {
		return countryName.toLowerCase();
	}

	// Check if we have a mapping for this country name
	const code = countryNameToCode[countryName];

	// Return the code if found, otherwise return the original string in lowercase
	return code || countryName?.toLowerCase() || "";
};

/**
 * Converts an ISO 3166-1 alpha-2 code to its full country name
 * @param countryCode ISO country code
 * @returns Full country name or the original code if not found
 */
export const getCountryName = (countryCode: string): string => {
	// If it's not a 2-letter code, return it as is
	if (!countryCode || countryCode.length !== 2) {
		return countryCode || "";
	}

	// Normalize the code to lowercase
	const normalizedCode = countryCode.toLowerCase();

	// Return the country name if found, otherwise return the original code
	return countryCodeToName[normalizedCode] || countryCode;
};

/**
 * Gets the flag URL for a country
 * @param country Country name or code
 * @param size Size of the flag (w20, w40, w80, etc.)
 * @returns URL to the country flag
 */
export const getCountryFlagUrl = (
	country: string,
	size: string = "w20"
): string => {
	const countryCode = getCountryCode(country);
	return `https://flagcdn.com/${size}/${countryCode}.png`;
};
