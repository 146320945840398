import logger from "@/common/utils/logger";
import { Network } from "@aptos-labs/ts-sdk";
import "@aptos-labs/wallet-adapter-core";
import {
	AptosWalletAdapterProvider,
	AvailableWallets,
} from "@aptos-labs/wallet-adapter-react";
import { PropsWithChildren } from "react";

export const WalletProvider = ({ children }: PropsWithChildren) => {
	const optInWallets: AvailableWallets[] = [
		"Petra",
		"Continue with Google",
	] as AvailableWallets[];

	return (
		<AptosWalletAdapterProvider
			optInWallets={optInWallets}
			autoConnect
			dappConfig={{
				network: Network.MAINNET,
				aptosConnect: {
					dappName: "FightFi",
					dappId: "92b13320-22fd-4c59-aec7-9b55ced81bd9",
					dappImageURI: "https://fight.id/icons/favicons/black/48x48.png",
				},
			}}
			onError={(error) => {
				if (typeof error === "string" && error === "WalletNotConnectedError") {
					logger.warn("Wallet not connected", "WalletProvider - Aptos");
				} else {
					logger.error(error, "WalletProvider - Aptos");
				}
			}}
		>
			{children}
		</AptosWalletAdapterProvider>
	);
};
