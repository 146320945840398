import { Card, CardContent } from "@/common/components/ui/card";
import CornerArrow from "@/modules/home/components/cards/ui/corner-arrow";

// Backgrounds
import ReferralsImage from "@/modules/home/assets/quests/referrals.png";
import WalletImage from "@/modules/home/assets/quests/connect-account.png";
import ForgeImage from "@/modules/home/assets/quests/forge.png";
import GigantikImage from "@/modules/home/assets/quests/gigantik.png";
import logger from "@/common/utils/logger";
import CampChallengeBGImage from "@/modules/home/assets/quests/camp-challenge-bg.png";
import { QUEST_IDS } from "@/common/constants/quest-ids";
import { useUserStore } from "@/common/hooks/use-user-store";

// Improve to CDN images
const QUEST_IMAGES: Record<string, { src: string; alt: string }> = {
	[QUEST_IDS.REFERRAL_QUEST]: {
		src: ReferralsImage,
		alt: "Referrals Quest",
	},
	[QUEST_IDS.CONNECT_WALLET]: {
		src: WalletImage,
		alt: "Connect Wallet Quest",
	},
	[QUEST_IDS.FORGE_QUEST]: {
		src: ForgeImage,
		alt: "Forge Quests",
	},
	[QUEST_IDS.GIGANTIK]: {
		src: GigantikImage,
		alt: "Lights Out II",
	},
	[QUEST_IDS.CAMP_FORGE]: {
		src: CampChallengeBGImage,
		alt: "Camp Forge Quest",
	},
};

type QuestCardProps = {
	id: string;
	title: string;
	description: string;
	createdAt: Date;
	dueDate: Date | null;
	limitedTime: boolean;
	onClick: () => void;
};

/**
 * Checks if a quest is new (created within the last 7 days)
 */
const isQuestNew = (createdAt: Date): boolean => {
	try {
		if (!createdAt) return false;
		const now = new Date().getTime();
		const creationTime = new Date(createdAt).getTime();
		const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
		return now - creationTime < oneWeekInMs;
	} catch (error) {
		logger.error(error, "Quest Card | Is Quest New");
		return false;
	}
};

/**
 * Calculates the time remaining until the due date
 */
const calculateTimeRemaining = (dueDate: Date | null): string => {
	try {
		if (!dueDate) return "Limited Time";

		const now = new Date();
		const end = new Date(dueDate);

		// Check if dueDate is valid
		if (isNaN(end.getTime())) {
			logger.error(dueDate, "Quest Card | Invalid due date");
			return "Limited Time";
		}

		// Handle case where due date is in the past
		const diff = end.getTime() - now.getTime();
		if (diff <= 0) {
			logger.error(dueDate, "Quest Card | Due date is in the past");
			return "Limited Time";
		}

		const days = Math.floor(diff / (1000 * 60 * 60 * 24));
		const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

		return `${days}d ${hours}h`;
	} catch (error) {
		logger.error(error, "Quest Card | Calculate Time Remaining");
		return "Limited Time";
	}
};

export default function QuestCard({
	id,
	title,
	description,
	createdAt,
	dueDate,
	limitedTime,
	onClick,
}: QuestCardProps) {
	const questImage = QUEST_IMAGES[id];
	const isNewQuest = isQuestNew(createdAt);

	const userCampMember = useUserStore((state) => state.user.campMember);

	const RenderAdditionalContent = () => {
		if (!id) return null;

		if (id === QUEST_IDS.CAMP_FORGE) {
			return (
				<img
					src={userCampMember?.camp?.fighterImage}
					alt="Fighter Picture"
					className="absolute right-20 h-full object-cover max-w-[200px]"
				/>
			);
		}
	};

	const RenderQuestTitle = () => {
		if (!id) return null;

		if (id === QUEST_IDS.CAMP_FORGE) {
			return title.includes("{name}")
				? title.replace("{name}", userCampMember?.camp?.fighterName || "")
				: title;
		}

		return title;
	};

	return (
		<Card
			className="relative bg-black text-white hover:bg-zinc-900 transition-colors cursor-pointer group border-none overflow-hidden rounded-none"
			onClick={onClick}
		>
			{limitedTime ? (
				<div className="absolute inset-0 z-20 left-6 top-4 bg-terciary text-black w-fit h-fit px-2 py-1">
					Limited Time - ends in {calculateTimeRemaining(dueDate)}
				</div>
			) : isNewQuest ? (
				<div className="absolute inset-0 z-20 left-6 top-4 bg-terciary text-black w-fit h-fit px-2 py-1">
					NEW QUEST
				</div>
			) : null}
			{questImage && (
				<img
					src={questImage.src}
					alt={questImage.alt}
					className="absolute inset-0 w-full h-full object-cover"
				/>
			)}
			<RenderAdditionalContent />
			<CardContent className="p-6 flex flex-col justify-end min-h-[200px]">
				{questImage && <div className="absolute inset-0" />}
				<div className="space-y-2 relative z-10">
					<div className="flex items-center justify-between gap-8">
						<h3 className="text-xl uppercase max-w-[200px] md:max-w-[290px] break-words">{RenderQuestTitle()}</h3>
					</div>
					<p className="text-zinc-400">{description}</p>
				</div>
				<CornerArrow />
			</CardContent>
		</Card>
	);
}
