import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import CampsLeaderboardsView from "./views/leaderboards/leaderboards";
import ChooseCampView from "./views/camps-list/choose-camp";
import CampDetailsView from "./views/camp-details/camp-details";

const campsRoutes: RouteObject[] = [
	{
		path: Routes.Camps,
		element: (
			<Layout>
				<CampsLeaderboardsView />
			</Layout>
		),
	},
	{
		path: Routes.CampsDetails,
		element: (
			<Layout>
				<CampDetailsView />
			</Layout>
		),
	},
	{
		path: Routes.CampChoose,
		element: (
			<Layout>
				<ChooseCampView />
			</Layout>
		),
	},
];

export default campsRoutes;
