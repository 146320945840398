import { create } from "zustand";

interface OAuthParams {
	clientId: string;
	redirectUri: string;
	scope?: string;
	state?: string;
}

interface OAuthStore {
	params: OAuthParams | null;
	setParams: (params: OAuthParams | null) => void;
	clearParams: () => void;
}

export const useOAuthStore = create<OAuthStore>((set) => ({
	params: null,
	setParams: (params) => set({ params }),
	clearParams: () => set({ params: null }),
}));
