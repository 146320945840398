import Footer from "@/common/components/shared/footer/footer";
import Header from "@/common/components/shared/header/header";
import { cn } from "@/common/lib/utils";
// import { Routes } from "@/common/types/routes";
import { PageBackground } from "./components/layout/backgrounds";

function Layout({ children }: { children: React.ReactNode }) {
	// const isLandingRoute = [Routes.Landing].includes(location.pathname as Routes);

	return (
		<div className="relative flex flex-col h-screen w-full">
			{/* Fixed Background */}
			<PageBackground />

			{/* Content Container */}
			<Header />

			{/* Content Container */}
			{/* {isAuthRoute && (
				<div className="flex-1 flex flex-col z-10 items-center justify-center text-center min-h-[50vh] text-primary-foreground">
					<div className="space-y-4">
						<Typography variant="subtitle" className="text-white">
							CLAIM YOUR
						</Typography>

						<img src={FightFiLogo} className="h-24 mx-auto" />

						<Typography variant="title-small">
							Earn Reputation and Airdrop entries, join and stake on fighters
							fan club!
						</Typography>
					</div>
				</div>
			)} */}
			<main
				className={cn(
					"relative z-10 flex flex-col px-4",
					"justify-center md:justify-start items-center text-center",
					"h-[calc(100vh-3.5rem)] overflow-scroll md:overflow-auto",
					"md:max-w-7xl md:mx-auto"
				)}
			>
				{children}
			</main>
			<Footer />
		</div>
	);
}

export default Layout;
