import React from "react";
import Typography from "../components/design/texts/typography";
import { NavBackButton } from "../components/design/buttons/nav-back-button";
import { Routes } from "../types/routes";
import AuthLayout from "../components/auth-layout";
import Layout from "@/modules/login/layout";
import { JSX } from "react/jsx-runtime";
import { useUserStore } from "../hooks/use-user-store";

const NotFound: React.FC = () => {
	const isAuthenticated = useUserStore((state) => state.user.email);

	const renderLayout = (children: JSX.Element) => {
		return isAuthenticated ? (
			<AuthLayout>{children}</AuthLayout>
		) : (
			<Layout>{children}</Layout>
		);
	};

	return renderLayout(
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-center md:items-start md:text-left">
			<div className="flex flex-col md:flex-row md:w-full gap-x-8 md:items-start md:justify-between gap-4">
				<div className="flex flex-col gap-4">
					<div className="flex w-full items-center relative md:justify-start justify-center">
						<NavBackButton
							to={isAuthenticated ? Routes.Home : Routes.Landing}
							className="absolute left-0 md:static md:mr-4"
						/>
						<Typography variant="title" className="text-2xl uppercase">
							Oops
						</Typography>
					</div>

					<Typography variant="body" className="font-bold text-lg">
						The page you are looking for doesn't exist.
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
