import { cn } from "@/common/lib/utils";

interface ValidationItemProps {
	isValid: boolean;
	message: string;
}

export function ValidationItem({ isValid, message }: ValidationItemProps) {
	return (
		<li className="flex items-center gap-2">
			<span
				className={cn(
					"transition-colors",
					isValid ? "text-green-700" : "text-primary"
				)}
			>
				{message}
			</span>
		</li>
	);
}
