import { useUserStore } from "@/common/hooks/use-user-store";

export const UserBalance = ({
	ffBalance,
	fightBalance,
}: {
	ffBalance: string;
	fightBalance: string;
}) => {
	const username = useUserStore((store) => store.user.username);

	return (
		<div className="w-full flex justify-between items-center">
			<div className="flex-1 flex">
				<h2 className="text-xl font-semibold uppercase">{username}.FIGHT</h2>
			</div>
			<div className="flex flex-col items-end">
				<span className="text-gray-600 text-sm ">BALANCE:</span>
				<div className="text-right font-bold ">
					<div>{ffBalance} $FF</div>
					<div>{fightBalance} $FIGHT</div>
				</div>
			</div>
		</div>
	);
};
