export function useValidationChecks(value: string) {
	return {
		length: {
			valid: value.length >= 5 && value.length <= 20,
			message: "5-20 characters",
		},
		characters: {
			valid: value.length > 0 && /^[a-zA-Z0-9]*$/.test(value),
			message: "Only letters or numbers",
		},
	};
}
