import MainButton from "@/common/components/design/buttons/button";
import Typography from "@/common/components/design/texts/typography";
import { Quest } from "@/common/types/entities/quest/quest";
import { useNavigate } from "react-router-dom";

export function JoinCampBanner({ quest }: { quest: Quest }) {
	const navigate = useNavigate();

	const handleQuestCardClick = (route: string) => {
		navigate(route);
	};

	return (
		<div className="flex flex-col md:flex-row gap-4 md:justify-between md:w-full">
			<div className="flex flex-row gap-4">
				<div className="flex flex-col gap-2">
					<Typography variant="title-small" className="text-2xl ">
						⚡ Powered by UFC Strike: Limited-Time Exclusive Challenge Awaits!
					</Typography>

					<Typography variant="body" className="text-sm text-white font-light">
						Choose your lock for this week{" "}
					</Typography>
				</div>
			</div>

			<MainButton
				onClick={() => handleQuestCardClick(quest.ctaLink)}
				className="text-xl font-semibold md:max-w-60"
			>
				Join Now
				<svg
					width="17"
					height="15"
					viewBox="0 0 17 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.50474 0L17 7.50001L9.50474 15L7.54697 13.041L11.7001 8.88525H0V6.11476H11.7001L7.54697 1.95904L9.50474 0Z"
						fill="white"
					/>
				</svg>
			</MainButton>
		</div>
	);
}
