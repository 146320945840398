import React from "react";

const GoogleIcon: React.FC<Partial<HTMLButtonElement>> = ({ className }) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g clipPath="url(#clip0_1217_4)">
			<path
				d="M9.625 16.2477C9.625 15.0587 9.95261 13.9448 10.5218 12.991V8.94531H6.47608C4.87038 11.0307 4 13.5722 4 16.2477C4 18.9233 4.87038 21.4648 6.47608 23.5502H10.5218V19.5045C9.95261 18.5507 9.625 17.4368 9.625 16.2477Z"
				fill="#FBBD00"
			/>
			<path
				d="M16 22.6227L13.1875 25.4352L16 28.2477C18.6756 28.2477 21.217 27.3773 23.3024 25.7716V21.7302H19.261C18.2989 22.3014 17.1804 22.6227 16 22.6227Z"
				fill="#0F9D58"
			/>
			<path
				d="M10.5218 19.5044L6.47608 23.5501C6.79399 23.963 7.14016 24.3583 7.51474 24.733C9.78124 26.9994 12.7947 28.2477 16 28.2477V22.6227C13.6739 22.6227 11.6351 21.3702 10.5218 19.5044Z"
				fill="#31AA52"
			/>
			<path
				d="M28 16.2476C28 15.5176 27.9339 14.7861 27.8035 14.0737L27.698 13.4972H16V19.1222H21.6931C21.1402 20.2219 20.2902 21.1192 19.261 21.7302L23.3024 25.7716C23.7153 25.4537 24.1106 25.1075 24.4853 24.7329C26.7518 22.4664 28 19.4529 28 16.2476Z"
				fill="#3C79E6"
			/>
			<path
				d="M20.5078 11.7399L21.005 12.237L24.9825 8.25957L24.4853 7.76241C22.2188 5.49592 19.2054 4.24768 16 4.24768L13.1875 7.06018L16 9.87268C17.7028 9.87268 19.3037 10.5358 20.5078 11.7399Z"
				fill="#CF2D48"
			/>
			<path
				d="M16 9.87268V4.24768C12.7947 4.24768 9.78123 5.49592 7.51469 7.76237C7.14011 8.13695 6.79393 8.53234 6.47603 8.94526L10.5217 12.9909C11.6351 11.1252 13.6739 9.87268 16 9.87268Z"
				fill="#EB4132"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1217_4">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(4 4.24768)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default GoogleIcon;
