import BaseCard from "@/common/components/design/card/base-card";
import {
	CardContent,
	CardHeader,
	CardTitle,
} from "@/common/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/common/components/ui/chart";
import { Skeleton } from "@/common/components/ui/skeleton";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import {
	useGetAccountsGrowthQuery,
	useGetAccountsKPIQuery,
} from "../../services/admin.api";

export function AccountKPI() {
	const { data: accountKPIData } = useGetAccountsKPIQuery();
	const { data: accountGrowthData, isLoading: accountGrowthIsLoading } =
		useGetAccountsGrowthQuery();

	const chartConfig = {
		month: {
			label: "Month",
			color: "hsl(var(--chart-1))",
		},
		total: {
			label: "Total",
			color: "hsl(var(--chart-2))",
		},
	} satisfies ChartConfig;

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
			<BaseCard className="bg-muted rounded-sm">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium">Total Users</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-2xl font-bold">
						{accountKPIData?.data.totalAccounts}
					</div>
					<p className="text-xs text-muted-foreground">
						Sign-ups since the beginning
					</p>
				</CardContent>
			</BaseCard>
			<BaseCard className="bg-muted rounded-sm">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium">New Sign-ups</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-2xl font-bold">
						{accountKPIData?.data.newAccounts}
					</div>
					<p className="text-xs text-muted-foreground">
						Sign-ups on the last 7 days
					</p>
				</CardContent>
			</BaseCard>
			<BaseCard className="bg-muted col-span-4 rounded-sm">
				<CardHeader>
					<CardTitle>User Growth</CardTitle>
				</CardHeader>
				{accountGrowthIsLoading ? (
					<Skeleton className="w-6/12 h-56" />
				) : (
					<CardContent className="pl-2">
						<ChartContainer config={chartConfig}>
							<BarChart
								data={accountGrowthData?.data.map((growth) => ({
									month: growth.month,
									total: growth.totalUsers,
								}))}
							>
								<XAxis
									dataKey="month"
									stroke="#888888"
									fontSize={12}
									tickLine={false}
									axisLine={false}
								/>
								<YAxis
									stroke="#888888"
									fontSize={12}
									tickLine={false}
									axisLine={false}
									tickFormatter={(value) => `${value}`}
								/>
								<Bar dataKey="total" fill="#FAFAFA" radius={[4, 4, 0, 0]} />
								<ChartTooltip
									content={<ChartTooltipContent />}
									cursor={false}
									defaultIndex={1}
								/>
							</BarChart>
						</ChartContainer>
					</CardContent>
				)}
			</BaseCard>
		</div>
	);
}
