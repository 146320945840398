// PWABadge.tsx
// import { toast } from "sonner";
import { useRegisterSW } from "virtual:pwa-register/react";
// import { useEffect } from "react";

function PWABadge() {
	// check for updates every hour
	const period = 60 * 60 * 1000;

	// const {
	// 	offlineReady: [offlineReady, setOfflineReady],
	// 	needRefresh: [needRefresh, setNeedRefresh],
	// 	updateServiceWorker,
	// } =
	useRegisterSW({
		onRegisteredSW(swUrl, r) {
			if (period <= 0) return;
			if (r?.active?.state === "activated") {
				registerPeriodicSync(period, swUrl, r);
			} else if (r?.installing) {
				r.installing.addEventListener("statechange", (e) => {
					const sw = e.target as ServiceWorker;
					if (sw.state === "activated") {
						registerPeriodicSync(period, swUrl, r);
					}
				});
			}
		},
		onRegisterError(error) {
			console.error("SW registration error:", error);
		},
	});

	// function close() {
	// 	setOfflineReady(false);
	// 	setNeedRefresh(false);
	// }

	// Show toast when offline ready or needs refresh
	// useEffect(() => {
	// 	if (offlineReady || needRefresh) {
	// 		toast(
	// 			offlineReady ? "App ready to work offline" : "New content available",
	// 			{
	// 				description: needRefresh
	// 					? "Click on reload button to update."
	// 					: undefined,
	// 				action: {
	// 					label: needRefresh ? "Reload" : "Close",
	// 					onClick: () => {
	// 						if (needRefresh) {
	// 							updateServiceWorker(true);
	// 						} else {
	// 							close();
	// 						}
	// 					},
	// 				},
	// 			}
	// 		);
	// 	}
	// }, [offlineReady, needRefresh]);

	return null;
}

export default PWABadge;

/**
 * This function will register a periodic sync check
 */
function registerPeriodicSync(
	period: number,
	swUrl: string,
	r: ServiceWorkerRegistration
) {
	if (period <= 0) return;

	setInterval(async () => {
		if ("onLine" in navigator && !navigator.onLine) return;

		const resp = await fetch(swUrl, {
			cache: "no-store",
			headers: {
				cache: "no-store",
				"cache-control": "no-cache",
			},
		});

		if (resp?.status === 200) await r.update();
	}, period);
}
