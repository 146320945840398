import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import Typography from "@/common/components/design/texts/typography";
import { useGetReferralInfoQuery } from "@/common/services/referrals.api";
import { Routes } from "@/common/types/routes";
import PointsSummary from "../../components/points/points-summary";
import SocialCards from "../../components/social-cards/social-cards";
import SocialCardsSkeleton from "../../components/social-cards/social-cards-skeleton";
import ReferralHistory from "../../components/history/referral-history";
import BaseCard from "@/common/components/design/card/base-card";

function ReferFriendsView() {
	const { data, isLoading } = useGetReferralInfoQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-row gap-4 p-4">
				<div>
					<NavBackButton to={Routes.Home} />
				</div>

				<div className="flex flex-col md:flex-row md:w-full gap-x-8 md:items-start md:justify-between gap-4">
					<div className="flex flex-col gap-4">
						<div className="flex w-full relative justify-start">
							<Typography variant="title">Refer Friends</Typography>
						</div>

						<Typography variant="body">
							You'll earn 100FF for each friend who signs up using your referral
							link
						</Typography>
					</div>

          <div className="md:max-w-[400px]">
						{data?.data && (
              <SocialCards referralCode={data?.data.referralCode || ""} />
						)}
						{isLoading && <SocialCardsSkeleton />}
					</div>
				</div>
			</BaseCard>

			<Typography variant="subtitle" className="text-4xl font-bold uppercase">
				Your Referrals
			</Typography>

			<PointsSummary
				isLoading={isLoading}
				totalReferrals={data?.data?.totalReferrals}
				totalPoints={data?.data?.totalPoints}
			/>

			{data?.data && <ReferralHistory history={data.data.referralHistory} />}
		</div>
	);
}

export default ReferFriendsView;
