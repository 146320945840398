import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserStore } from "@/common/hooks/use-user-store";
import { Routes } from "@/common/types/routes";
import { useLazyGetUserDataQuery } from "@/common/services/user.api";
import logger from "@/common/utils/logger";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useAuth0 } from "@auth0/auth0-react";

export function UsernameGuard({ children }: { children: React.ReactNode }) {
	const { account, connected, isLoading: isWalletLoading } = useWallet();
	const { isAuthenticated: isAuth0AUthenticated, isLoading: isAuth0Loading } =
		useAuth0();

	// Merged authentication states
	const isAuthenticated = connected || isAuth0AUthenticated;
	const isLoading = isWalletLoading || isAuth0Loading;

	const navigate = useNavigate();
	const location = useLocation();
	const updateUsername = useUserStore((state) => state.updateUsername);
	const username = useUserStore((state) => state.user.username);
	const userToken = useUserStore((state) => state.user.token);
	const [getUserData] = useLazyGetUserDataQuery();

	useEffect(() => {
		const checkUsername = async () => {
			// Skip check if we're already on the claim-fight-id page
			if (location.pathname === Routes.ClaimFightID) {
				return;
			}

			// If we have a username in the store, we're good
			if (username && location.pathname === Routes.AuthLoading) {
				navigate(Routes.Home);
				return;
			} else if (username) {
				return;
			}

			// If authenticated but no username, check with API
			if (
				// Aptos Auth
				// is auth with aptos
				(isAuthenticated &&
					// finished loading
					!isLoading &&
					// no username in store
					!username &&
					// user token exists
					userToken) || // OTP Auth
				// no username in store
				(!username &&
					// user token exists
					userToken)
			) {
				try {
					// const token = await getAccessTokenSilently({
					// 	cacheMode: "off",
					// });
					// const profile = await getUserData(authUser?.email || "");
					const profile = await getUserData();

					if (profile.data?.data.username) {
						// Update store with API data
						updateUsername(profile.data?.data.username);
						// navigate(Routes.Home);
					} else {
						// No username found, redirect to claim page
						navigate(Routes.ClaimFightID);
					}
				} catch (error) {
					logger.error(error, "UsernameGuard");
					navigate(Routes.ClaimFightID);
				}
			}
		};

		checkUsername();
	}, [
		isAuthenticated,
		isLoading,
		username,
		userToken,
		account?.address.toString(),
		location.pathname,
	]);

	return <>{children}</>;
}
