import Typography from "@/common/components/design/texts/typography";
import { Switch } from "@/common/components/ui/switch";
import { useUpdateNotificationSettingsMutation } from "@/common/services/user.api";
import { useUserStore } from "@/common/hooks/use-user-store";
import { toast } from "sonner";

export default function NotificationPreferencesForm() {
	const user = useUserStore((state) => state.user);
	const [updateSettings, { isLoading }] =
		useUpdateNotificationSettingsMutation();

	const handleSettingChange = async (
		setting: "pushNotificationsEnabled" | "emailNotificationsEnabled",
		enabled: boolean
	) => {
		try {
			await updateSettings({
				setting,
				enabled,
			}).unwrap();

			toast.success("Settings updated successfully");
		} catch (error) {
			toast.error("Failed to update settings");
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex items-center justify-between">
					<div className="flex flex-col">
						<Typography
							variant="body"
							className="font-medium text-lg text-white"
						>
							Push Notifications
						</Typography>
					</div>
					<Switch
						className="data-[state=checked]:bg-terciary"
						disabled={isLoading}
						checked={user.settings.pushNotificationsEnabled ?? false}
						onCheckedChange={(enabled) =>
							handleSettingChange("pushNotificationsEnabled", enabled)
						}
					/>
				</div>

				<div className="flex items-center justify-between">
					<div className="flex flex-col">
						<Typography
							variant="body"
							className="font-medium text-lg text-white"
						>
							Get Email Updates
						</Typography>
					</div>
					<Switch
						className="data-[state=checked]:bg-terciary"
						disabled={isLoading}
						checked={user.settings.emailNotificationsEnabled ?? false}
						onCheckedChange={(enabled) =>
							handleSettingChange("emailNotificationsEnabled", enabled)
						}
					/>
				</div>
			</div>
		</div>
	);
}
