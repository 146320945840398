import { create } from "zustand";

type LoadingState =
	| "idle"
	| "otpSent" // loading screen with email being sent
	| "otpVerified" // success screen after email is verified with OTP
	| "validatingCode" // loading screen with code being validated
	| "validatingNft" // loading screen with NFT being validated
	| "fightIdValidated"; // loading screen with fightId validated

type State = {
	otpSent: boolean; // loading screen with email being sent
	otpVerified: boolean; // success screen after email is verified with OTP
	loadingState: LoadingState; // loading screen with code being validated
};

type Action = {
	updateOtpSent: (otp: State["otpSent"]) => void;
	updateOtpVerified: (otp: State["otpVerified"]) => void;
	updateLoadingState: (otp: State["loadingState"]) => void;

	initializeData: () => void;
};

export const useLoadingStore = create<State & Action>()((set) => ({
	otpSent: false,
	otpVerified: false,
	loadingState: "idle",

	updateOtpSent: (state) => set(() => ({ otpSent: state })),
	updateOtpVerified: (state) => set(() => ({ otpVerified: state })),
	updateLoadingState: (state) => set(() => ({ loadingState: state })),

	initializeData: () => {
		set(() => ({
			otpSent: false,
			otpVerified: false,
			loadingState: "idle",
		}));
	},
}));
