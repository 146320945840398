import { Skeleton } from "@/common/components/ui/skeleton";

export default function SocialCardsSkeleton() {
	return (
		<div className="flex gap-2 justify-evenly">
			<Skeleton className="w-20 h-20" />
			<Skeleton className="w-20 h-20" />
			<Skeleton className="w-20 h-20" />
		</div>
	);
}
