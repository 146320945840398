import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import QuestCampView from "./views/quest-camp/quest-camp";

const campsQuestRoutes: RouteObject[] = [
	{
		path: Routes.QuestCamp,
		element: (
			<Layout>
				<QuestCampView />
			</Layout>
		),
	},
];

export default campsQuestRoutes;
