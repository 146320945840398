import React from "react";

const MailIcon: React.FC<Partial<HTMLButtonElement>> = ({ className }) => (
	<svg
		width="74"
		height="53"
		viewBox="0 0 74 53"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M36.7905 22.7256L72.6331 0.622835C72.1704 0.325259 71.6212 0.150635 71.0302 0.150635H2.55087C1.95988 0.150635 1.41066 0.325259 0.948162 0.622835L36.7905 22.7256Z"
			fill="white"
		/>
		<path
			d="M42.9479 25.9069L38.3495 28.7427C37.8717 29.0373 37.3312 29.1846 36.7907 29.1846C36.2502 29.1846 35.7097 29.0373 35.2318 28.7427L30.6333 25.9069L1.65993 52.1425C1.94127 52.2308 2.24043 52.2789 2.55087 52.2789H71.0302C71.3406 52.2789 71.6398 52.231 71.9211 52.1425L42.9479 25.9069Z"
			fill="white"
		/>
		<path d="M74 46.012V6.75811L48.2121 22.6607L74 46.012Z" fill="white" />
		<path
			d="M-0.418945 6.75811V46.012L25.369 22.6607L-0.418945 6.75811Z"
			fill="white"
		/>
	</svg>
);

export default MailIcon;
