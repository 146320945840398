import { NavLink } from "react-router-dom";
import TelegramLogo from "../../icons/telegram";
import XLogo from "../../icons/x";
import { cn } from "@/common/lib/utils";
import DiscordLogo from "../../icons/discord";

export default function Footer() {
	return (
		<footer className="w-[90%] md:w-[99%] mx-auto z-20 hidden md:block">
			<div className="w-full h-[1px] bg-black/10" />
			<div className="container mx-auto px-2 py-6">
				<div className="flex flex-row items-center gap-6 justify-between">
					{/* Social Icons */}
					<div className="flex gap-8">
						<NavLink
							to="https://x.com/MakeFight_Great/"
							target="_blank"
							className="text-foreground hover:text-muted-foreground"
						>
							<XLogo className={cn("w-9 h-9", "text-white")} />
							<span className="sr-only">X</span>
						</NavLink>
						<NavLink
							to="mailto:support@fight.id"
							className="text-foreground hover:text-muted-foreground"
						>
							<TelegramLogo className={cn("w-9 h-9", "text-white")} />
							<span className="sr-only">Telegram</span>
						</NavLink>
						<NavLink
							to="https://t.co/ob8JFeqAeb"
							target="_blank"
							className="text-foreground hover:text-muted-foreground"
						>
							<DiscordLogo className={cn("w-9 h-9", "text-white")} />
							<span className="sr-only">Discord</span>
						</NavLink>
					</div>

					{/* Navigation Links */}
					<div className="flex flex-col gap-2 items-end">
						<nav className="flex flex-row w-full items-center justify-evenly gap-4  font-semibold text-sm sm:text-lg md:gap-6 md:justify-end">
							<NavLink
								to="#"
								className={cn("hover:text-muted-foreground", "text-white")}
							>
								HELP
							</NavLink>
							<NavLink
								to="/FightFi Claim Terms and Conditions.pdf"
								className={cn("hover:text-muted-foreground", "text-white")}
								target="_blank"
								download
							>
								TERMS OF USE
							</NavLink>
						</nav>
						{/* Copyright - Only visible on desktop */}
						<NavLink
							to="https://fightfi.xyz"
							target="_blank"
							className={"text-xs md:text-base text-[#ababab] text-right"}
						>
							© FightFi Foundation.
						</NavLink>
					</div>
				</div>

				{/* Copyright - Only visible on mobile */}
				{/* <div className="mt-4 text-right md:hidden">
					<p className={cn("text-xs md:text-base", "text-[#ababab]")}>
						© Concept Labs, Inc.
					</p>
				</div> */}
			</div>
		</footer>
	);
}
