import { useNavigate } from "react-router-dom";
import { useGetAllActiveQuestsQuery } from "@/common/services/quests.api";
import QuestCardsList from "./quest-cards-list";
import QuestCard from "./quest-card";
import QuestCardSkeleton from "./quest-skeleton";
import { Quest } from "@/common/types/entities/quest/quest";
import { useUserStore } from "@/common/hooks/use-user-store";
import { QUEST_IDS } from "@/common/constants/quest-ids";
import { useQuestsStore } from "@/common/hooks/use-quests-store";
import { useEffect } from "react";

export function QuestCardsListWrapper() {
	const navigate = useNavigate();
	const { data, isLoading } = useGetAllActiveQuestsQuery();
	const campMember = useUserStore((state) => state.user.campMember);
	const setEnabledQuests = useQuestsStore((state) => state.setEnabledQuests);

	const filterQuests = (quests: Quest[]) => {
		if (!quests) return [];

		return quests.filter((quest) => {
			if (quest.id === QUEST_IDS.CAMP_FORGE && !campMember) {
				return false;
			}
			if (quest.isPromoBanner) {
				return false;
			}
			return true;
		});
	};

	useEffect(() => {
		if (data?.data) {
			setEnabledQuests(data.data);
		}
	}, [data?.data, setEnabledQuests]);

	const handleQuestCardClick = (route: string) => {
		navigate(route);
	};

	return (
		<QuestCardsList>
			{data?.data &&
				filterQuests(data.data).map((quest: Quest, index: number) => (
					<QuestCard
						key={index}
						id={quest.id}
						title={quest.title}
						description={quest.description}
						createdAt={quest.createdAt}
						limitedTime={quest.limitedTime}
						dueDate={quest.dueDate}
						onClick={() => handleQuestCardClick(quest.ctaLink)}
					/>
				))}

			{isLoading && <QuestCardSkeleton />}
		</QuestCardsList>
	);
}
