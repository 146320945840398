import Typography from "@/common/components/design/texts/typography";
import { useWalletStore } from "@/common/hooks/use-wallet-store";
import { cn } from "@/common/lib/utils";
import { useGetClaimedPacksHistoryQuery } from "@/common/services/lightsout.api";
import { Loader2 } from "lucide-react";

const TIERS = [
	{ category: "Base", price: "100", enabled: true },
	{ category: "Elite", price: "1000", enabled: true },
] as const;

export function ClaimDetailsHistory() {
	const isWalletConnected = useWalletStore((store) => store.wallet.loggedIn);
	const { data, isLoading } = useGetClaimedPacksHistoryQuery();

	const calculateAmounts = (basePacks?: number, elitePacks?: number) => {
		const counts = new Map<string, number>();

		// Initialize all tiers with 0
		TIERS.forEach((tier) => {
			counts.set(tier.category, 0);
		});

		// Count from base packs
		counts.set("Base", basePacks || 0);

		// Count from elite packs
		counts.set("Elite", elitePacks || 0);

		return counts;
	};

	const tierAmounts = calculateAmounts(
		data?.data.records.base,
		data?.data.records.elite
	);

	return (
		<div className="flex flex-col gap-4 md:min-w-96">
			<Typography
				variant="subtitle"
				className={cn(
					"text-2xl font-semibold uppercase",
					!isWalletConnected && "mt-12 md:mt-0"
				)}
			>
				{!isWalletConnected ? "Claim Values" : "Previously Claimed"}
			</Typography>
			<div className="w-full max-w-2xl mx-auto">
				<div className="space-y-2">
					{TIERS.map((tier) => (
						<div
							key={tier.category}
							className={cn("grid items-center font-medium grid-cols-4 ")}
						>
							<div
								className={cn(
									isWalletConnected || tier.enabled
										? "col-span-1"
										: "col-span-1",
									"text-left"
								)}
							>
								{tier.category}
							</div>
							<div
								className={cn(
									"col-span-3 text-right",
									isWalletConnected && tier.enabled
										? "col-span-2"
										: "col-span-3"
								)}
							>
								{tier.enabled ? (
									<>
										{tier.price}
										<span className="text-xs">FF</span>
									</>
								) : (
									<span>Coming Soon</span>
								)}
							</div>
							{isWalletConnected && tier.enabled && (
								<div className="text-right flex flex-row gap-2 justify-end items-center">
									x
									{isLoading ? (
										<Loader2 className="animate-spin w-3 h-3" />
									) : (
										tierAmounts.get(tier.category)
									)}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default ClaimDetailsHistory;
