// src/components/Typography.tsx
import { cn } from "@/common/lib/utils";
import React from "react";

interface TypographyProps {
	variant: "title" | "title-small" | "subtitle" | "body" | "caption";
	children: React.ReactNode;
	className?: string;
}

const Typography: React.FC<TypographyProps> = ({
	variant,
	children,
	className = "",
}) => {
	let baseClasses = "";

	// Determine styles based on the variant
	switch (variant) {
		case "title":
			baseClasses += " text-2xl font-semibold uppercase";
			break;
		case "title-small":
			baseClasses += " text-base font-normal";
			break;
		case "subtitle":
			baseClasses += " text-[1.75rem] leading-[1.875rem] font-medium";
			break;
		case "body":
			baseClasses += " text-base text-white/70";
			break;
		case "caption":
			baseClasses += " text-lg text-primary/60";
			break;
		default:
			break;
	}

	// Use semantic HTML elements based on variant
	switch (variant) {
		case "title":
			return <h1 className={cn(baseClasses, className)}>{children}</h1>;
		case "subtitle":
			return <h2 className={cn(baseClasses, className)}>{children}</h2>;
		default:
			return <p className={cn(baseClasses, className)}>{children}</p>;
	}
};

export default Typography;
