import Typography from "@/common/components/design/texts/typography";
import { Check } from "lucide-react";

interface OAuthScopeListProps {
	scopes: string[];
}

const SCOPE_DESCRIPTIONS: Record<string, string> = {
	profile: "Access your basic profile information",
	email: "Access your email address",
	wallet: "Access your connected wallet information",
	nft: "Access your NFT collection information",
	quests: "Access your quest progress and history",
	achievements: "Access your achievements and rewards",
};

export default function OAuthScopeList({ scopes }: OAuthScopeListProps) {
	return (
		<div className="w-full ">
			<Typography variant="subtitle" className="mb-4">
				Requested Permissions
			</Typography>
			<div className="space-y-2 text-left">
				{scopes.map((scope) => (
					<div key={scope} className="flex items-start gap-2">
						<Check className="w-5 h-5 text-primary mt-0.5" />
						<div>
							<Typography variant="body" className="font-medium">
								{scope}
							</Typography>
							<Typography
								variant="body"
								className="text-muted-foreground text-sm"
							>
								{SCOPE_DESCRIPTIONS[scope] || "Access to this feature"}
							</Typography>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
