import Typography from "@/common/components/design/texts/typography";
import FightIdLogo from "@/common/components/logo/fightid";
import ClaimFightIDForm from "@/modules/login/components/form/fightid/claim-fightid-form";

function ClaimFightidView() {
	return (
		<div className="w-full flex flex-col gap-y-8 py-4 h-full justify-end md:justify-start">
			<div className="text-center px-6 space-y-2">
				<Typography variant="subtitle" className="font-bold text-xl">
					WELCOME TO
				</Typography>
				<div className="flex justify-center">
					<FightIdLogo width={340} height={60} />
				</div>
			</div>

			<div className="flex flex-col justify-end md:justify-start items-center pb-6 mt-14 space-y-4">
				<Typography variant="title" className="">
					Choose your username
				</Typography>
				<Typography variant="body" className="">
					This name cannot be changed after creation
				</Typography>

				<ClaimFightIDForm />
			</div>
		</div>
	);
}

export default ClaimFightidView;
