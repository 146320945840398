import { createApi } from "@reduxjs/toolkit/query/react";
import { GetReferralInfoResponseDto } from "../types/entities/referrals/dto/referrals.dto";
import { interceptor } from "./interceptor/query.interceptor";

// TBD
// Add both referal endpoints and implement on views
export const referralsApi = createApi({
	reducerPath: "referralsApi",
	baseQuery: interceptor,
	endpoints: (builder) => ({
		getReferralInfo: builder.query<GetReferralInfoResponseDto, void>({
			query: () => ({
				url: `referrals/info`,
				method: "GET",
			}),
		}),
		// getQuestById: builder.query<GetQuestByIdResponseDto, string>({
		// 	query: (id) => ({
		// 		url: `referrals/${id}`,
		// 		method: "GET",
		// 	}),
		// }),
	}),
});

export const { useGetReferralInfoQuery } = referralsApi;
