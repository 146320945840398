import NavButton from "@/common/components/design/buttons/nav-button";

export function RedirectForgeButton() {
	return (
		<NavButton
			to="https://fightfi.forge.gg/"
			target="_blank"
			label="CHECK AVAILABLE QUESTS"
			className="uppercase w-full h-16 font-semibold text-xl max-w-[400px] rounded-none"
		/>
	);
}
