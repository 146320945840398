import { useGetCampQuestHistoryQuery } from "@/common/services/forgegg.api";
import Typography from "@/common/components/design/texts/typography";
import { ClaimHistorySkeleton } from "./claim-history-skeleton";

export function ClaimHistory() {
	const { data: claimHistoryPointsData, isLoading } =
		useGetCampQuestHistoryQuery();

	if (isLoading) {
		return <ClaimHistorySkeleton />;
	}

	return (
		<div className="flex flex-col md:flex-row gap-x-8 gap-y-12 md:items-start md:justify-between">
			<div className="flex flex-col gap-4">
				<Typography
					variant="subtitle"
					className="text-2xl font-semibold uppercase"
				>
					Previously Claimed
				</Typography>

				{claimHistoryPointsData &&
				claimHistoryPointsData.data.quests.length > 0 ? (
					<div className="flex flex-col gap-4">
						{claimHistoryPointsData.data.quests.map((record) => (
							<div
								key={record.questId}
								className="flex flex-row justify-between items-center"
							>
								<Typography variant="body">
									{new Date(record.completedAt).toLocaleDateString("en-US", {
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									})}
								</Typography>
								<Typography variant="body">{record.points} $FF</Typography>
							</div>
						))}
					</div>
				) : (
					<Typography variant="body">
						Your claimed rewards will appear here
					</Typography>
				)}
			</div>
		</div>
	);
}
