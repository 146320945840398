import MainButton from "@/common/components/design/buttons/button";
import FormInput from "@/common/components/design/inputs/form/input";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/common/components/ui/form";
import { useDebounce } from "@/common/hooks/use-debounce";
import { useUserStore } from "@/common/hooks/use-user-store";
import {
	useClaimFightIdMutation,
	useLazyCheckFightIdAvailabilityQuery,
} from "@/common/services/user.api";
import { Routes } from "@/common/types/routes";
import logger from "@/common/utils/logger";
import { useValidationChecks } from "@/modules/login/hooks/use-validation-checks";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import * as z from "zod";
import { useLoadingStore } from "../../../hooks/use-loading";
import { ValidationItem } from "./validation-item";
import { useOAuthStore } from "@/modules/login/hooks/use-oauth-store";

const formSchema = z.object({
	fightId: z
		.string()
		.min(5, "Fight ID must be at least 5 characters")
		.max(20, "Fight ID must be at most 20 characters")
		.regex(/^[a-zA-Z0-9]+$/, "Only letters and numbers are allowed"),
});

export default function ClaimFightIDForm() {
	const navigate = useNavigate();

	// states
	const user = useUserStore((store) => store.user);
	const referral = useUserStore((store) => store.referral);
	const [isAvailable, setIsAvailable] = useState<boolean>(false);
	const oauthParams = useOAuthStore((state) => state.params);

	// actions
	const updateValidatingCode = useLoadingStore(
		(store) => store.updateLoadingState
	);
	const updateUsername = useUserStore((store) => store.updateUsername);
	const [checkAvailability, { isLoading: isCheckingAvailability }] =
		useLazyCheckFightIdAvailabilityQuery();
	const [claimFightID, { isLoading: isClaimingLoading }] =
		useClaimFightIdMutation();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			fightId: "",
		},
	});

	const fightId = form.watch("fightId");
	const debouncedFightId = useDebounce(fightId, 500); // 500ms delay
	const validations = useValidationChecks(fightId);

	// Check availability when debouncedFightId changes
	useEffect(() => {
		const checkFightIdAvailability = async () => {
			if (debouncedFightId && debouncedFightId.length >= 5) {
				try {
					const result = await checkAvailability(debouncedFightId);
					setIsAvailable(result.data?.data.available ?? false);

					if (!result) {
						form.setError("fightId", {
							type: "manual",
							message: "Fight ID is already taken",
						});
					} else {
						form.clearErrors("fightId");
					}
				} catch (error) {
					logger.error(error, "checkFightIdAvailability");
				}
			}
		};

		checkFightIdAvailability();
	}, [debouncedFightId, checkAvailability, form]);

	const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
		logger.info(values, "onRegisterSubmit");

		try {
			if (!isAvailable) {
				throw new Error("Fight ID is already taken");
			}

			const claimResult = await claimFightID({
				email: user.email,
				username: values.fightId,
				referralCode: referral.code,
				referralSource: referral.source,
			});

			if (claimResult.error) {
				if (claimResult.error.data.message) {
					throw new Error(claimResult.error.data.message);
				}
				throw new Error("Failed to claim fight ID");
			}

			updateUsername(values.fightId);
			updateValidatingCode("fightIdValidated");

			// If we have OAuth params, redirect to consent screen
			if (oauthParams) {
				const searchParams = new URLSearchParams({
					client_id: oauthParams.clientId,
					redirect_uri: oauthParams.redirectUri,
					...(oauthParams.scope && { scope: oauthParams.scope }),
					...(oauthParams.state && { state: oauthParams.state }),
				});
				navigate(`${Routes.OAuthConsent}?${searchParams.toString()}`);
			} else {
				navigate(Routes.ClaimFightIDSuccess);
			}
		} catch (error) {
			logger.error(error, "onFormSubmit");
			if (error instanceof Error && error.message) {
				toast.error(error.message);
			}
			return;
		}
	};

	return (
		<div className="flex flex-col items-center justify-center w-full">
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onFormSubmit)}
					className="space-y-2 mt-2 w-full max-w-[400px]"
				>
					<FormField
						control={form.control}
						name="fightId"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<div className="relative w-full">
										<FormInput
											className="w-full font-light text-gray-600 placeholder:text-gray-500 max-w-[400px] mx-auto"
											placeholder="| Enter your name"
											{...field}
										/>
										<span className="absolute right-3 top-1/2 -translate-y-1/2 font-medium text-black">
											.fight
										</span>
									</div>
								</FormControl>
								<FormMessage />
								<div className="text-sm text-muted-foreground">
									<ul className="list-disc list-inside text-left text-primary p-2 ">
										{Object.entries(validations).map(([key, check]) => (
											<ValidationItem
												key={key}
												isValid={check.valid}
												message={check.message}
											/>
										))}
										{debouncedFightId && debouncedFightId.length >= 5 && (
											<ValidationItem
												isValid={isAvailable ?? false}
												message="Fight ID availability"
											/>
										)}
									</ul>
								</div>
							</FormItem>
						)}
					/>

					<MainButton
						type="submit"
						className="mt-5"
						isLoading={isCheckingAvailability || isClaimingLoading}
						isLoadingLabel={
							isCheckingAvailability ? "Checking availability..." : "Claiming"
						}
						disabled={!form.formState.isValid || !isAvailable}
					>
						Create Fight ID
					</MainButton>

					<NavLink
						to={Routes.Signup}
						className="inline-flex items-center cursor-pointer hover:text-muted-foreground transition-colors p-1 gap-2"
					>
						<ArrowLeft className="h-4 w-4" />
						Back
					</NavLink>
				</form>
			</Form>
		</div>
	);
}
