import { createApi } from "@reduxjs/toolkit/query/react";
import {
	CampDetailsResponseDto,
	CampsLeaderboardsResponseDto,
} from "../types/entities/leaderboards/dto/leaderboards.dto";
import { interceptor } from "./interceptor/query.interceptor";

export const campsApi = createApi({
	reducerPath: "campsApi",
	baseQuery: interceptor,
	tagTypes: ["CampsLeaderboard", "CampDetails", "User"],
	endpoints: (builder) => ({
		getCampsLeaderboard: builder.query<CampsLeaderboardsResponseDto, void>({
			query: () => ({
				url: `camps`,
				method: "GET",
			}),
			providesTags: ["CampsLeaderboard"],
		}),
		getCampDetails: builder.query<CampDetailsResponseDto, string | undefined>({
			query: (campId) => ({
				url: `camps/${campId}`,
				method: "GET",
			}),
			providesTags: ["CampDetails"],
		}),
		joinCamp: builder.mutation<void, string>({
			query: (campId) => ({
				url: `camps/${campId}/join`,
				method: "POST",
			}),
			invalidatesTags: ["CampsLeaderboard", "CampDetails", "User"],
		}),
	}),
});

export const {
	useGetCampsLeaderboardQuery,
	useGetCampDetailsQuery,
	useJoinCampMutation,
} = campsApi;
