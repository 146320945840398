import Typography from "@/common/components/design/texts/typography";
import { useUserStore } from "@/common/hooks/use-user-store";

export function ClaimInstructions() {
  const userEmail = useUserStore((store) => store.user.email);

  return (
    <div className="flex flex-col gap-y-12 gap-4 ">
      <div className="flex flex-col gap-2 text-left text-lg">
        <Typography variant="body">
          <span className="text-xl">Step 1:</span> Create an account on
          Forge using {userEmail} as email.
        </Typography>
        <Typography variant="body">
          <span className="text-xl">Step 2:</span> Complete the quest on
          Forge (e.g., share on socials).
        </Typography>
        <Typography variant="body">
          <span className="text-xl">Step 3:</span> Return here to claim
          your rewards!
        </Typography>
      </div>
    </div>
  );
}