import React from "react";

interface GoChevronIconProps {
	className?: string;
}

const GoChevronIcon: React.FC<GoChevronIconProps> = ({ className }) => (
	<svg
		className={`fill-current ${className}`}
		width="28"
		height="28"
		viewBox="0 0 28 28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2941_24505)">
			<path
				d="M14 28C6.26817 28 0 21.7318 0 14C0 6.26817 6.26817 0 14 0C21.7318 0 28 6.26817 28 14C28 21.7318 21.7318 28 14 28ZM14 2.33335C7.55683 2.33335 2.33335 7.55683 2.33335 14C2.33335 20.4432 7.55683 25.6666 14 25.6666C20.4432 25.6666 25.6666 20.4432 25.6666 14C25.6666 7.55683 20.4432 2.33335 14 2.33335Z"
			/>
			<path
				d="M12.4916 20.6583C12.036 21.1139 11.2973 21.1139 10.8417 20.6583C10.3861 20.2027 10.3861 19.464 10.8417 19.0084L15.8501 14L10.8417 8.9916C10.3861 8.53599 10.3861 7.79727 10.8417 7.34167C11.2973 6.88607 12.036 6.88607 12.4916 7.34167L18.325 13.175C18.7806 13.6306 18.7806 14.3693 18.325 14.8249L12.4916 20.6583Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2941_24505">
				<rect
					width="28"
					height="28"
					transform="matrix(1 0 0 -1 0 28)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default GoChevronIcon;
