import { useLocation } from "react-router-dom";
import { Routes } from "@/common/types/routes";
import { useIsMobile } from "@/common/hooks/use-mobile";

// Import background images
import LoginBGMobile from "@/assets/backgrounds/login/login-mobile-bg.png";
import LoginBGDesktop from "@/assets/backgrounds/login/login-desktop-bg.png";

import LandingBGMobile from "@/assets/backgrounds/landing/landing-mobile-bg.png";
import LandingBGDesktop from "@/assets/backgrounds/landing/landing-desktop-bg.png";

interface BackgroundConfig {
	mobile: string | null;
	desktop: string | null;
	routes: Routes[];
}

const BACKGROUND_IMAGES: BackgroundConfig[] = [
	{
		routes: [Routes.Landing],
		mobile: LandingBGMobile,
		desktop: LandingBGDesktop,
	},
	{
		routes: [Routes.Signup],
		mobile: LoginBGMobile,
		desktop: LoginBGDesktop,
	},
	{
		routes: [Routes.ClaimFightID],
		mobile: LoginBGMobile,
		desktop: LoginBGDesktop,
	},
	{
		routes: [Routes.AuthLoading],
		mobile: LoginBGMobile,
		desktop: LoginBGDesktop,
	},
	{
		routes: [Routes.ClaimFightIDSuccess],
		mobile: LoginBGMobile,
		desktop: LoginBGDesktop,
	},
	{
		routes: [], // Empty array means this is the default
		mobile: LoginBGMobile,
		desktop: LoginBGDesktop,
	},
];

export const PageBackground = () => {
	const location = useLocation();
	const isMobile = useIsMobile();

	const getBackgroundImage = () => {
		const pathname = location.pathname.split("?")[0];

		// Find matching route or use the last config (default)
		const config =
			BACKGROUND_IMAGES.find(
				(bg) => bg.routes.length > 0 && bg.routes.includes(pathname as Routes)
			) || BACKGROUND_IMAGES[BACKGROUND_IMAGES.length - 1];

		return isMobile ? config.mobile : config.desktop;
	};

	const backgroundImage = getBackgroundImage();
	if (!backgroundImage) return null;

	return (
		<div className="fixed top-0 left-0 w-full z-0 h-full">
			<img
				src={backgroundImage}
				alt="background"
				className="w-full h-full object-cover object-center"
			/>
			<div className="absolute inset-0 bg-gradient-to-b to-black/30" />
		</div>
	);
};
