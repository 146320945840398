import { Skeleton } from "@/common/components/ui/skeleton";
import BaseCard from "@/common/components/design/card/base-card";

export function CampCardSkeleton() {
	return (
		<BaseCard className="p-4">
			<div className="flex flex-col gap-2">
				<Skeleton className="h-5 w-40" />
				<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-2 gap-2">
					<Skeleton className="h-8 w-64 min-w-0 max-w-full" />
					<div className="flex gap-4 overflow-x-auto pb-1 w-full sm:w-auto">
						<div className="min-w-[80px]">
							<Skeleton className="h-4 w-20 mb-1" />
							<Skeleton className="h-6 w-16" />
						</div>
						<div className="min-w-[100px]">
							<Skeleton className="h-4 w-28 mb-1" />
							<Skeleton className="h-6 w-24" />
						</div>
					</div>
				</div>
			</div>
		</BaseCard>
	);
}
