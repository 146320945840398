import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/common/components/ui/tabs";
import { Routes } from "@/common/types/routes";
import { AccountKPI } from "../../components/account/accounts";
import { ReferralsKPI } from "../../components/referrals/referrals";

function KPIsView() {
	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-6 p-4 min-h-full">
				<div className="flex flex-row gap-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">KPIs</Typography>
						<Typography variant="body">
							Here you can see the KPIs for the platform.
						</Typography>
					</div>
				</div>

				<Tabs defaultValue="account-stats" className="space-y-4">
					<TabsList>
						<TabsTrigger value="account-stats">Account Stats</TabsTrigger>
						<TabsTrigger value="referrals-stats">Referrals</TabsTrigger>
						<TabsTrigger value="insights" disabled>
							NFT Claims (moments)
						</TabsTrigger>
						<TabsTrigger value="user-feedback" disabled>
							Lights Outs II
						</TabsTrigger>
					</TabsList>
					<TabsContent value="account-stats">
						<AccountKPI />
					</TabsContent>
					<TabsContent value="referrals-stats">
						<ReferralsKPI />
					</TabsContent>
					<TabsContent value="insights">c</TabsContent>
					<TabsContent value="user-feedback">d</TabsContent>
				</Tabs>
			</BaseCard>
		</div>
	);
}

export default KPIsView;
