import * as React from "react";

import { Button } from "@/common/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTrigger,
} from "@/common/components/ui/dialog";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTrigger,
} from "@/common/components/ui/drawer";
import { useMediaQuery } from "@/common/hooks/use-media-query";
import { OldQuest } from "@/common/types/entities/quest/quest";
import Typography from "@/common/components/design/texts/typography";

type QuestDialogProps = {
	children: React.ReactNode;
} & Partial<OldQuest>;

export function DrawerDialogQuest({ children, ...props }: QuestDialogProps) {
	const [open, setOpen] = React.useState(false);
	const isDesktop = useMediaQuery("(min-width: 768px)");

	if (isDesktop) {
		return (
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogTrigger asChild>{children}</DialogTrigger>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogDescription>{props.deadline}</DialogDescription>
					</DialogHeader>
					<Details {...props} />
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Drawer open={open} onOpenChange={setOpen}>
			<DrawerTrigger asChild>{children}</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader className="text-left">
					<DrawerDescription>{props.deadline}</DrawerDescription>
				</DrawerHeader>
				<Details {...props} />
				<DrawerFooter className="pt-2">
					<DrawerClose asChild>
						<Button variant="outline">Close</Button>
					</DrawerClose>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}

function Details(props: Partial<OldQuest>) {
	return (
		<div className="space-y-8 px-2">
			<div className="space-y-6 text-center">
				<Typography variant="title">{props.title}</Typography>

				<Typography variant="body">{props.event?.instructions}</Typography>
				{/* <ol className="space-y-4 text-left">
					<li className="flex gap-2">
						<span className="font-mono">1.</span>
						Log into UFC Strike now
					</li>
					<li className="flex gap-2">
						<span className="font-mono">2.</span>
						Connect your fight id in your profile
					</li>
					<li className="flex gap-2">
						<span className="font-mono">3.</span>
						Pick the card&apos;s winner
					</li>
					<li className="flex gap-2">
						<span className="font-mono">4.</span>
						Come back here to claim your reward
					</li>
				</ol> */}

				<Button className="w-full bg-black font-mono text-white hover:bg-black/90">
					{props.event?.cta?.label}
				</Button>
			</div>
		</div>
	);
}
