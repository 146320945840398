import { cn } from "@/common/lib/utils";
import { Button } from "@/common/components/ui/button";
import { NavLink } from "react-router-dom";
import { Routes } from "@/common/types/routes";
import { HTMLAttributeAnchorTarget } from "react";

interface NavButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	label: string;
	isLoading?: boolean;
	isLoadingLabel?: string;
	className?: string;
	type?: "button" | "submit" | "reset";
	to: Routes | string;
	target?: HTMLAttributeAnchorTarget | undefined;
}

function NavButton({
	label,
	isLoading = false,
	isLoadingLabel = "Loading...",
	className,
	type = "button",
	disabled,
	to,
	target = "_self",
	...props
}: NavButtonProps) {
	return (
		<Button
			asChild
			size="sm"
			type={type}
			className={cn("uppercase text-xs", className)}
			disabled={disabled || isLoading}
			{...props}
		>
			<NavLink to={to} target={target}>
				{isLoading ? isLoadingLabel : label}
			</NavLink>
		</Button>
	);
}

export default NavButton;
