
			if (
				import.meta.env.VITE_APP_ENVIRONMENT === "prod" &&
				import.meta.env.VITE_APP_GA_MEASUREMENT_ID
			) {
				const script = document.createElement("script");
				script.src = `https://www.googletagmanager.com/gtag/js?id=${
					import.meta.env.VITE_APP_GA_MEASUREMENT_ID
				}`;
				script.async = true;
				document.head.appendChild(script);

				window.dataLayer = window.dataLayer || [];
				function gtag() {
					dataLayer.push(arguments);
				}
				gtag("js", new Date());
				gtag("config", import.meta.env.VITE_APP_GA_MEASUREMENT_ID);
			}
		