import React from "react";

interface CampsGlovesIconProps {
	className?: string;
}
const CampsGlovesIcon: React.FC<CampsGlovesIconProps> = ({ className }) => (
	<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
			<path
				d="M5.23438 21.1406V23.2969C5.23438 23.6852 5.54919 24 5.93751 24H15.3125C15.7008 24 16.0156 23.6852 16.0156 23.2969V21.1406H5.23438Z"
				fill="currentColor"
			/>
			<path
				d="M19.0625 0H13.4375C12.0141 0 10.7198 0.551953 9.75632 1.45312H13.4375C17.1853 1.45312 20.2344 4.5022 20.2344 8.25V8.95312C20.2344 9.17564 20.2192 9.39464 20.1905 9.60938H20.9375C22.8791 9.60938 24.4531 8.03536 24.4531 6.09375V5.39062C24.4531 2.41345 22.0397 0 19.0625 0Z"
				fill="currentColor"
			/>
			<path
				d="M9.45285 8.48438C11.2621 8.48438 12.7341 9.9563 12.7341 11.7656C12.7341 12.007 12.7071 12.2421 12.6575 12.4688H15.3125C17.2541 12.4688 18.8281 10.8947 18.8281 8.95312V8.25C18.8281 5.27283 16.4147 2.85938 13.4375 2.85938H7.81251C4.83533 2.85938 2.42188 5.27283 2.42188 8.25V8.94919C3.09838 8.65059 3.8459 8.48438 4.63162 8.48438H9.45285Z"
				fill="currentColor"
			/>
			<path
				d="M10.625 17.3906C9.8496 17.3906 9.21876 18.0215 9.21876 18.7969V19.7344H12.0313V18.7969C12.0313 18.0215 11.4004 17.3906 10.625 17.3906Z"
				fill="currentColor"
			/>
			<path
				d="M20.9375 11.0156H20.2344V12.8344C20.2344 14.2117 20.0287 15.5723 19.6292 16.875H22.3523C23.7147 14.8435 24.4531 12.4414 24.4531 9.975V9.53456C23.5591 10.4478 22.3135 11.0156 20.9375 11.0156Z"
				fill="currentColor"
			/>
			<path
				d="M19.1141 18.2812C18.7798 19.0574 18.3726 19.8057 17.8953 20.5176L17.4775 21.1406H20.9375C21.3258 21.1406 21.6406 20.8258 21.6406 20.4375V18.2812H19.1141Z"
				fill="currentColor"
			/>
			<path
				d="M15.3125 13.875H11.963C11.383 14.564 10.5255 15.0118 9.56404 15.0441C9.45121 15.055 9.33604 15.0587 9.2188 15.0549L5.70327 15.0482C5.3151 15.0474 5.0008 14.7326 5.0008 14.3444C5.0008 13.9557 5.3159 13.6406 5.70458 13.6406H9.4528C10.4883 13.6406 11.3278 12.8011 11.3278 11.7656C11.3278 10.7301 10.4883 9.89062 9.4528 9.89062H4.68538C2.53527 9.89062 0.712304 11.533 0.557616 13.6776C0.476475 14.8022 0.857757 15.8967 1.60963 16.7235L4.13849 19.5043C4.27176 19.6508 4.46062 19.7343 4.65866 19.7343H7.81251V18.7968C7.81251 17.246 9.07419 15.9843 10.625 15.9843C12.1758 15.9843 13.4375 17.246 13.4375 18.7968V19.7343H16.7273C18.0897 17.7029 18.8281 15.3008 18.8281 12.8344V12.3939C17.9341 13.3072 16.6885 13.875 15.3125 13.875Z"
			fill="currentColor"
		/>
	</svg>
);

export default CampsGlovesIcon;
