import { Routes } from "@/common/types/routes";
import Layout from "@/common/components/auth-layout";
import { RouteObject } from "react-router-dom";
import LightsOutView from "./views/connection/connection";

const lightsOutRoutes: RouteObject[] = [
	{
		path: Routes.QuestLightOut,
		element: (
			<Layout>
				<LightsOutView />
			</Layout>
		),
	},
];

export default lightsOutRoutes;
