import { NavLink } from "react-router-dom";
import FightIdLogo from "../../logo/fightid";
import { Routes } from "@/common/types/routes";

function Header() {
	return (
		<header className="sticky top-0 z-30 flex h-24 items-center justify-center gap-4 px-4 sm:static sm:px-6">
			<NavLink to={Routes.Signup}>
				<FightIdLogo width={130} height={22} />
			</NavLink>

			{/* <div className="relative ml-auto flex-1 md:grow-0"></div> */}
		</header>
	);
}

export default Header;
