import React from "react";

interface FightIdLogoProps {
	width?: number;
	height?: number;
}

const FightIdLogo: React.FC<FightIdLogoProps> = ({
	width = 129,
	height = 23,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 129 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M98.9732 5.37309L100.099 0H108.432L107.306 5.37309H98.9732Z"
				fill="#FF4001"
			/>
			<path
				d="M96.0132 21.8784L98.0401 12.2262H95.9166L97.7506 6.62788H107.017L103.799 21.8784H96.0132Z"
				fill="#FF4001"
			/>
			<path
				d="M30.399 17.765H48.9747L50.7799 9.29834H40.9024L39.905 13.9958H43.3477L43.0581 15.2506C42.4361 15.4007 41.7819 15.4758 41.0955 15.4758C39.8943 15.4758 39.0256 15.3042 38.4894 14.961C37.9531 14.5964 37.685 13.985 37.685 13.1271C37.685 12.5694 37.7601 11.9795 37.9102 11.3575C38.2534 9.79168 38.972 8.71921 40.0659 8.14007C41.1598 7.53949 42.4575 7.23919 43.959 7.23919C45.0315 7.23919 46.061 7.31427 47.0477 7.46441C48.0344 7.59311 48.9889 7.8505 49.9112 8.23659L51.2625 1.7374C50.3617 1.39421 49.3964 1.13682 48.3669 0.965224C47.3587 0.772179 46.1897 0.675657 44.8599 0.675657C40.5485 0.675657 37.1702 1.57653 34.725 3.37829C32.3012 5.18004 30.714 7.83978 29.9632 11.3575C29.856 11.8508 29.7702 12.3334 29.7058 12.8053C29.6629 13.2772 29.6415 13.7277 29.6415 14.1566C29.6415 15.5389 29.894 16.7417 30.399 17.765Z"
				fill="white"
			/>
			<path
				d="M26.9539 17.765L30.4948 0.997398H22.7087L19.1677 17.765H26.9539Z"
				fill="white"
			/>
			<path
				d="M26.4645 20.0822H18.6784L18.333 21.7176H26.1192L26.4645 20.0822Z"
				fill="white"
			/>
			<path
				d="M8.62096 17.765L9.36269 14.2532H16.9236L18.1462 8.46181H10.5853L10.9714 6.59571H19.755L20.9454 0.997398H4.37569L0.834703 17.765H8.62096Z"
				fill="white"
			/>
			<path
				d="M0.345357 20.0822H8.13156L7.78616 21.7176H0L0.345357 20.0822Z"
				fill="white"
			/>
			<path
				d="M50.9334 17.765L54.4744 0.997398H62.2606L60.8449 7.75398H66.025L67.4406 0.997398H75.2268L71.6858 17.765H63.8931L64.4806 14.961H59.3006L58.713 17.765H50.9334Z"
				fill="white"
			/>
			<path
				d="M48.3649 20.0822H32.3634C34.1608 21.387 36.621 22.0393 39.7442 22.0393C41.6532 22.0393 43.337 21.8356 44.7955 21.428C46.2109 21.0383 47.4007 20.5897 48.3649 20.0822Z"
				fill="white"
			/>
			<path
				d="M50.4441 20.0822H58.2275L57.8849 21.7176H50.0987L50.4441 20.0822Z"
				fill="white"
			/>
			<path
				d="M63.4076 20.0822L63.0649 21.7176H70.8511L71.1964 20.0822H63.4076Z"
				fill="white"
			/>
			<path
				d="M78.1072 20.0822L77.7627 21.7176H85.5489L85.8934 20.0822H78.1072Z"
				fill="white"
			/>
			<path
				d="M86.3815 17.765L88.7342 6.59571H94.0107L95.2012 0.997398H76.8619L75.6714 6.59571H80.948L78.5953 17.765H86.3815Z"
				fill="white"
			/>
			<path
				d="M88.9051 16.2801L87.779 21.7176H93.7634L94.8895 16.2801H88.9051Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M110.307 1.15827L105.931 21.8784H115.776C118.372 21.8784 120.517 21.5245 122.211 20.8167C123.927 20.1089 125.3 19.0042 126.33 17.5028C127.359 15.9798 128.142 14.0279 128.678 11.6471C128.786 11.1752 128.861 10.7247 128.903 10.2957C128.968 9.86675 129 9.44849 129 9.04095C129 7.36789 128.614 5.95222 127.842 4.79395C127.091 3.61423 126.051 2.71336 124.721 2.09132C123.391 1.46929 121.868 1.15827 120.152 1.15827H110.307ZM116.13 15.9584H114.972L116.742 7.52876H117.9C118.972 7.52876 119.755 7.65746 120.249 7.91485C120.763 8.15079 121.021 8.70848 121.021 9.58791C121.021 9.86675 120.999 10.1778 120.956 10.521C120.935 10.8427 120.871 11.2181 120.763 11.6471C120.506 12.8482 120.174 13.7598 119.766 14.3819C119.38 15.0039 118.887 15.4222 118.286 15.6367C117.707 15.8512 116.988 15.9584 116.13 15.9584Z"
				fill="#FF4001"
			/>
		</svg>
	);
};

export default FightIdLogo;
