import PWABadge from "@/PWABadge.tsx";
import router from "@/router.tsx";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { toast, Toaster } from "sonner";
import { useRegisterSW } from "virtual:pwa-register/react";
import { ThemeProvider } from "./common/components/theme-provider";
import "./push-event-handler";

function App() {
	useRegisterSW({
		immediate: false,
		onNeedRefresh() {
			toast.info("New content is available; please refresh.");
		},
		onRegistered(r) {
			if (r) {
				setInterval(() => {
					r.update();
				}, 60 * 60 * 1000);
			}
		},
	});

	return (
		<HelmetProvider>
			<ThemeProvider defaultTheme="dark" storageKey="ui-theme">
				<RouterProvider router={router} />
				<PWABadge />
				<Toaster richColors />
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
