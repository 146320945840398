import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { Routes } from "@/common/types/routes";
import NotificationPreferencesForm from "../../components/forms/notification-preferences-form";

function SettingsView() {
	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit">
				<div className="flex flex-row gap-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">SETTINGS</Typography>
						<Typography variant="body">
							Enable notifications. Earn up to{" "}
							<span className="text-[#E92903]">30$FF/mo</span>
						</Typography>
					</div>
				</div>

				<div className="flex flex-col md:flex-row gap-y-8 md:justify-evenly md:items-start">
					{/* PWA Installation Instructions - Will be ordered second on mobile */}
					<div className="flex flex-col gap-4 w-full max-w-96 order-2 md:order-1">
						<div className="flex flex-row gap-6 items-center">
							<img
								src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/fightif-icon-UkUywpQrGZksdQHD4E5FDkwuKuZssC.png"
								alt="Fight.id logo"
								className="w-12 h-12"
							/>
							<Typography
								variant="subtitle"
								className="font-medium text-2xl italic max-w-48 md:max-w-full"
							>
								ADD APP TO HOME SCREEN
							</Typography>
						</div>

						<div className="flex flex-col gap-4">
							<div className="flex flex-col gap-2">
								<Typography variant="body" className="font-semibold">
									1. Tap the Share button
								</Typography>
								<img
									src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/chrome-install-XuuRD9VriK8BINvYC0vxytojV2C0wv.png"
									alt="Chrome install"
                  className="block md:hidden"
								/>
								<img
									src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/safari-install-S0iF1TYGnEXHqnORB87OOP6gl3495h.png"
									alt="Safari install"
                  className="block md:hidden"
								/>
								<img
									src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/chrome-desktop-install-OCau5ga4pi7AfK0cSqtmVi7r3zf0dd.png"
									alt="Safari install"
                  className="hidden md:block"
								/>

							</div>

							<div className="flex flex-col gap-2">
								<Typography variant="body" className="font-semibold">
									2. Select "Add to Home Screen"
								</Typography>
								<img
									src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/add-to-home-3r6gmequmszCo5D7G3AwnK1nd437C9.png"
									alt="Add to home screen option"
                  className="block md:hidden"
								/>
								<img
									src="https://hhr2x0it6lgt6k2a.public.blob.vercel-storage.com/assets/settings/chrome-desktop-install-button-k8GWE0PzCPCxEnEdqokY9G9EQBD4IG.png"
									alt="Add to home screen option"
                  className="hidden md:block"
								/>
							</div>
						</div>
					</div>

					{/* Settings Form - Will be ordered first on mobile */}
					<div className="flex flex-col gap-6 w-full max-w-96 order-1 md:order-2">
						<NotificationPreferencesForm />

            {/* divider here */}
            <div className="w-full h-[1px] bg-neutral-800 block md:hidden" />
					</div>
				</div>
			</BaseCard>
		</div>
	);
}

export default SettingsView;
