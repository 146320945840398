import { createApi } from "@reduxjs/toolkit/query/react";
import { interceptor } from "./interceptor/query.interceptor";
import {
	GetPacksToClaimRequestDto,
	GetPacksToClaimResponseDto,
} from "../types/entities/lightsout/dto/get-packs.dto";
import {
	ClaimPacksRequestDto,
	ClaimPacksResponseDto,
} from "../types/entities/lightsout/dto/claim-packs.dto";
import { GetPacksClaimedHistoryResponseDto } from "../types/entities/lightsout/dto/get-claimed-packs-history.dto";

export const lightsoutApi = createApi({
	reducerPath: "lightsoutApi",
	baseQuery: interceptor,
	tagTypes: ["ClaimablePacks", "ClaimedPacksHistory"],
	endpoints: (builder) => ({
		claimPacks: builder.mutation<ClaimPacksResponseDto, ClaimPacksRequestDto>({
			query: (body) => ({
				url: "gigantik/packs/claim",
				method: "POST",
				body,
			}),
			invalidatesTags: ["ClaimablePacks", "ClaimedPacksHistory"],
		}),
		getPacksToClaim: builder.query<
			GetPacksToClaimResponseDto,
			GetPacksToClaimRequestDto
		>({
			query: (request) => ({
				url: `gigantik/packs?wallet=${request.wallet}`,
				method: "GET",
			}),
			providesTags: ["ClaimablePacks"],
		}),
		getClaimedPacksHistory: builder.query<
			GetPacksClaimedHistoryResponseDto,
			void
		>({
			query: () => ({
				url: `gigantik/packs/claim-history`,
				method: "GET",
			}),
			providesTags: ["ClaimedPacksHistory"],
		}),
	}),
});

export const {
	useClaimPacksMutation,
	useGetPacksToClaimQuery,
	useLazyGetPacksToClaimQuery,
	useGetClaimedPacksHistoryQuery,
} = lightsoutApi;
