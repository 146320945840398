import { cn } from "@/common/lib/utils";
import { Loader2 } from "lucide-react";

type NFTData = {
	nft_id: number;
	tier: string;
	points: number;
};

const TIERS = [
	{ category: "Common", price: "5", enabled: true },
	{ category: "Uncommon", price: "100", enabled: false },
	{ category: "Rare", price: "1,000", enabled: false },
	{ category: "Fandom", price: "0", enabled: false },
	{ category: "Epic", price: "5,000", enabled: false },
	{ category: "Champion", price: "10,000", enabled: false },
	{ category: "Ultimate", price: "1,000,000", enabled: false },
] as const;

interface ClaimDetailsProps {
	initialNFTs?: NFTData[];
	updatedNFTs?: NFTData[];
	showAmount?: boolean;
	isLoading?: boolean;
}

export function ClaimDetailsHistory({
	initialNFTs = [],
	updatedNFTs,
	showAmount = true,
	isLoading = false,
}: ClaimDetailsProps) {
	const calculateAmounts = (data: NFTData[]) => {
		const counts = new Map<string, number>();

		// Initialize all tiers with 0
		TIERS.forEach((tier) => {
			counts.set(tier.category, 0);
		});

		// Count occurrences of each tier
		data.forEach((nft) => {
			const currentCount = counts.get(nft.tier) || 0;
			counts.set(nft.tier, currentCount + 1);
		});

		return counts;
	};

	// Use updatedNFTs if provided, otherwise fall back to initialNFTs
	const nftsToDisplay =
		updatedNFTs && updatedNFTs.length > 0 ? updatedNFTs : initialNFTs;
	const tierAmounts = calculateAmounts(nftsToDisplay);

	return (
		<div className="w-full max-w-2xl mx-auto">
			<div className="space-y-2">
				{TIERS.map((tier) => (
					<div
						key={tier.category}
						className={cn(
							"grid items-center font-medium grid-cols-4 "
						)}
					>
						<div
							className={cn(
								!showAmount || tier.enabled ? "col-span-1" : "col-span-1",
								"text-left"
							)}
						>
							{tier.category}
						</div>
						<div
							className={cn(
								"col-span-3 text-right",
								showAmount && tier.enabled ? "col-span-2" : "col-span-3"
							)}
						>
							{tier.enabled ? (
								<>
									{tier.price}
									<span className="text-xs">FF</span>
								</>
							) : (
								<span>Coming Soon</span>
							)}
						</div>
						{showAmount && tier.enabled && (
							<div className="text-right flex flex-row gap-2 justify-end items-center">
								x
								{isLoading ? (
									<Loader2 className="animate-spin w-3 h-3" />
								) : (
									tierAmounts.get(tier.category)
								)}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default ClaimDetailsHistory;
