export enum Routes {
	Landing = "/",
	Dashboard = "/dashboard",

	// Admin access
	AdminUserManagement = "/admin/users",
	AdminFFManagement = "/admin/ff",
	AdminKPIs = "/admin/kpis",
	AdminQuests = "/admin/quests",

	// Auth flow
	Login = "/login",
	VerifyEmailLogin = "/verify-email-login",
	Signup = "/signup",
	VerifyEmail = "/verify-email",
	AuthLoading = "/auth-user",

	// OAuth routes
	OAuthAuthorize = "/oauth/authorize",
	OAuthConsent = "/oauth/consent",

	// FightID - username claiming
	ClaimConnect = "/claim-connect",
	ClaimWithNFT = "/claim-nft",
	ClaimWithNFTSuccess = "/claim-nft-success",
	ClaimWithNFTError = "/claim-nft-error",
	ClaimWithCode = "/claim-code",
	ClaimWithCodeSuccess = "/claim-code-success",
	ClaimWithCodeError = "/claim-code-error",

	ClaimFightID = "/claim-fight-id",
	ClaimFightIDSuccess = "/claim-fight-id-success",

	// Quests
	QuestsHome = "/quests",

	// Camps
	Camps = "/camps",
	CampsDetails = "/camps/:campId",
	CampChoose = "/camps-choose",

	// Profile
	Profile = "/profile",

	// Home
	Home = "/home",

	// Notifications
	Notifications = "/notifications",

	/*
	 * Quests
	 */
	// Referrals
	QuestReferrals = "/refer-friends",

	// Connect wallet and claim $FIGHT
	QuestWalletConnect = "/wallet-connect",

	// Connect wallet and claim $FIGHT
	QuestLightOut = "/lights-out-ii",

	// Forge GG
	QuestForgegg = "/forge-gg",

	// Forge GG
	QuestTicketsTwitter = "/tickets-promo",

	// Quest Camps
	QuestCamp = "/quest-camp",
	/*
	 * End Quests
	 */

	/*
	 * Generals
	 */
	NotFound = "/404",
}
