import { useLocation } from "react-router-dom";
import { Routes } from "@/common/types/routes";
import { useIsMobile } from "@/common/hooks/use-mobile";

// Import background images
import GeneralBGMobile from "@/assets/backgrounds/shared/general-mobile-bg.png";
import GeneralBGDesktop from "@/assets/backgrounds/shared/general-desktop-bg.png";

interface BackgroundConfig {
	mobile: string | null;
	desktop: string | null;
	routes: Routes[];
}

const BACKGROUND_IMAGES: BackgroundConfig[] = [
	{
		routes: [Routes.Home, Routes.Dashboard],
		mobile: GeneralBGMobile,
		desktop: GeneralBGDesktop,
	},
	{
		routes: [Routes.QuestReferrals],
		mobile: GeneralBGMobile,
		desktop: GeneralBGDesktop,
	},
	{
		routes: [Routes.QuestWalletConnect],
		mobile: GeneralBGMobile,
		desktop: GeneralBGDesktop,
	},
	{
		routes: [Routes.QuestForgegg],
		mobile: GeneralBGMobile,
		desktop: GeneralBGDesktop,
	},
	{
		routes: [], // Empty array means this is the default
		mobile: GeneralBGMobile,
		desktop: GeneralBGDesktop,
	},
];

export const PageBackground = () => {
	const location = useLocation();
	const isMobile = useIsMobile();

	const getBackgroundImage = () => {
		const pathname = location.pathname.split("?")[0];

		// Find matching route or use the last config (default)
		const config =
			BACKGROUND_IMAGES.find(
				(bg) => bg.routes.length > 0 && bg.routes.includes(pathname as Routes)
			) || BACKGROUND_IMAGES[BACKGROUND_IMAGES.length - 1];

		return isMobile ? config.mobile : config.desktop;
	};

	const backgroundImage = getBackgroundImage();
	if (!backgroundImage) return null;

	return (
		<div className="fixed top-0 left-0 w-full h-full z-0">
			<img
				src={backgroundImage}
				alt="background"
				className="w-full h-full object-cover object-center bg-black/10"
			/>
			<div className="absolute inset-0 bg-gradient-to-b to-black/30 bg-black/10" />
		</div>
	);
};
