import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { User } from "@auth0/auth0-react";
import { SiwaCallback } from "../types/entities/auth/dto/login.dto";
import { CampMember, NotificationSettings } from "../types/entities/user/dto/user.dto";

type State = {
	user: {
		id: string;
		email: string;
		username: string;
		verified: boolean;
		notification: string;
		isAdmin: boolean;
		token: string;
		settings: NotificationSettings;
		campMember?: CampMember;
	};
	balance: {
		ffBalance: number;
		fightBalance: string;
	};
	referral: {
		code?: string;
		source?: string;
	};
};

type Action = {
	updateUser: (user: User, token?: string) => void;
	updateUserWithAptosSignin: (user: SiwaCallback, token: string) => void;
	updateUsername: (username: string) => void;
	updateNotification: (notification: string) => void;
	updateFFBalance: (ffBalance: number) => void;
	addFFBalance: (ffBalance: number) => void;
	updateFightBalance: (fightBalance: string) => void;
	updateIsAdmin: (isAdmin: boolean) => void;
	getFightUsername: () => string;
	updateReferral: (
		code: State["referral"]["code"],
		source: State["referral"]["source"]
	) => void;
	clearReferral: () => void;
	clear: () => void;
};

export type UserStoreState = State & Action;

export const useUserStore = create<UserStoreState>()(
	devtools(
		persist(
			(set, get) => ({
				user: {
					id: "",
					email: "",
					username: "",
					verified: false,
					notification: "",
					isAdmin: false,
					token: localStorage.getItem("token") || "",
					settings: {
						pushNotificationsEnabled: false,
						emailNotificationsEnabled: false,
					},
				},
				balance: {
					ffBalance: 0,
					fightBalance: "0",
				},
				referral: {
					code: "",
					source: "",
				},

				updateUser: (user, token) => {
					set((state) => ({
						user: {
							...state.user,
							id: user.id?.toLowerCase() || "",
							email: user.email?.toLowerCase() || "",
							username: user.username || "",
							verified: user.email_verified || false,
							notification: user.notification || "",
							isAdmin: user.isAdmin || false,
							token: token || state.user.token,
							campMember: user.campMember || undefined,
							settings: user.settings || {
								pushNotificationsEnabled: false,
								emailNotificationsEnabled: false,
							},
						},
					}));
					if (token) {
						localStorage.setItem("token", token);
					}
				},

				updateUserWithAptosSignin: (user, token) => {
					set((state) => ({
						user: {
							...state.user,
							id: user.userId?.toLowerCase() || "",
							email: user.email?.toLowerCase() || "",
							username: user.username || "",
							verified: user.verified === "true",
							token: token,
						},
					}));
					localStorage.setItem("token", token);
				},

				updateUsername: (username) => {
					set((state) => ({
						user: {
							...state.user,
							username,
						},
					}));
				},

				updateNotification: (notification) => {
					set((state) => ({
						user: {
							...state.user,
							notification,
						},
					}));
				},
				getFightUsername: () => {
					return `${get().user.username}.FIGHT`;
				},

				updateFFBalance: (ffBalance) => {
					set((state) => ({
						balance: {
							...state.balance,
							ffBalance,
						},
					}));
				},
				addFFBalance: (ffBalance) => {
					set((state) => ({
						balance: {
							...state.balance,
							ffBalance: state.balance.ffBalance + ffBalance,
						},
					}));
				},

				updateFightBalance: (fightBalance) => {
					set((state) => ({
						balance: {
							...state.balance,
							fightBalance,
						},
					}));
				},

				updateIsAdmin: (isAdmin) => {
					set((state) => ({
						user: {
							...state.user,
							isAdmin,
						},
					}));
				},

				updateReferral: (code, source) => {
					set(() => ({
						referral: {
							code,
							source,
						},
					}));
				},
				clearReferral: () => {
					set(() => ({
						referral: {
							code: "",
							source: "",
						},
					}));
				},

				clear: () => {
					localStorage.removeItem("token");
					set(() => ({
						user: {
							id: "",
							email: "",
							username: "",
							verified: false,
							notification: "",
							ffBalance: 0,
							fightBalance: "0",
							isAdmin: false,
							token: "",
							settings: {
								pushNotificationsEnabled: false,
								emailNotificationsEnabled: false,
							},
						},
					}));
				},
			}),
			{
				name: "user-store",
			}
		)
	)
);
