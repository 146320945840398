import React from "react";
import { Input, InputProps } from "@/common/components/ui/input";
import { cn } from "@/common/lib/utils";

interface FormInputProps extends InputProps {
	className?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
	({ className, ...props }, ref) => {
		return (
			<Input
				ref={ref}
				className={cn("rounded text-base font-medium h-16", className)}
				{...props}
			/>
		);
	}
);
FormInput.displayName = "FormInput";

export default FormInput;
