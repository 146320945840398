// src/push-event-handler.ts
import logger from "./common/utils/logger";
import { publicVapidKey } from "./environment";

if ("serviceWorker" in navigator) {
	window.addEventListener("load", async () => {
		try {
			const registration = await navigator.serviceWorker.register("/sw.js");

			// Initialize push handler
			registration.active?.postMessage({
				type: "PUSH_HANDLER_INIT",
				options: {
					userVisibleOnly: true,
					applicationServerKey: publicVapidKey,
				},
			});

			// Listen for push messages
			navigator.serviceWorker.addEventListener("message", (event) => {
				if (event.data && event.data.type === "PUSH_RECEIVED") {
					const { title, options } = event.data;
					if (document.visibilityState === "hidden") {
						new Notification(title, options);
					}
				}
			});
		} catch (error) {
			logger.error(error, "Service Worker Registration Failed");
		}
	});
}
