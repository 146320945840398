import { interceptor } from "@/common/services/interceptor/query.interceptor";
import { createApi } from "@reduxjs/toolkit/query/react";
import { GetAccountKPIResponseDto } from "../types/entities/account/dto/account.dto";
import { GetAccountGrowthResponseDto } from "../types/entities/account/dto/growth.dto";
import { GetReferralsKPIResponseDto } from "../types/entities/referrals/dto/referrals.dto";

export const adminApi = createApi({
	reducerPath: "adminApi",
	baseQuery: interceptor,
	tagTypes: ["AccountsKpi", "AccountsGrowth", "ReferralsKpi"],
	endpoints: (builder) => ({
		getAccountsKPI: builder.query<GetAccountKPIResponseDto, void>({
			query: () => ({
				url: `admin/kpi/accounts`,
				method: "get",
			}),
			providesTags: ["AccountsKpi"],
		}),
		getAccountsGrowth: builder.query<GetAccountGrowthResponseDto, void>({
			query: () => ({
				url: `admin/kpi/accounts-growth`,
				method: "get",
			}),
			providesTags: ["AccountsGrowth"],
		}),
		getReferralsKPI: builder.query<GetReferralsKPIResponseDto, void>({
			query: () => ({
				url: `admin/kpi/referrals`,
				method: "get",
			}),
			providesTags: ["ReferralsKpi"],
		}),
	}),
});

export const {
	useGetAccountsKPIQuery,
	useGetAccountsGrowthQuery,
	useGetReferralsKPIQuery,
} = adminApi;
