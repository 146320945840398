import {
	useGetCampQuestClaimableQuery,
	useClaimCampQuestMutation,
} from "@/common/services/forgegg.api";
import logger from "@/common/utils/logger";
import MainButton from "@/common/components/design/buttons/button";
import { toast } from "sonner";

export function ClaimPointsButton() {
	const { data: claimablePointsData, isLoading: claimablePointsIsLoading } =
		useGetCampQuestClaimableQuery();

	const [claimPoints, { isLoading: claimPointsIsLoading }] =
		useClaimCampQuestMutation();

	const handleClaimPoints = async () => {
		try {
			// await claimPoints().unwrap();
			const response = await claimPoints();

			if (response.error) {
				throw new Error(response.error.message);
			}

			if (response.data) {
				toast.success("Points claimed successfully");
			}
		} catch (error) {
			logger.error(error, "HandleClaimPoints | ClaimPointsButton");
			toast.error("Failed to claim points");
		}
	};

	return (
		<MainButton
			className="px-6 mt-2 w-fit font-semibold text-xl"
			disabled={
				claimablePointsIsLoading || claimablePointsData?.data.totalPoints === 0
			}
			isLoading={claimablePointsIsLoading || claimPointsIsLoading}
			isLoadingLabel={claimablePointsIsLoading ? "Loading..." : "Claiming..."}
			onClick={handleClaimPoints}
		>
			Claim {claimablePointsData?.data.totalPoints} $FF
		</MainButton>
	);
}
