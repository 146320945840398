import { Copy, User } from "lucide-react";
import { NavLink } from "react-router-dom";
import { toast } from "sonner";

import {
	SidebarMenuButton,
	SidebarMenuItem,
} from "@/common/components/ui/sidebar";
import {
	APTOS_CONNECT_ACCOUNT_URL,
	isAptosConnectWallet,
	truncateAddress,
	useWallet,
} from "@aptos-labs/wallet-adapter-react";

export function NavWallet() {
	const { account, wallet } = useWallet();

	const copyAddress = async () => {
		if (!account?.address) return;
		try {
			await navigator.clipboard.writeText(account.address.toString());
			toast.success("Copied wallet address to clipboard.");
		} catch {
			toast.error("Failed to copy wallet address.");
		}
	};

	if (!wallet || !isAptosConnectWallet(wallet)) {
		return null;
	}

	return (
    <>
			<SidebarMenuItem>
				<SidebarMenuButton>
					<div className="flex items-center gap-2">
						{account?.ansName ||
							truncateAddress(account?.address.toString()) ||
							"Unknown"}
						<Copy
							className="h-4 w-4"
							onClick={copyAddress}
						/>
					</div>
				</SidebarMenuButton>
			</SidebarMenuItem>
			<SidebarMenuItem>
				<SidebarMenuButton asChild>
					<NavLink
						to={APTOS_CONNECT_ACCOUNT_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="flex gap-2"
					>
						<User className="h-4 w-4" /> Account
					</NavLink>
				</SidebarMenuButton>
			</SidebarMenuItem>
		</>
	);
}

export default NavWallet;
