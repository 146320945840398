import { createApi } from "@reduxjs/toolkit/query/react";
import {
	ClaimNFTsRequestDto,
	ClaimNFTsResponseDto,
} from "../types/entities/nfts/dto/claim-nfts.dto";
import { interceptor } from "./interceptor/query.interceptor";
import {
	RevalidateNFTsRequestDto,
	RevalidateNFTsResponseDto,
} from "../types/entities/nfts/dto/revalidate-nfts.dto";

export const nftsApi = createApi({
	reducerPath: "nftsApi",
	baseQuery: interceptor,
	endpoints: (builder) => ({
		claimNFTs: builder.mutation<ClaimNFTsResponseDto, ClaimNFTsRequestDto>({
			query: (body) => ({
				url: "nfts/claim",
				method: "POST",
				body,
			}),
		}),
		revalidateNFTs: builder.query<
			RevalidateNFTsResponseDto,
			RevalidateNFTsRequestDto
		>({
			query: (request) => ({
				url: `nfts/revalidate?wallet=${request.userWallet}`,
				method: "get",
			}),
		}),
		historyNFTs: builder.query<
			RevalidateNFTsResponseDto,
			void
		>({
			query: () => ({
      url: `nfts/history`,
				method: "get",
			}),
		}),
	}),
});

export const {
	useClaimNFTsMutation,
	useRevalidateNFTsQuery,
	useLazyRevalidateNFTsQuery,
  useHistoryNFTsQuery,
} = nftsApi;
