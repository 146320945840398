import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type State = {
	wallet: {
		loggedIn: boolean;
		addr: string | null;
	};
};

type Action = {
	setWallet: (walletData: { loggedIn: boolean; addr: string | null }) => void;
	disconnect: () => void;
};

export const useWalletStore = create<State & Action>()(
	devtools(
		persist(
			(set) => ({
				wallet: {
					loggedIn: false,
					addr: null,
				},

				setWallet: (walletData) => {
					set(() => ({
						wallet: {
							loggedIn: walletData.loggedIn,
							addr: walletData.addr,
						},
					}));
				},

				disconnect: () => {
					set(() => ({
						wallet: {
							loggedIn: false,
							addr: null,
						},
					}));
				},
			}),
			{
				name: "wallet-store",
			}
		)
	)
);
