export default function CornerArrow() {
	return (
		<svg
			width="73"
			height="71"
			viewBox="0 0 73 71"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="absolute bottom-0 right-0"
		>
			<path d="M73 0V71H0.5L73 0Z" fill="#FF4001" />
			<path
				d="M52.5047 43L60 50.5L52.5047 58L50.547 56.041L54.7001 51.8853H43V49.1148H54.7001L50.547 44.959L52.5047 43Z"
				fill="white"
			/>
		</svg>
	);
}
