import logger from "@/common/utils/logger";
import * as fcl from "@onflow/fcl";
import { useCallback } from "react";

export const useWallet = () => {
	const signMessage = useCallback(async () => {
		try {
			const currentUser = await fcl.currentUser.snapshot();

			// Convert the message to a hexadecimal string using TextEncoder
			const encoder = new TextEncoder();
			const messageBytes = encoder.encode(
				`I am signing this message to verify my wallet ownership. Wallet: ${currentUser.addr}`
			);
			const messageHex = Array.from(messageBytes)
				.map((byte) => byte.toString(16).padStart(2, "0"))
				.join("");

			// Request signature of message
			const signedMessage = await fcl.currentUser.signUserMessage(messageHex);

			const result = {
				wallet: currentUser.addr,
				message: messageHex,
				signatures: signedMessage,
			};

			return result;
		} catch (error) {
			logger.error(error, "useWallet");
			throw error;
		}
	}, []);

	return {
		signMessage,
	};
};
