import { Routes } from "@/common/types/routes";
import Layout from "@/modules/login/layout";
import { RouteObject } from "react-router-dom";
import ClaimFightidView from "./views/claim-fightid/claim-fightid";
// import SuccessCodeView from "./views/connect/code/success-code";
// import ConnectAccountView from "./views/connect/connect-account";
// import ConnectWalletView from "./views/connect/nft/connect-wallet";
// import SuccessNFTView from "./views/connect/nft/success-nft";
// import LoginView from "./views/login/login";
import SignUpView from "./views/signup/signup";
// import VerifyEmailView from "./views/signup/verify/verify-email";
// import ErrorNFTView from "./views/connect/nft/error-nft";
// import ErrorCodeView from "./views/connect/code/error-code";
// import VerifyEmailLogin from "./views/login/verify/verify-email-login";
import LoadingUserView from "./views/loading/loading-user";
import LandingView from "./views/landing/landing";
import ClaimFightidSuccessView from "./views/claim-fightid/claim-fightid-success";
import VerifyEmailView from "./views/signup/verify/verify-email";
import OAuthConsentView from "./views/oauth/oauth-consent";
import OAuthAuthorizeView from "./views/oauth/oauth-authorize";

const loginRoutes: RouteObject[] = [
	{
		path: Routes.Landing,
		element: (
			<Layout>
				<LandingView />
			</Layout>
		),
	},
	// {
	// 	path: Routes.Login,
	// 	element: (
	// 		<Layout>
	// 			<LoginView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.VerifyEmailLogin,
	// 	element: (
	// 		<Layout>
	// 			<VerifyEmailLogin />
	// 		</Layout>
	// 	),
	// },
	{
		path: Routes.Signup,
		element: (
			<Layout>
				<SignUpView />
			</Layout>
		),
	},
	{
		path: Routes.VerifyEmail,
		element: (
			<Layout>
				<VerifyEmailView />
			</Layout>
		),
	},
	// {
	// 	path: Routes.ClaimConnect,
	// 	element: (
	// 		<Layout>
	// 			<ConnectAccountView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.ClaimWithNFT,
	// 	element: (
	// 		<Layout>
	// 			<ConnectWalletView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.ClaimWithNFTSuccess,
	// 	element: (
	// 		<Layout>
	// 			<SuccessNFTView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.ClaimWithNFTError,
	// 	element: (
	// 		<Layout>
	// 			<ErrorNFTView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.ClaimWithCodeSuccess,
	// 	element: (
	// 		<Layout>
	// 			<SuccessCodeView />
	// 		</Layout>
	// 	),
	// },
	// {
	// 	path: Routes.ClaimWithCodeError,
	// 	element: (
	// 		<Layout>
	// 			<ErrorCodeView />
	// 		</Layout>
	// 	),
	// },
	{
		path: Routes.ClaimFightID,
		element: (
			<Layout>
				<ClaimFightidView />
			</Layout>
		),
	},
	{
		path: Routes.ClaimFightIDSuccess,
		element: (
			<Layout>
				<ClaimFightidSuccessView />
			</Layout>
		),
	},
	{
		path: Routes.AuthLoading,
		element: (
			<Layout>
				<LoadingUserView />
			</Layout>
		),
	},
	{
		path: Routes.OAuthAuthorize,
		element: (
			<Layout>
				<OAuthAuthorizeView />
			</Layout>
		),
	},
	{
		path: Routes.OAuthConsent,
		element: (
			<Layout>
				<OAuthConsentView />
			</Layout>
		),
	},
];

export default loginRoutes;
