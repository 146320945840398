import NotFound from "@/common/views/not-found";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import questsRoutes from "./modules/quests-old/router";
import loginRoutes from "./modules/login/router";
import profileRoutes from "./modules/profile/router";
import campsRoutes from "./modules/camps/router";
import homeRoutes from "./modules/home/router";
import referralsQuestRoutes from "./modules/(quest)/referrals/router";
import walletConnectQuestRoutes from "./modules/(quest)/wallet-connect/router";
import forgeggQuestRoutes from "./modules/(quest)/forgegg/router";
import lightsOutQuestRoutes from "./modules/(quest)/lights-out/router";
import adminRoutes from "./modules/admin/router";
import ticketsTwitterQuestRoutes from "./modules/(quest)/ticket-twitter/router";
import campsQuestRoutes from "./modules/(quest)/camps/router";import { AuthLayoutErrorElement } from "./common/components/error-boundary/auth-layout-with-error";
import { LoginLayoutErrorElement } from "./common/components/error-boundary/login-layout-with-error";
import RouteError from "./common/components/error-boundary/route-error";

// Add error elements to routes based on their type
const addErrorElements = (
	routes: RouteObject[],
	isAuthRoute: boolean
): RouteObject[] => {
	return routes.map((route) => ({
		...route,
		errorElement: isAuthRoute ? (
			<AuthLayoutErrorElement />
		) : (
			<LoginLayoutErrorElement />
		),
	}));
};

// Create routes with appropriate error elements
const authRoutesWithError = addErrorElements(
	[
		...homeRoutes,
		...profileRoutes,
		...questsRoutes,
		...campsRoutes,
	  // Quests
		...referralsQuestRoutes,
		...walletConnectQuestRoutes,
		...forgeggQuestRoutes,
		...lightsOutQuestRoutes,
		...adminRoutes,
		...ticketsTwitterQuestRoutes,
	  ...campsQuestRoutes,
	],
	true
);

const nonAuthRoutesWithError = addErrorElements([...loginRoutes], false);

// Combine all routes
const routes: RouteObject[] = [
	...authRoutesWithError,
	...nonAuthRoutesWithError,
	{
		path: "*",
		element: <NotFound />,
		errorElement: <RouteError />,
	},
];

const router = createBrowserRouter(routes);

export default router;
