import { WalletSelector } from "@/common/components/aptos/wallet-selector";
import MainButton from "@/common/components/design/buttons/button";
// import FormInput from "@/common/components/design/inputs/form/input";
import {
	Form,
	// FormControl,
	// FormField,
	// FormItem,
	// FormMessage,
} from "@/common/components/ui/form";
// import { useUserStore } from "@/common/hooks/use-user-store";
// import { useSignUpMutation } from "@/common/services/auth.api";
// import logger from "@/common/utils/logger";
import { zodResolver } from "@hookform/resolvers/zod";
// import { useState } from "react";
import { useForm } from "react-hook-form";
// import { toast } from "sonner";
import * as z from "zod";
// import { useLoadingStore } from "../../hooks/use-loading";
import { useAuth0 } from "@auth0/auth0-react";

const registerFormSchema = z.object({
	email: z.string().email(),
});

export default function SignUpForm() {
	const { loginWithRedirect } = useAuth0();

	// const [isLoading, setIsLoading] = useState(false);
	// const [signup] = useSignUpMutation();

	// const updateUser = useUserStore((store) => store.updateUser);
	// const updateLoadingState = useLoadingStore(
	// 	(store) => store.updateLoadingState
	// );
	// const user = useUserStore((store) => store.user);

	const registerForm = useForm<z.infer<typeof registerFormSchema>>({
		resolver: zodResolver(registerFormSchema),
		defaultValues: {
			email: "",
		},
	});

	// const onRegisterSubmit = async (
	// 	values: z.infer<typeof registerFormSchema>
	// ) => {
	// 	setIsLoading(true);
	// 	logger.info(values, "onRegisterSubmit");

	// 	try {
	// 		const signupResult = await signup({ email: values.email });

	// 		if (signupResult.error) {
	// 			logger.error(signupResult.error);
	// 			throw new Error();
	// 		}

	// 		updateUser(
	// 			{
	// 				...user,
	// 				email: values.email,
	// 			},
	// 			""
	// 		);
	// 		updateLoadingState("otpSent");
	// 	} catch (error) {
	// 		logger.error(error);
	// 		toast.error("Registration Failed", {
	// 			description: "Please check your credentials and try again.",
	// 		});
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	return (
		<div className="flex flex-col gap-y-4 items-center justify-center w-full">
			{/* <Typography variant="subtitle">SIGN UP</Typography>

			<Typography variant="body">
				Enter your email to receive a one-time password.
			</Typography> */}
			<Form {...registerForm}>
				<form
					// onSubmit={registerForm.handleSubmit(onRegisterSubmit)}
					className="space-y-4 w-full"
				>
					{/* 	<FormField
						control={registerForm.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<FormInput
										className="font-light text-gray-600 placeholder:text-gray-500 max-w-[400px] mx-auto"
										placeholder="Enter your Email"
										autoComplete="off"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<MainButton
						type="submit"
						isLoading={isLoading}
						isLoadingLabel="Sending"
						disabled={!registerForm.formState.isValid}
					>
						Send one-time password
					</MainButton> */}

					<MainButton
						onClick={() => {
							loginWithRedirect({
								authorizationParams: {
									prompt: "login",
								},
							});
						}}
					>
						Sign In
					</MainButton>

					<WalletSelector />
				</form>
			</Form>
		</div>
	);
}
