type QuestCardsListProps = {
	children: React.ReactNode;
};

export default function QuestCardsList({ children }: QuestCardsListProps) {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
			{children}
		</div>
	);
}
