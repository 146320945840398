import Button from "@/common/components/design/buttons/button";
import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import LoadingIcon from "@/common/components/icons/loading-icon";
import { cn } from "@/common/lib/utils";
import { useGetTwitterTicketQuestQuery } from "@/common/services/forgegg.api";
import { Routes } from "@/common/types/routes";
import QuestButton from "../../components/quest-button";

function TwitterTicketGiveawayView() {
	const { data, isLoading, isFetching, refetch } =
		useGetTwitterTicketQuestQuery();

	const handleSync = async () => {
		await refetch();
	};

	const handleNavigateLikeQuest = () => {
		window.open(
			"https://fightfi.forge.gg/home?activeQuestId=like-3-fightfi-b8164fd0-adb5-496b-9ab9-b6bd5a49c767",
			"_blank",
			"noopener,noreferrer"
		);
	};

  const handleNavigateReplyQuest = () => {
		window.open(
			"https://fightfi.forge.gg/home?activeQuestId=reply-2-fightfi-b8164fd0-adb5-496b-9ab9-b6bd5a49c767",
			"_blank",
			"noopener,noreferrer"
		);
	};

  const handleNavigateRetweetQuest = () => {
		window.open(
			"https://fightfi.forge.gg/home?activeQuestId=retweet-2-fightfi-b8164fd0-adb5-496b-9ab9-b6bd5a49c767",
			"_blank",
			"noopener,noreferrer"
		);
	};
  
  const handleNavigateFollowQuest = () => {
		window.open(
			"https://fightfi.forge.gg/home?activeQuestId=follow-16-fightfi-b8164fd0-adb5-496b-9ab9-b6bd5a49c767",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-6 p-4 min-h-full">
				<div className="flex flex-row gap-4 md:mb-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">
							Win Pit & VIP tickets for the next Karate Combat fight!
						</Typography>
						<Typography variant="body" className="md:w-1/2">
							Complete these actions to earn entries. Each action gives you an
							extra chance to win!
						</Typography>
					</div>
				</div>

				<div className="flex flex-col md:flex-row md:justify-evenly gap-4">
					<div className="flex flex-col order-2 md:order-1 gap-4 w-full max-w-96">
						<div className="flex justify-end items-center">
							<Button
								variant="ghost"
								className="w-fit hover:bg-transparent disabled:bg-transparent p-0 m-0 h-fit"
								onClick={handleSync}
								disabled={isLoading || isFetching}
							>
								<LoadingIcon
									className={cn(
										"text-white/70",
										(isLoading || isFetching) && "animate-spin"
									)}
								/>
								<span className="text-lg text-white/70 normal-case font-normal">
									Refresh
								</span>
							</Button>
						</div>

						<div className="flex justify-between">
							<Typography variant="body" className="uppercase text-white">
								Total Entries
							</Typography>
							<Typography variant="body" className="uppercase text-white">
								{
									Object.values(data?.data.giveawayStatus ?? {}).filter(
										(status) => status.completed
									).length
								}{" "}
								entries
							</Typography>
						</div>

						<div className="flex flex-col gap-4">
							<QuestButton
								label="Like"
								isCompleted={data?.data.giveawayStatus.like.completed ?? false}
								isLoading={isLoading}
								onClick={handleNavigateLikeQuest}
							/>
							<QuestButton
								label="Retweet"
								isCompleted={
									data?.data.giveawayStatus.retweet.completed ?? false
								}
								isLoading={isLoading}
								onClick={handleNavigateRetweetQuest}
							/>
							<QuestButton
								label="Follow"
								isCompleted={
									data?.data.giveawayStatus.follow.completed ?? false
								}
								isLoading={isLoading}
								onClick={handleNavigateFollowQuest}
							/>
							<QuestButton
								label="Comment"
								isCompleted={data?.data.giveawayStatus.reply.completed ?? false}
								isLoading={isLoading}
								onClick={handleNavigateReplyQuest}
							/>
						</div>
					</div>

					<div className="flex flex-col order-1 md:order-2 text-center md:text-start gap-4 w-full max-w-96">
						<Typography
							variant="subtitle"
							className="text-2xl font-semibold uppercase"
						>
							🏆 Prize Pool
						</Typography>

						<div className="w-full flex flex-col gap-2">
							<Typography variant="body">
								🥇{" "}
								<span className="font-semibold text-white">
									1 Grand Prize Winner:
								</span>{" "}
								2 tickets in the Pit
							</Typography>
							<Typography variant="body">
								🥈 <span className="foxnt-semibold text-white">2 Winners:</span>{" "}
								2x 1 VIP ticket
							</Typography>
						</div>
					</div>
				</div>
			</BaseCard>
		</div>
	);
}

export default TwitterTicketGiveawayView;
