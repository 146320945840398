import { createApi } from "@reduxjs/toolkit/query/react";
import { GetAllActiveQuestsResponseDto, GetQuestByIdResponseDto } from "../types/entities/quest/dto/quests.dto";
import { interceptor } from "./interceptor/query.interceptor";

export const questsApi = createApi({
	reducerPath: "questsApi",
	baseQuery: interceptor,
	endpoints: (builder) => ({
		getAllActiveQuests: builder.query<GetAllActiveQuestsResponseDto, void>({
			query: () => ({
				url: "quests",
				method: "GET",
			}),
		}),
		getQuestById: builder.query<GetQuestByIdResponseDto, string>({
			query: (id) => ({
				url: `quests/${id}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetAllActiveQuestsQuery, useGetQuestByIdQuery } = questsApi;
