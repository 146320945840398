export const WelcomeBanner = ({
	title,
	description,
}: {
	title: string;
	description: string;
}) => {
	return (
		<div className="w-full bg-black text-white p-4 text-left">
			<h3 className="text-base leading-4 font-bold">{title}</h3>
			<p className="text-xs leading-4">{description}</p>
		</div>
	);
};
