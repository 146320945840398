import { useGetUserDataQuery } from "../services/user.api";
import { useUserStore } from "./use-user-store";
import { useEffect } from "react";
import logger from "@/common/utils/logger";

export function useSyncUserData() {
	const { data: userData, isLoading, error, refetch } = useGetUserDataQuery();
	const updateUser = useUserStore((state) => state.updateUser);
	const updateFFBalance = useUserStore((state) => state.updateFFBalance);

	useEffect(() => {
		if (userData?.data) {
			updateUser(userData.data);
			updateFFBalance(userData.data.points);
		}
	}, [userData, updateUser, updateFFBalance]);

	const handleRefetch = () => {
		if (refetch) {
			try {
				refetch();
			} catch (error) {
				logger.error(error, "Handle Refetch | useSyncUserData");
			}
		}
	};

	return {
		isLoading,
		error,
		refetch: handleRefetch,
	};
}
