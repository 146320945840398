import * as fcl from "@onflow/fcl";
import { useEffect } from "react";
import Button from "@/common/components/design/buttons/button";
import logger from "@/common/utils/logger";
import {
	flowAccessNodeApi,
	flowDiscoveryAuthnEndpoint,
	flowDiscoveryAuthnInclude,
	flowDiscoveryWallet,
	flowNetwork,
	flowToken,
	walletConnectProjectID,
} from "@/environment";
import { useWalletStore } from "@/common/hooks/use-wallet-store";

fcl.config({
	"app.detail.title": "FIGHT.iD",
	"app.detail.icon": "https://app.fight.id/icons/favicons/black/48x48.png",
	"app.detail.description":
		"Decentralized ecosystem and DAO, uniting the MMA world through blockchain technology",
	"app.detail.url": window.location.origin,

	"flow.network": flowNetwork,
	"walletconnect.projectId": walletConnectProjectID, // Wallet connect - Reown
	"0xFlowToken": flowToken,

	"accessNode.api": flowAccessNodeApi,
	"discovery.wallet": flowDiscoveryWallet,
	"discovery.wallet.method": "TAB/RPC",

	"discovery.authn.endpoint": flowDiscoveryAuthnEndpoint,
	"discovery.authn.include": [flowDiscoveryAuthnInclude],
});

export function WalletConnect() {
	const { wallet, setWallet, disconnect } = useWalletStore();

	// Subscribe to user updates
	useEffect(() => {
		try {
			fcl.currentUser.subscribe((user: { loggedIn: any; addr: any }) => {
				setWallet({
					loggedIn: user.loggedIn,
					addr: user.addr || null,
				});
			});
		} catch (error) {
			logger.error(error, "wallet connect");
		}
	}, [setWallet]);

	const handleConnect = async () => {
		try {
			fcl.unauthenticate();
			await fcl.authenticate();
		} catch (error) {
			logger.error(error, "wallet connect");
		}
	};

	const handleDisconnect = async () => {
		try {
			fcl.unauthenticate();
			disconnect();
		} catch (error) {
			logger.error(error, "wallet connect");
		}
	};

	return (
		<div className="w-full">
			{!wallet.loggedIn ? (
				<Button
					onClick={handleConnect}
					className="md:max-w-none bg-white hover:bg-white/90 text-black text-base font-medium normal-case md:w-[400px]"
				>
					Connect Dapper Wallet
				</Button>
			) : (
				<Button
					onClick={handleDisconnect}
					variant="ghost"
					className="text-white/70 md:max-w-none focus:bg-transparent normal-case font-normal"
				>
					Disconnect
				</Button>
			)}
		</div>
	);
}

export default WalletConnect;
