// components/FightCard.tsx
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from "@/common/components/ui/card";
import { Badge } from "@/common/components/ui/badge";
import { Button } from "@/common/components/ui/button";
import { cn } from "@/common/lib/utils";
import { OldQuest } from "@/common/types/entities/quest/quest";
import { formatNumberToCurrency } from "../../helpers/currency";
import UFCStrike from "@/assets/partners/UFCStrike.png";
import { DrawerDialogQuest } from "./drawer";

interface QuestCardProps {
	quest: OldQuest;
	onSelect?: () => void;
}

const statusColors = {
	OPEN: "bg-[#B0E388]",
	COMPLETED: "bg-[#D9D9D9]",
	UPCOMING: "bg-[#D9D9D9]",
	EXPIRED: "bg-[#D9D9D9]",
};

const statusColorBorders = {
	OPEN: "border-[#66E700]",
	COMPLETED: "border-[#D9D9D9]",
	UPCOMING: "border-[#D9D9D9]",
	EXPIRED: "border-[#D9D9D9]",
};

const badgeColors = {
	OPEN: "bg-[#DEFFC3]",
	COMPLETED: "bg-[#66E700]",
	UPCOMING: "bg-[#66E700]",
	EXPIRED: "bg-[#66E700]",
};

export function QuestCard({ quest, onSelect }: QuestCardProps) {
	const isClickable =
		quest.status !== "EXPIRED" && quest.reward?.status !== "CLAIMED";

	const CardWrapper = ({ children }: { children: React.ReactNode }) => {
		if (!isClickable) {
			return <>{children}</>;
		}

		return <DrawerDialogQuest {...quest}>{children}</DrawerDialogQuest>;
	};

	return (
		<CardWrapper>
			<Card
				className={cn(
					"rounded-lg overflow-hidden border-2 min-h-60 flex flex-col justify-between",
					statusColors[quest.status],
					statusColorBorders[quest.status],
					!isClickable ? "opacity-50" : "hover:cursor-pointer"
				)}
			>
				<CardHeader className="p-0">
					<div className="flex flex-row justify-between">
						<div className="flex justify-start gap-2 items-center p-2  font-bold">
							<span className="text-base bg-white px-2 py-0.5 w-auto rounded-md">
								{quest.status}
							</span>
							<span className="text-xs uppercase">{quest.deadline}</span>
						</div>
						{quest.sponsor && (
							<div className="h-7 p-2 mt-2 bg-[#494949]">
								{quest.sponsor === "UFC-Strike" && (
									<img
										src={`${UFCStrike}?height=30&width=80`}
										alt="UFC STRIKE"
										className="h-full"
									/>
								)}
							</div>
						)}
					</div>
				</CardHeader>
				<CardContent className="flex flex-col p-4 gap-2"></CardContent>
				<CardFooter className="flex flex-col p-4 gap-2 items-start">
					<div className="flex flex-col gap-0">
						<h4 className="text-lg font-bold  uppercase">{quest.title}</h4>
						<p className="font-roboto">{quest.description}</p>
					</div>
					<div className="flex flex-row w-full justify-between">
						<div>
							<div>
								<Badge
									variant="default"
									className={cn(
										"text-black font-roboto w-auto h-7 px-2 py-0.5 rounded-md",
										badgeColors[quest.status]
									)}
								>
									{quest.reward?.currency} {quest.reward?.amount}
								</Badge>
							</div>
							<p className="font-medium  text-sm">
								{formatNumberToCurrency(quest.reward?.totalPrize)}{" "}
								{quest.reward?.currency}
							</p>
						</div>
						<div>
							{quest.status === "COMPLETED" && (
								<Button
									className="text-[#A3FF5A] text-xs leading-3  font-bold uppercase"
									onClick={() =>
										quest.reward?.status === "CLAIMED" ? null : onSelect
									}
									disabled={quest.reward?.status === "CLAIMED"}
								>
									Claim ${quest.reward?.currency}
									{quest.reward?.amount}
								</Button>
							)}
						</div>
					</div>
				</CardFooter>
			</Card>
		</CardWrapper>
	);
}
