import React from "react";

interface HomeIconProps {
	className?: string;
}
const HomeIcon: React.FC<HomeIconProps> = ({ className }) => (
	<svg
		width="19"
		height="17"
		viewBox="0 0 19 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M7.5001 15.9997V10.9997H11.5001V15.9997C11.5001 16.5497 11.9501 16.9997 12.5001 16.9997H15.5001C16.0501 16.9997 16.5001 16.5497 16.5001 15.9997V8.99973H18.2001C18.6601 8.99973 18.8801 8.42973 18.5301 8.12973L10.1701 0.599727C9.7901 0.259727 9.2101 0.259727 8.8301 0.599727L0.470098 8.12973C0.130098 8.42973 0.340098 8.99973 0.800098 8.99973H2.5001V15.9997C2.5001 16.5497 2.9501 16.9997 3.5001 16.9997H6.5001C7.0501 16.9997 7.5001 16.5497 7.5001 15.9997Z"
			fill="currentColor"
		/>
	</svg>
);

export default HomeIcon;
