import { cn } from "@/common/lib/utils";
import Button from "@/common/components/design/buttons/button";
import LoadingIcon from "@/common/components/icons/loading-icon";
import { FilledPacksResponse } from "@/common/types/entities/lightsout/pack";
import { useWalletStore } from "@/common/hooks/use-wallet-store";
import { useGetPacksToClaimQuery } from "@/common/services/lightsout.api";
import { toast } from "sonner";
import logger from "@/common/utils/logger";
import { ClaimButton } from "./claim-button";

const TIERS = [
	{ category: "Base", price: "100", enabled: true },
	{ category: "Elite", price: "1000", enabled: true },
] as const;

export function ClaimDetails() {
	const isWalletConnected = useWalletStore((store) => store.wallet.loggedIn);
	const userWallet = useWalletStore((store) => store.wallet.addr);

	const {
		data: revalidateData,
		isLoading: isRevalidateLoading,
		isFetching: isRevalidateFetching,
		refetch,
	} = useGetPacksToClaimQuery(
		{
			wallet: userWallet || "",
		},
		{
			skip: !userWallet,
		}
	);

	const handleRefreshUserNFTs = async () => {
		try {
			const response = await refetch();

			if (response.error) {
				toast.error(response.error.data.message);
			} else {
				response.data?.data.records.base.length === 0 &&
					response.data?.data.records.elite.length === 0 &&
					toast.success("No packs to claim");
			}
		} catch (error) {
			logger.error(error, "lights-out | refresh-nfts");
		}
	};

	const calculateAmounts = (
		basePacks?: FilledPacksResponse,
		elitePacks?: FilledPacksResponse
	) => {
		const counts = new Map<string, number>();

		// Initialize all tiers with 0
		TIERS.forEach((tier) => {
			counts.set(tier.category, 0);
		});

		// Count from base packs
		if (basePacks) {
			basePacks.forEach(() => {
				const currentCount = counts.get("Base") || 0;
				counts.set("Base", currentCount + 1);
			});
		}

		// Count from elite packs
		if (elitePacks) {
			elitePacks.forEach(() => {
				const currentCount = counts.get("Elite") || 0;
				counts.set("Elite", currentCount + 1);
			});
		}

		return counts;
	};

	const tierAmounts = calculateAmounts(
		revalidateData?.data.records.base,
		revalidateData?.data.records.elite
	);

	return (
		<>
			{isWalletConnected && (
				<div className="w-full max-w-2xl mx-auto md:min-w-96">
					<div className="space-y-2">
						<div className="flex justify-end items-center">
							<Button
								variant="ghost"
								className="w-fit hover:bg-transparent disabled:bg-transparent"
								onClick={handleRefreshUserNFTs}
								disabled={isRevalidateLoading || isRevalidateFetching}
							>
								<LoadingIcon
									className={cn(
										"text-white/70",
										(isRevalidateLoading || isRevalidateFetching) &&
											"animate-spin"
									)}
								/>
								<span className="text-lg text-white/70 normal-case font-normal">
									Refresh
								</span>
							</Button>
						</div>
						{TIERS.map((tier) => (
							<div
								key={tier.category}
								className={cn(
									"grid items-center font-medium grid-cols-4 text-left"
								)}
							>
								<div className={tier.enabled ? "col-span-1" : "col-span-1"}>
									{tier.category}
								</div>
								<div
									className={cn(
										"col-span-3 text-right",
										tier.enabled ? "col-span-2" : "col-span-3"
									)}
								>
									{tier.enabled ? (
										<>
											{tier.price}
											<span className="text-xs">FF</span>
										</>
									) : (
										<span>Coming Soon</span>
									)}
								</div>
								{tier.enabled && (
									<div className="text-right">
										x{tierAmounts.get(tier.category)}
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			)}
			<ClaimButton
				claimPoints={revalidateData?.data.records.claimPoints ?? 0}
			/>
		</>
	);
}

export default ClaimDetails;
