import BaseCard from "@/common/components/design/card/base-card";

function SkeletonFighterCard() {
	return (
		<BaseCard className="flex flex-col overflow-hidden h-full relative bg-black rounded-lg animate-pulse">
			{/* Main container */}
			<div className="flex flex-col h-full">
				{/* Fighter Image Skeleton */}
				<div className="h-[150px] w-full bg-gray-800"></div>

				{/* Fighter Info Section Skeleton */}
				<div className="p-3 flex-grow flex flex-col gap-2">
					{/* Fighter Name Skeleton */}
					<div className="bg-gray-700 w-3/4 h-5 rounded"></div>

					{/* Weight Class Skeleton */}
					<div className="bg-gray-700 w-24 h-3 rounded"></div>
				</div>
			</div>
		</BaseCard>
	);
}

export default SkeletonFighterCard;
