import { useUserStore } from "@/common/hooks/use-user-store";

export function FFBalance() {
	const ffBalance = useUserStore((store) => store.balance.ffBalance);

	return (
		<div className="text-[1.1rem] leading-[1.4rem] font-bold uppercase  px-4">
			{ffBalance} $FF
		</div>
	);
}
