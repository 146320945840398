import "./instrument.ts";
import "./polyfills";

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.tsx";
import { WalletProvider } from "./common/components/aptos/wallet-provider.tsx";
import { auth0AuthorizationParams } from "./common/config/auth0.config";
import { store } from "./common/store/store.ts";
import { auth0ClientId, auth0Domain } from "./environment.ts";
import "./index.css";

const queryClient = new QueryClient();

const onRedirectCallback = (appState?: { returnTo?: string } | undefined) => {
	window.location.href = appState?.returnTo || window.location.pathname;
};
createRoot(document.getElementById("root")!).render(
	<StrictMode>
		<Auth0Provider
			domain={auth0Domain}
			clientId={auth0ClientId}
			cacheLocation="localstorage"
			useRefreshTokens
			authorizationParams={auth0AuthorizationParams}
			onRedirectCallback={onRedirectCallback}
		>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<WalletProvider>
						<App />
					</WalletProvider>
				</QueryClientProvider>
			</Provider>
		</Auth0Provider>
	</StrictMode>
);
