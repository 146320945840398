import Typography from "@/common/components/design/texts/typography";

interface StatItemProps {
	label: string;
	value: string | number;
}

function StatItem({ label, value }: StatItemProps) {
	return (
		<div className="flex justify-between text-left">
			<Typography
				variant="caption"
				className="text-white/60 font-bold font-chakra text-xs"
			>
				{label}
			</Typography>
			<Typography
				variant="body"
				className="text-white font-chakra font-bold text-base"
			>
				{value}
			</Typography>
		</div>
	);
}

export default StatItem;
