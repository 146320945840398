import React from "react";

interface CheckCompletedIconProps {
	className?: string;
}

const CheckCompletedIcon: React.FC<CheckCompletedIconProps> = ({
	className,
}) => (
	<svg
		className={`fill-current ${className}`}
		width="28"
		height="28"
		viewBox="0 0 28 28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2953_26403)">
			<path d="M23.8993 4.10069C18.4317 -1.36691 9.56709 -1.36691 4.10063 4.1008C-1.36691 9.56834 -1.36691 18.4329 4.10085 23.8994C9.56709 29.3669 18.4317 29.3669 23.8993 23.8993C29.3669 18.4329 29.3669 9.56834 23.8993 4.10069ZM22.2495 22.2493C17.693 26.8057 10.306 26.8057 5.75077 22.2495C1.19432 17.6941 1.19432 10.307 5.75066 5.75066C10.306 1.19427 17.693 1.19427 22.2494 5.75066C26.8057 10.3071 26.8057 17.6941 22.2495 22.2493Z" />
			<path d="M20.1749 8.50869L12.8332 15.8504L8.9915 12.0087C8.5359 11.5531 7.79718 11.5531 7.34158 12.0087C6.88598 12.4643 6.88598 13.203 7.34158 13.6586L12.0082 18.3253C12.4638 18.7809 13.2025 18.7809 13.6581 18.3253L21.8248 10.1586C22.2804 9.70301 22.2804 8.96429 21.8248 8.50869C21.3692 8.05309 20.6305 8.05309 20.1749 8.50869Z" />
		</g>
		<defs>
			<clipPath id="clip0_2953_26403">
				<rect width="28" height="28" />
			</clipPath>
		</defs>
	</svg>
);

export default CheckCompletedIcon;
