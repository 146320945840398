import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOAuth } from "../../hooks/use-oauth";
import { useUserStore } from "@/common/hooks/use-user-store";
import { Routes } from "@/common/types/routes";
import { useOAuthStore } from "../../hooks/use-oauth-store";
import { logger } from "@/common/utils/logger";

function OAuthAuthorizeView() {
	const hasInitiated = useRef(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	
  const { isAuthenticated } = useOAuth();
	const user = useUserStore((state) => state.user);
	const setOAuthParams = useOAuthStore((state) => state.setParams);

	useEffect(() => {
		const clientId = searchParams.get("client_id");
		const redirectUri = searchParams.get("redirect_uri");
		const scope = searchParams.get("scope");
		const state = searchParams.get("state");

		if (!clientId || !redirectUri || hasInitiated.current) {
			return;
		}

		hasInitiated.current = true;

		// Store OAuth parameters in Zustand
		setOAuthParams({
			clientId,
			redirectUri,
			scope: scope || undefined,
			state: state || undefined,
		});

		if (!isAuthenticated) {
			// Not logged in, redirect to signup
			logger.info("User not authenticated, redirecting to signup");
			navigate(Routes.Signup);
			return;
		}

		if (!user?.username) {
			// Logged in but no username, redirect to claim
			logger.info("User authenticated but needs to claim username");
			navigate(Routes.ClaimFightID);
			return;
		}

		// User is logged in and has username, redirect to consent
		logger.info("User is authenticated, redirecting to consent screen");
		navigate(`/oauth/consent?${searchParams.toString()}`);
	}, [searchParams, navigate, isAuthenticated, user?.username, setOAuthParams]);

	return null; // This component doesn't render anything as it immediately redirects
}

export default OAuthAuthorizeView;
