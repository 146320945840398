import { NavLink } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { ButtonProps } from "@/common/components/ui/button";

interface NavButtonProps extends Omit<ButtonProps, "asChild"> {
	to: string;
}

export function NavBackButton({ to }: NavButtonProps) {
	return (
		<NavLink
			to={to}
			className="inline-flex items-center cursor-pointer hover:text-muted-foreground transition-colors p-1"
		>
			<ArrowLeft className="h-6 w-6" />
			<span className="sr-only">Back</span>
		</NavLink>
	);
}
