import { cn } from "@/common/lib/utils";
import { Button, ButtonProps } from "@/common/components/ui/button";
import React from "react";

interface LinkButtonProps extends ButtonProps {
	className?: string;
	disabled?: boolean;
	children: React.ReactNode;
}

function LinkButton({
	className,
	disabled,
	children,
	...props
}: LinkButtonProps) {
	return (
		<Button
			type="button"
			variant="link"
			className={cn("text-base font-roboto", className)}
			disabled={disabled}
			{...props}
		>
			{children}
		</Button>
	);
}

export default LinkButton;
