import React from "react";

interface LoadingIconProps {
	className?: string;
}
const LoadingIcon: React.FC<LoadingIconProps> = ({ className }) => (
	<svg
		width="15"
		height="16"
		viewBox="0 0 15 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g clipPath="url(#clip0_2550_3021)">
			<path
				d="M15 3.03128V5.70312C15 6.22089 14.5803 6.64062 14.0625 6.64062H11.3907C10.8729 6.64062 10.4532 6.22089 10.4532 5.70312C10.4532 5.18536 10.8729 4.76562 11.3907 4.76562H12.107C11.0718 3.28188 9.37521 2.375 7.5 2.375C5.16683 2.375 3.10992 3.7787 2.25979 5.95106C2.07105 6.43323 1.52719 6.67109 1.04508 6.48242C0.56291 6.29372 0.32502 5.74988 0.513721 5.26774C1.05744 3.8784 1.99459 2.69237 3.22386 1.83787C4.48295 0.962627 5.96159 0.5 7.5 0.5C9.03841 0.5 10.5171 0.962627 11.7761 1.83784C12.2764 2.1856 12.7283 2.58831 13.125 3.03726V3.03131C13.125 2.51355 13.5447 2.09381 14.0625 2.09381C14.5803 2.09381 15 2.51352 15 3.03128ZM13.9549 9.51761C13.4728 9.32888 12.9289 9.56683 12.7402 10.049C11.8901 12.2213 9.83317 13.625 7.5 13.625C5.66464 13.625 4.00037 12.7562 2.95986 11.3281H3.60935C4.12711 11.3281 4.54685 10.9084 4.54685 10.3906C4.54685 9.87286 4.12711 9.45312 3.60935 9.45312H0.9375C0.419736 9.45312 0 9.87286 0 10.3906V13.0625C0 13.5803 0.419736 14 0.9375 14C1.45526 14 1.875 13.5803 1.875 13.0625V12.9628C2.27168 13.4117 2.72358 13.8144 3.22386 14.1622C4.48295 15.0374 5.96159 15.5 7.5 15.5C9.03841 15.5 10.5171 15.0374 11.7761 14.1622C13.0054 13.3077 13.9426 12.1216 14.4863 10.7323C14.675 10.2501 14.4371 9.70631 13.9549 9.51761Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2550_3021">
				<rect
					width="15"
					height="15"
					fill="white"
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default LoadingIcon;
