import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { CardFooter, CardHeader } from "@/common/components/ui/card";
import {
	getCountryCode,
	getCountryFlagUrl,
} from "@/common/utils/country-helpers";

interface FighterCardProps {
	fighter: {
		campId: string;
		fighterName: string;
		fighterImage?: string;
		position: number;
		memberCount: number;
		currentLeaderboardPoints: number;
		wins?: number;
		losses?: number;
		draws?: number;
		category?: string;
		country?: string;
	};
	onJoin: () => void;
}

function FighterCard({ fighter, onJoin }: FighterCardProps) {
	// Default values for missing data
	const record =
		fighter.wins !== undefined
			? `${fighter.wins}-${fighter.losses || 0}-${fighter.draws || 0}`
			: "0-0-0";
	const weightClass = fighter.category || "WEIGHT CLASS";

	return (
		<BaseCard
			className="flex flex-col overflow-hidden cursor-pointer hover:opacity-90 transition-all hover:scale-[1.02] h-full relative bg-black rounded-lg border border-gray-800"
			onClick={onJoin}
		>
			<CardHeader className="pb-0 px-0 pt-0" />
			{/* Main container */}
			<div className="flex flex-col h-full">
				{/* Fighter Image Container */}
				<div className="w-full overflow-hidden relative">
					{fighter.fighterImage ? (
						<img
							src={fighter.fighterImage}
							alt={fighter.fighterName}
							className="w-full h-full object-cover aspect-[3/4]"
						/>
					) : (
						<div className="w-full aspect-[3/4] bg-gray-800 flex items-center justify-center">
							<Typography variant="subtitle">{fighter.fighterName}</Typography>
						</div>
					)}

					{/* Country and Flag */}
					{fighter.country && (
						<div className="absolute bottom-0 left-1/2 -translate-x-1/2 flex items-center gap-1 px-2 py-1 z-20">
							<img
								src={getCountryFlagUrl(fighter.country || "")}
								alt={`${fighter.country} flag`}
								className="h-full object-cover"
								onError={(e) => {
									e.currentTarget.style.display = "none";
								}}
								loading="lazy"
							/>
							<span>{getCountryCode(fighter.country || "").toUpperCase()}</span>
						</div>
					)}

					{/* Gradient overlay at the bottom of the image */}
					<div className="absolute -bottom-4 left-0 right-0 h-[100px] bg-gradient-to-t from-black to-transparent z-10"></div>
				</div>

				<CardFooter className="p-0">
					{/* Fighter Info Section */}
					<div className="p-3 pt-1 flex-grow flex flex-col gap-1 text-center font-chakra w-full">
						{/* Fighter Name */}
						<Typography
							variant="subtitle"
							className="text-white uppercase font-bold text-sm"
						>
							{fighter.fighterName}
						</Typography>

						{/* Record */}
						<div className="text-xs text-white/85">{record}</div>

						{/* Weight Class */}
						<div className="text-xs text-white/85 uppercase">{weightClass}</div>
					</div>
				</CardFooter>
			</div>
		</BaseCard>
	);
}

export default FighterCard;
