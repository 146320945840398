import Button from "@/common/components/design/buttons/button";
import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { useUserStore } from "@/common/hooks/use-user-store";
import { useWalletStore } from "@/common/hooks/use-wallet-store";
import { cn } from "@/common/lib/utils";
import {
	useClaimNFTsMutation,
	useHistoryNFTsQuery,
	useRevalidateNFTsQuery,
} from "@/common/services/nfts.api";
import { Routes } from "@/common/types/routes";
import logger from "@/common/utils/logger";
import { useWallet } from "@/modules/(quest)/referrals/hooks/useWallet";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { ClaimDetails } from "../../components/claim/claim-details";
import ClaimDetailsHistory from "../../components/claim/claim-details-history";
import WalletConnect from "../../components/wallet/wallet-connect";

function WalletConnectView() {
	const isWalletConnected = useWalletStore((store) => store.wallet.loggedIn);
	const userWallet = useWalletStore((store) => store.wallet.addr);
	const updateFFBalance = useUserStore((store) => store.addFFBalance);

	const { signMessage } = useWallet();
	const [claimNFTs, { data: nftClaimingData, isLoading: isClaimingLoading }] =
		useClaimNFTsMutation();
	const { data: nftHistoryData, isLoading: isHistoryLoading } =
		useHistoryNFTsQuery();
	const {
		data: revalidateData,
		isLoading: isRevalidateLoading,
		isFetching: isRevalidateFetching,
		refetch,
	} = useRevalidateNFTsQuery(
		{
			userWallet: userWallet || "",
		},
		{
			skip: !userWallet,
		}
	);

	const calculateTotalPoints = () => {
		try {
			const total =
				revalidateData?.data?.reduce((acc, nft) => acc + nft.points, 0) ?? 0;
			return total.toLocaleString();
		} catch (error) {
			logger.error(error, "wallet-connection | calculate-total-points");
			toast.error("Error calculating total points, please try again");
			return "0";
		}
	};

	const handleRefreshUserNFTs = async () => {
		try {
			const response = await refetch();

			if (response.error) {
				toast.error(response.error.data.message);
			} else {
				response.data?.data.length === 0 && toast.success("No NFTs to claim");
			}
		} catch (error) {
			logger.error(error, "wallet-connection | refresh-nfts");
		}
	};

	const handleClaimNFTsClick = async () => {
		try {
			const signedMessage = await signMessage();

			const response = await claimNFTs({
				nfts: revalidateData?.data.map((nft) => nft.nft_id.toString()) || [],
				questId: "connect-wallet",
				signatures: signedMessage.signatures,
				signedMessage: signedMessage.message,
				userWallet: signedMessage.wallet || "",
			});

			if (response.error) {
				toast.error(response.error.data.message);
			} else {
				await refetch();
				if (response.data?.data.current.summary.totalPoints) {
					updateFFBalance(response.data?.data.current.summary.totalPoints);
				}
			}
		} catch (error) {
			logger.error(error, "wallet-connection | claim-nfts");
		}
	};

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-4 p-4">
				<div className="flex flex-row gap-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">
							Connect your UFC Strike account
						</Typography>
						{isWalletConnected ? (
							<Typography variant="body">
								Each NFT can only be used once to claim and is no longer
								eligible if already claimed, even if traded.
							</Typography>
						) : (
							<Typography variant="body">
								Get $FF per moment based on their rarity.
							</Typography>
						)}
					</div>
				</div>

				<div className="flex flex-col md:flex-row md:justify-evenly gap-4">
					<div className="flex flex-col gap-4 w-full max-w-96">
						{isWalletConnected && (
							<ClaimDetails
								nfts={revalidateData?.data || []}
								refreshOnClick={handleRefreshUserNFTs}
								isLoading={isRevalidateLoading || isRevalidateFetching}
							/>
						)}
						{isWalletConnected && (
							<Button
								onClick={handleClaimNFTsClick}
								disabled={!revalidateData?.data.length || isClaimingLoading}
								className="md:max-w-none bg-primary text-white mt-8"
							>
								{isClaimingLoading ? (
									<Loader2 className="animate-spin" />
								) : (
									<span className="text-xl font-semibold font-variant-numeric-tabular">
										Claim {calculateTotalPoints()} FF
									</span>
								)}
							</Button>
						)}
						<WalletConnect />
					</div>

					<div className="flex flex-col text-center md:text-start gap-4 w-full max-w-96">
						<Typography
							variant="subtitle"
							className={cn(
								"text-2xl font-semibold uppercase",
								!isWalletConnected && "mt-12 md:mt-0"
							)}
						>
							{!isWalletConnected ? "Claim Values" : "Previously Claimed"}
						</Typography>

						<ClaimDetailsHistory
							showAmount={isWalletConnected}
							initialNFTs={nftHistoryData?.data || []}
							updatedNFTs={nftClaimingData?.data.history.claims.nfts || []}
							isLoading={isClaimingLoading || isHistoryLoading}
						/>
					</div>
				</div>
			</BaseCard>
		</div>
	);
}

export default WalletConnectView;
