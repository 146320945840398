import { Skeleton } from "@/common/components/ui/skeleton";

export function ClaimHistorySkeleton() {
	return (
		<div className="flex flex-col gap-4">
			<Skeleton className="h-8 w-48" />
			<div className="flex flex-col gap-4 mt-4">
				<div className="flex justify-between items-center">
					<Skeleton className="h-6 w-24" />
					<Skeleton className="h-6 w-20" />
				</div>
				<div className="flex justify-between items-center">
					<Skeleton className="h-6 w-24" />
					<Skeleton className="h-6 w-20" />
				</div>
			</div>
		</div>
	);
}
