import React from "react";

interface TelegramLogoProps {
	className?: string;
}

const TelegramLogo: React.FC<TelegramLogoProps> = ({ className }) => (
	<svg
		className={`fill-current ${className}`}
		width="40"
		height="36"
		viewBox="0 0 40 36"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M39.2167 1.27728C38.5732 0.68767 37.6586 0.491186 36.8296 0.764389L1.65866 12.3582C0.700144 12.6742 0.0501435 13.5298 0.00272162 14.5378C-0.0446221 15.546 0.522175 16.4588 1.44671 16.8635L10.1448 20.6691C10.1872 20.866 12.6841 32.4469 12.769 32.8406C12.878 33.3462 13.1347 33.7966 13.6783 33.9351C14.2283 34.0752 14.6168 33.7746 15.0271 33.4674C15.2555 33.2965 21.4818 28.6342 21.4818 28.6342L29.0278 34.8092C29.4673 35.169 30.0045 35.3567 30.5523 35.3567C30.813 35.3566 31.0763 35.3141 31.3324 35.2272C32.1266 34.9579 32.7154 34.3097 32.9073 33.4934L39.9359 3.6104C40.1357 2.7608 39.8602 1.86689 39.2167 1.27728ZM15.5894 22.8498C15.5851 22.8599 15.5808 22.8714 15.5769 22.8852L14.1219 27.9686L12.4981 20.4377L23.6635 14.2342L15.8572 22.3966C15.7342 22.5252 15.6437 22.6806 15.5894 22.8498ZM16.0512 29.7658L16.712 27.4568L17.3441 25.2483L19.6091 27.1017L16.0512 29.7658ZM37.649 3.07244L30.6205 32.9554C30.617 32.9702 30.6123 32.9907 30.578 33.0024C30.5439 33.0139 30.5273 33.0007 30.5156 32.991L22.2585 26.2342C22.2583 26.234 22.2581 26.2338 22.2579 26.2336L18.4322 23.103L30.723 10.2516C31.1244 9.83181 31.1588 9.18173 30.8039 8.72197C30.4488 8.26212 29.8112 8.1308 29.3036 8.41298L11.1043 18.5246L2.38842 14.7111C2.36186 14.6995 2.34725 14.6931 2.34936 14.6481C2.35147 14.6034 2.36663 14.5983 2.39413 14.5892L37.5651 2.99548C37.5821 2.98994 37.6013 2.98353 37.6296 3.00931C37.6579 3.03525 37.6532 3.05494 37.649 3.07244Z"
			fill="currentColor"
		/>
	</svg>
);

export default TelegramLogo;
