import BaseCard from "@/common/components/design/card/base-card";
import {
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/common/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from "@/common/components/ui/chart";
import { Skeleton } from "@/common/components/ui/skeleton";
import { useMemo } from "react";
import { Label, Pie, PieChart } from "recharts";
import { useGetReferralsKPIQuery } from "../../services/admin.api";

export function ReferralsKPI() {
	const { data: referralsKPIData, isLoading } = useGetReferralsKPIQuery();

	const totalReferralsCompleted = useMemo(
		() =>
			referralsKPIData?.data.items.reduce(
				(acc, referral) => acc + referral.totalReferrals,
				0
			) ?? 0,
		[referralsKPIData?.data.items]
	);

	const chartConfig = useMemo(() => {
		if (!referralsKPIData?.data.items) return {};

		return referralsKPIData.data.items.reduce(
			(acc, item, index) => ({
				...acc,
				[item.referralSource]: {
					label: item.referralSource,
					color: `hsl(var(--chart-${index + 1}))`,
				},
			}),
			{}
		) satisfies ChartConfig;
	}, [referralsKPIData?.data.items]);

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
			<BaseCard className="bg-muted rounded-sm">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium">Total Referrals</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-2xl font-bold">
						{totalReferralsCompleted.toLocaleString()}
					</div>
					<p className="text-xs text-muted-foreground">
						Referrals completed since the beginning
					</p>
				</CardContent>
			</BaseCard>
			<BaseCard className="bg-muted col-span-4 rounded-sm">
				<CardHeader>
					<CardTitle>Referral Types</CardTitle>
				</CardHeader>
				{isLoading ? (
					<Skeleton className="w-6/12 h-56" />
				) : (
					<CardContent className="pl-2">
						<ChartContainer
							config={chartConfig}
							className="mx-auto aspect-square max-h-[250px]"
						>
							<PieChart>
								<ChartTooltip
									cursor={false}
									content={<ChartTooltipContent hideLabel />}
								/>
								<Pie
									data={referralsKPIData?.data.items.map((referral, index) => ({
										source: referral.referralSource,
										total: referral.totalReferrals,
										fill: `hsl(var(--chart-${index + 1}))`,
									}))}
									dataKey="total"
									nameKey="source"
									innerRadius={60}
									strokeWidth={5}
								>
									<Label
										content={({ viewBox }) => {
											if (viewBox && "cx" in viewBox && "cy" in viewBox) {
												return (
													<text
														x={viewBox.cx}
														y={viewBox.cy}
														textAnchor="middle"
														dominantBaseline="middle"
													>
														<tspan
															x={viewBox.cx}
															y={viewBox.cy}
															className="fill-foreground text-3xl font-bold"
														>
															{totalReferralsCompleted.toLocaleString()}
														</tspan>
														<tspan
															x={viewBox.cx}
															y={(viewBox.cy || 0) + 24}
															className="fill-muted-foreground"
														>
															Visitors
														</tspan>
													</text>
												);
											}
										}}
									/>
								</Pie>
								<ChartLegend
									content={<ChartLegendContent nameKey="source" />}
									className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
								/>
							</PieChart>
						</ChartContainer>
					</CardContent>
				)}
				<CardFooter className="flex-col gap-2 text-sm">
					<div className="leading-none text-muted-foreground">
						Showing total referrals completed by source
					</div>
				</CardFooter>
			</BaseCard>
		</div>
	);
}
