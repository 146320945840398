/* eslint-disable @typescript-eslint/no-explicit-any */
// logger.ts
import { appEnvironment } from "@/environment";
import chalk from 'chalk';

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

interface LogConfig {
  level: LogLevel;
  production: boolean;
  timestamp: boolean;
  context?: string;
}

class Logger {
  private config: LogConfig = {
    level: LogLevel.INFO,
    production: appEnvironment === 'prod',
    timestamp: true
  };

  private formatMessage(level: LogLevel, message: any, context?: string): string {
    const timestamp = this.config.timestamp ? `${new Date().toISOString()} ` : '';
    const ctx = context ? `[${context}] ` : '';
    const msg = typeof message === 'object' ? JSON.stringify(message, null, 2) : message;
    return `${timestamp}${level} ${ctx}${msg}`;
  }

  private shouldLog(level: LogLevel): boolean {
    const levels = Object.values(LogLevel);
    return levels.indexOf(level) >= levels.indexOf(this.config.level);
  }

  setConfig(config: Partial<LogConfig>) {
    this.config = { ...this.config, ...config };
  }

  debug(message: any, context?: string) {
    if (!this.shouldLog(LogLevel.DEBUG)) return;
    const formattedMessage = this.formatMessage(LogLevel.DEBUG, message, context);
    console.debug(chalk.gray(formattedMessage));
  }

  info(message: any, context?: string) {
    if (!this.shouldLog(LogLevel.INFO)) return;
    const formattedMessage = this.formatMessage(LogLevel.INFO, message, context);
    console.info(chalk.blue(formattedMessage));
  }

  warn(message: any, context?: string) {
    if (!this.shouldLog(LogLevel.WARN)) return;
    const formattedMessage = this.formatMessage(LogLevel.WARN, message, context);
    console.warn(chalk.yellow(formattedMessage));
  }

  error(message: any, context?: string) {
    if (!this.shouldLog(LogLevel.ERROR)) return;
    const formattedMessage = this.formatMessage(LogLevel.ERROR, message, context);
    console.error(chalk.red(formattedMessage));
    
    if (message instanceof Error) {
      console.error(chalk.red(message.stack));
    }
  }

  // Utility method to create contextualized logger
  createLogger(context: string) {
    return {
      debug: (message: any) => this.debug(message, context),
      info: (message: any) => this.info(message, context),
      warn: (message: any) => this.warn(message, context),
      error: (message: any) => this.error(message, context)
    };
  }
}

export const logger = new Logger();

// Export default instance
export default logger;