import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { Routes } from "@/common/types/routes";
import { ClaimHistory } from "../../components/claim-history";
import { ClaimInstructions } from "../../components/claim-instructions";
import { ClaimPointsButton } from "../../components/claim-points-button";
import { RedirectForgeButton } from "../../components/redirect-forge-button";
import { useUserStore } from "@/common/hooks/use-user-store";

function QuestCampView() {
	const userCampMember = useUserStore((state) => state.user.campMember);

	if (!userCampMember) {
		return <div>You are not a member of a camp</div>;
	}

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-4 p-4 min-h-full md:min-h-fit">
				<div className="flex flex-row gap-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">
							{userCampMember.camp?.campName}'s Victory Challenge
						</Typography>
						<Typography variant="body">
							Complete weekly quests on Forge to earn points for both you and
							{userCampMember.camp?.fighterName}. Show your support and climb the
							leaderboard together!
						</Typography>
					</div>
				</div>

				<div className="flex flex-col md:flex-row gap-y-8 md:justify-evenly md:items-start">
					<div className="flex flex-col gap-4 w-full max-w-96">
						<ClaimPointsButton />

						<ClaimInstructions />

						<RedirectForgeButton />
					</div>
					<div className="flex flex-col text-center md:text-start gap-4 w-full max-w-96">
						<ClaimHistory />
					</div>
				</div>
			</BaseCard>
		</div>
	);
}

export default QuestCampView;
