import StatsCard from "@/common/components/design/stats-card/stats-card";

interface PointsSummaryProps {
	isLoading: boolean;
	totalReferrals?: number;
	totalPoints?: number;
}

function PointsSummary({
	isLoading,
	totalReferrals,
	totalPoints,
}: PointsSummaryProps) {
	return (
		<div className="flex flex-row gap-4 w-full text-left">
			<StatsCard
				title="Friends Connected"
				isLoading={isLoading}
				value={totalReferrals}
			/>
			<StatsCard title="FF Earned" isLoading={isLoading} value={totalPoints} />
		</div>
	);
}

export default PointsSummary;
