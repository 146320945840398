import Typography from "@/common/components/design/texts/typography";
import MailIcon from "../icons/mail-icon";

function SendingEmail() {
	return (
		<div className="flex flex-col items-center text-center justify-center w-full gap-y-4 min-h-[80vh]">
			<MailIcon />

			<Typography variant="title">Email SENT!</Typography>
			<Typography variant="body" className="text-white">
				We’ve sent a one-time password to your email. Please check your inbox.
			</Typography>
		</div>
	);
}

export default SendingEmail;
