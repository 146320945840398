import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Quest } from "../types/entities/quest/quest";
import { QuestId } from "../constants/quest-ids";

type State = {
	enabledQuestIds: QuestId[];
	isQuestEnabled: (questId: QuestId) => boolean;
};

type Action = {
	setEnabledQuests: (quests: Quest[]) => void;
	reset: () => void;
};

export type QuestsStoreState = State & Action;

export const useQuestsStore = create<QuestsStoreState>()(
	devtools(
		(set, get) => ({
			enabledQuestIds: [],

			isQuestEnabled: (questId: QuestId) => {
				return get().enabledQuestIds.includes(questId);
			},

			setEnabledQuests: (quests: Quest[]) => {
				const enabledQuestIds = quests
					.filter((quest) => quest.enabled)
					.map((quest) => quest.id as QuestId);

				set({ enabledQuestIds });
			},

			reset: () => {
				set({ enabledQuestIds: [] });
			},
		}),
		{
			name: "quests-store",
		}
	)
);
