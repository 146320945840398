import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import routeReducer from "./route-slice";
import { authApi } from "../services/auth.api";
import { userApi } from "../services/user.api";
import { campsApi } from "../services/camps.api";
import { questsApi } from "../services/quests.api";
import { referralsApi } from "../services/referrals.api";
import { nftsApi } from "../services/nfts.api";
import { forgeggApi } from "../services/forgegg.api";
import { lightsoutApi } from "../services/lightsout.api";
import { adminApi } from "@/modules/admin/services/admin.api";

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[campsApi.reducerPath]: campsApi.reducer,
		[questsApi.reducerPath]: questsApi.reducer,
		[referralsApi.reducerPath]: referralsApi.reducer,
		[nftsApi.reducerPath]: nftsApi.reducer,
		[forgeggApi.reducerPath]: forgeggApi.reducer,
		[lightsoutApi.reducerPath]: lightsoutApi.reducer,
		[adminApi.reducerPath]: adminApi.reducer,
		route: routeReducer,
	},
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(authApi.middleware)
			.concat(userApi.middleware)
			.concat(campsApi.middleware)
			.concat(questsApi.middleware)
			.concat(referralsApi.middleware)
			.concat(nftsApi.middleware)
			.concat(forgeggApi.middleware)
			.concat(lightsoutApi.middleware)
			.concat(adminApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
