import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "@/common/components/ui/avatar";
import { useUserStore } from "@/common/hooks/use-user-store";
import { cn } from "@/common/lib/utils";
import { useGetCampsLeaderboardQuery } from "@/common/services/camps.api";
import { useMemo } from "react";
import { CampCardSkeleton } from "../../components/skeletons/camp-card-skeleton";
import { CampListSkeleton } from "../../components/skeletons/camp-list-skeleton";
import MainButton from "@/common/components/design/buttons/button";
import { ArrowRight } from "lucide-react";
import { Routes } from "@/common/types/routes";
import { useNavigate } from "react-router-dom";

function CampsLeaderboardsView() {
	const navigate = useNavigate();
	const userCamp = useUserStore((state) => state.user.campMember);

	const { data: camps, isLoading: isLoadingCamps } =
		useGetCampsLeaderboardQuery();

	const userCampPosition = useMemo(() => {
		if (!camps?.data || !userCamp?.camp?.id) return "-";

		const index = camps.data.findIndex(
			(camp) => camp.campId === userCamp.camp?.id
		);

		// Return position (1-based) or fallback if not found
		return index !== -1 ? (index + 1).toString() : "-";
	}, [camps?.data, userCamp?.camp?.id]);

	const handleJoinCampClick = () => {
		navigate(Routes.CampChoose);
	};

	return (
		<div className="flex flex-col w-full h-full gap-y-4">
			{/* User's Camp Card */}
			{isLoadingCamps ? (
				<CampCardSkeleton />
			) : userCamp?.camp ? (
				<BaseCard className="p-4 md:p-10">
					<div className="flex flex-col md:flex-row justify-between gap-6">
						<div className="flex flex-col gap-2">
							<Typography
								variant="body"
								className="text-muted-foreground text-sm"
							>
								Your Camp for This Week
							</Typography>
							<Typography variant="title" className="uppercase font-bold">
								{userCamp.camp.campName}
							</Typography>
						</div>

						<div className="flex justify-between items-center gap-20">
							<div className="flex flex-col gap-2 order-2 md:order-1">
								<Typography
									variant="body"
									className="text-muted-foreground text-sm"
								>
									Position
								</Typography>
								<Typography
									variant="body"
									className="text-terciary font-bold text-lg"
								>
									{userCampPosition}
								</Typography>
							</div>

							<div className="flex flex-col gap-2 order-1 md:order-2">
								<Typography
									variant="body"
									className="text-muted-foreground text-sm"
								>
									Camp Earnings
								</Typography>
								<Typography
									variant="body"
									className="text-white font-bold text-lg"
								>
									{userCamp.camp.currentLeaderboardPoints?.toLocaleString() ||
										"-"}{" "}
									$FF
								</Typography>
							</div>
						</div>
					</div>
				</BaseCard>
			) : (
				<div className="flex flex-col md:flex-row justify-between items-center gap-4">
					<Typography variant="title" className="uppercase font-bold">
						Pick Your Fighter, Build Your Camp, Earn Exclusive Rewards
					</Typography>

					<MainButton
						className="uppercase w-full md:w-56 h-16 font-semibold text-xl mt-auto [&_svg]:size-6"
						onClick={handleJoinCampClick}
					>
						Join Now
						<ArrowRight className="h-6 w-6 ml-2" />
					</MainButton>
				</div>
			)}

			{/* Leaderboard List */}
			<div className="space-y-2 mt-4">
				{isLoadingCamps ? (
					<CampListSkeleton count={8} />
				) : (
					camps?.data?.map((camp) => (
						<div
							key={camp.position}
							className={cn(
								"flex items-center justify-between p-4 rounded-sm",
								camp.campId === userCamp?.camp?.id
									? "border border-terciary"
									: "",
								"bg-bgcontainer"
							)}
						>
							<div className="flex items-center gap-4">
								<span className="text-lg font-semibold w-8">
									{camp.position}
								</span>
								<Avatar>
									<AvatarImage
										src={camp.fighterImage}
										className="object-scale-down"
									/>
									<AvatarFallback>{camp.name[0]}</AvatarFallback>
								</Avatar>
								<span className="text-base font-medium">{camp.name}</span>
							</div>
							<span className="text-base font-bold">
								$FF {camp.currentLeaderboardPoints.toLocaleString()}
							</span>
						</div>
					))
				)}
			</div>
		</div>
	);
}

export default CampsLeaderboardsView;
