import { Routes } from "@/common/types/routes";
import { useLoadingStore } from "@/modules/login/hooks/use-loading";
import FightIdReady from "@/modules/login/components/loading-screens/fightid-ready";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOAuthStore } from "../../hooks/use-oauth-store";

function ClaimFightidSuccessView() {
	const navigate = useNavigate();
	const loadingState = useLoadingStore((store) => store.loadingState);
	const updateLoadingState = useLoadingStore(
		(store) => store.updateLoadingState
	);
	const oauthParams = useOAuthStore((state) => state.params);

	useEffect(() => {
		const timer = setTimeout(() => {
			updateLoadingState("idle");

			// Check if we have stored OAuth params
			if (oauthParams) {
				// Convert params to URL search params format
				const searchParams = new URLSearchParams({
					client_id: oauthParams.clientId,
					redirect_uri: oauthParams.redirectUri,
					...(oauthParams.scope && { scope: oauthParams.scope }),
					...(oauthParams.state && { state: oauthParams.state }),
				});

				navigate(`/oauth/consent?${searchParams.toString()}`);
				return;
			}

			// If no OAuth params, go to home
			navigate(Routes.Home);
		}, 3000);

		// Cleanup timer on unmount
		return () => clearTimeout(timer);
	}, [navigate, loadingState, updateLoadingState, oauthParams]);

	return <FightIdReady />;
}

export default ClaimFightidSuccessView;
