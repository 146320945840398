import React from "react";

interface XLogoProps {
	className?: string;
}

const XLogo: React.FC<XLogoProps> = ({ className }) => (
	<svg
		className={`fill-current ${className}`}
		width="40"
		height="40"
		viewBox="0 0 40 40"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.2764 23.0627L1.70568 40H5.15847L17.8102 25.2936L27.9151 40H39.5699L24.2893 17.7613L39.5699 0H36.117L22.7564 15.5305L12.0849 0H0.430042L16.2772 23.0627H16.2764ZM21.0057 17.5654L22.554 19.7799L34.8728 37.4007H29.5692L19.6278 23.1801L18.0795 20.9656L5.15684 2.48114H10.4604L21.0057 17.5646V17.5654Z"
			fill="currentColor"
		/>
	</svg>
);

export default XLogo;
