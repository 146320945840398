import { useUserStore } from "@/common/hooks/use-user-store";
import {
	useLazyGetSiwaQuery,
	useSiwaCallbackMutation,
} from "@/common/services/auth.api";
import { Routes } from "@/common/types/routes";
import logger from "@/common/utils/logger";
import { AptosSignInOutput, useWallet } from "@aptos-labs/wallet-adapter-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import MainButton from "../design/buttons/button";
import GoogleIcon from "@/modules/login/components/icons/google-icon";

export function WalletSelector() {
	const navigate = useNavigate();
	const { signIn } = useWallet();
	const updateUser = useUserStore((state) => state.updateUserWithAptosSignin);

	const [getSiwa, { isLoading: isGetLoading }] = useLazyGetSiwaQuery();
	const [postSiwaCallback, { isLoading: isPostLoading }] =
		useSiwaCallbackMutation();

	/**
	 * Extracts email from the sign-in output resources
	 */
	const extractEmail = useCallback((output: AptosSignInOutput) => {
		return (
			output?.input?.resources
				?.find((resource) => resource.startsWith("aptosconnect.app.email"))
				?.split(":")
				?.at(1) || ""
		);
	}, []);

	/**
	 * Handles the sign-in process with Google via Aptos wallet
	 */
	const handleSignIn = useCallback(async () => {
		try {
			// Get SIWA (Sign In With Aptos) data
			const getSiwaResponse = await getSiwa();
			if (!getSiwaResponse?.data?.data) {
				toast.error("Failed to initialize sign-in process");
				return;
			}

			const input = getSiwaResponse.data.data;

			// Sign in with wallet
			const output = await signIn({
				walletName: "Continue with Google",
				input,
			});

			if (!output || !("input" in output)) {
				toast.error("Sign-in process was interrupted");
				return;
			}

			// Extract email from output
			const email = extractEmail(output);

			// Post callback to complete authentication
			const responsePostCallback = await postSiwaCallback({
				input,
				output: {
					address: output.account.address.toString(),
					email,
					signature: output.signature.toString(),
					nonce: output.input.nonce,
					domain: window.location.origin,
					message: output.plainText,
					fullMessage: output.plainText,
					timestamp: Date.now(),
					statement: input.statement,
				},
			});

			if (!responsePostCallback?.data?.data) {
				toast.error("Authentication failed");
				return;
			}

			// Update user state and navigate
			updateUser(
				responsePostCallback.data.data,
				responsePostCallback.data.data.accessToken
			);
			navigate(Routes.AuthLoading);
		} catch (error) {
			logger.error(error, "Wallet Selector");
			if (typeof error === "string" && error.includes("open prompt")) {
				toast.error(
					"Pop-up blocked: Please allow pop-ups for this site in your browser settings and try again"
				);
			} else {
				toast.error("Failed to sign in. Please try again.");
			}
		}
	}, [getSiwa, signIn, postSiwaCallback, extractEmail, updateUser, navigate]);

	return (
		<MainButton
			isLoading={isGetLoading || isPostLoading}
			disabled={isGetLoading || isPostLoading}
			onClick={handleSignIn}
			variant="ghost"
			className="[&_svg]:size-6"
		>
			<GoogleIcon className="h-6 w-6" />
			Connect with Google
		</MainButton>
	);
}
