import BaseCard from "@/common/components/design/card/base-card";

interface ReferralHistoryItem {
	referredUserId: string;
	points: number;
}

interface ReferralHistoryProps {
	history: ReferralHistoryItem[];
}

function ReferralHistory({ history }: ReferralHistoryProps) {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
			{history.map((item, index) => (
				<BaseCard key={index}>
					<div className="flex items-center justify-between px-4 py-2 pl-12 font-medium text-base">
						<div className="flex items-center gap-4">
							<span className="">{item.referredUserId}.fight</span>
						</div>
						<span className="">{item.points}FF</span>
					</div>
				</BaseCard>
			))}
		</div>
	);
}

export default ReferralHistory;
