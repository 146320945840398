import { useGetClaimablePointsQuery, useClaimPointsMutation } from "@/common/services/forgegg.api";
import logger from "@/common/utils/logger";
import MainButton from "@/common/components/design/buttons/button";

export function ClaimPointsButton() {
	const { data: claimablePointsData, isLoading: claimablePointsIsLoading } =
		useGetClaimablePointsQuery();

	const [claimPoints, { isLoading: claimPointsIsLoading }] =
		useClaimPointsMutation();

	// Add helper function to calculate total points
	const getTotalPoints = () => {
		try {
			return (
				claimablePointsData?.data.records.reduce(
					(acc, record) => acc + record.points,
					0
				) || 0
			);
		} catch (error) {
			logger.error(error, "GetTotalPoints | ForgeggHomeView");
			return 0;
		}
	};

	const handleClaimPoints = async () => {
		try {
			await claimPoints().unwrap();
		} catch (error) {
			logger.error(error, "HandleClaimPoints | ClaimPointsButton");
		}
	};

	return (
		<MainButton
			className="px-6 mt-2 w-fit font-semibold text-xl"
			disabled={
				claimablePointsIsLoading ||
				getTotalPoints() === 0
			}
      isLoading={claimablePointsIsLoading || claimPointsIsLoading}
      isLoadingLabel={claimablePointsIsLoading ? "Loading..." : "Claiming..."}
			onClick={handleClaimPoints}
		>
			Claim {getTotalPoints()} $FF
		</MainButton>
	);
}