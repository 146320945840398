import { NavBackButton } from "@/common/components/design/buttons/nav-back-button";
import BaseCard from "@/common/components/design/card/base-card";
import Typography from "@/common/components/design/texts/typography";
import { useWalletStore } from "@/common/hooks/use-wallet-store";
import { Routes } from "@/common/types/routes";
import { ClaimDetails } from "../../components/claim/claim-details";
import WalletConnect from "../../components/wallet/wallet-connect";
import ClaimDetailsHistory from "../../components/claim/claim-details-history";

function LightsOutView() {
	const isWalletConnected = useWalletStore((store) => store.wallet.loggedIn);

	return (
		<div className="flex flex-col w-full h-full gap-y-4 md:gap-y-12 justify-start items-center text-left md:items-start">
			<BaseCard className="flex flex-col gap-6 p-4 min-h-full">
				<div className="flex flex-row gap-4">
					<div>
						<NavBackButton to={Routes.Home} />
					</div>

					<div className="flex flex-col gap-2">
						<Typography variant="title">Lights Out II bonus </Typography>
						{!isWalletConnected && (
							<Typography variant="body">
								Earn 100 $FF per Base pack and 1,000 $FF per Elite pack.{" "}
							</Typography>
						)}
					</div>
				</div>

				<div className="flex flex-col md:flex-row md:justify-evenly gap-4">
					<div className="flex flex-col">
						<ClaimDetails />

						<WalletConnect />
					</div>

					<ClaimDetailsHistory />
				</div>
			</BaseCard>
		</div>
	);
}

export default LightsOutView;
