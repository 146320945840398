/**
 * Constants for quest IDs used throughout the application
 * Centralizing these values prevents typos and makes maintenance easier
 */


// modules
const MODULES = {
  MODULE_SETTINGS: "module-settings",
} as const;

export const QUEST_IDS = {
	// Camp-related quests
	CAMP_FORGE: "camp-forge",
	CAMP_FORGE_BANNER: "camp-forge-banner",
	
  TWITTER_TICKET_GIVEAWAY_FEB: "twitter-ticket-giveaway-feb",

  // Referral Quest
  REFERRAL_QUEST: "referral-quest",

  // Connect Wallet - NFTs Quest
  CONNECT_WALLET: "connect-wallet",
  GIGANTIK: "gigantik",

  // Quest Partners
  FORGE_QUEST: "forge-quest",

  // Modules
  ...MODULES
} as const;


// Type for quest IDs to enable type checking
export type QuestId = (typeof QUEST_IDS)[keyof typeof QUEST_IDS];

// Helper function to check if a string is a valid quest ID
export const isValidQuestId = (id: string): id is QuestId => {
	return Object.values(QUEST_IDS).includes(id as QuestId);
};
