import { cn } from "@/common/lib/utils";
import Button from "@/common/components/design/buttons/button";
import LoadingIcon from "@/common/components/icons/loading-icon";

type NFTData = {
	nft_id: number;
	tier: string;
	points: number;
};

const TIERS = [
	{ category: "Common", price: "5", enabled: true },
	{ category: "Uncommon", price: "100", enabled: false },
	{ category: "Rare", price: "1,000", enabled: false },
	{ category: "Fandom", price: "0", enabled: false },
	{ category: "Epic", price: "5,000", enabled: false },
	{ category: "Champion", price: "10,000", enabled: false },
	{ category: "Ultimate", price: "1,000,000", enabled: false },
] as const;

interface ClaimDetailsProps {
	nfts?: NFTData[];
	refreshOnClick?: () => void;
	isLoading?: boolean;
}

export function ClaimDetails({
	nfts = [],
	refreshOnClick,
	isLoading = false,
}: ClaimDetailsProps) {
	const calculateAmounts = (data: NFTData[]) => {
		const counts = new Map<string, number>();

		// Initialize all tiers with 0
		TIERS.forEach((tier) => {
			counts.set(tier.category, 0);
		});

		// Count occurrences of each tier
		data.forEach((nft) => {
			const currentCount = counts.get(nft.tier) || 0;
			counts.set(nft.tier, currentCount + 1);
		});

		return counts;
	};

	const tierAmounts = calculateAmounts(nfts || []);

	return (
		<div className="w-full max-w-2xl mx-auto">
			<div className="space-y-2">
				<div className="flex justify-end items-center">
					<Button
						variant="ghost"
						className="w-fit hover:bg-transparent disabled:bg-transparent"
						onClick={refreshOnClick}
						disabled={isLoading}
					>
						<LoadingIcon
							className={cn("text-white/70", isLoading && "animate-spin")}
						/>
						<span className="text-lg text-white/70 normal-case font-normal">
							Refresh
						</span>
					</Button>
				</div>
				{TIERS.map((tier) => (
					<div
						key={tier.category}
						className={cn(
							"grid items-center font-medium grid-cols-4 text-left"
						)}
					>
						<div className={tier.enabled ? "col-span-1" : "col-span-1"}>
							{tier.category}
						</div>
						<div
							className={cn(
								"col-span-3 text-right",
								tier.enabled ? "col-span-2" : "col-span-3"
							)}
						>
							{tier.enabled ? (
								<>
									{tier.price}
									<span className="text-xs">FF</span>
								</>
							) : (
								<span>Coming Soon</span>
							)}
						</div>
						{tier.enabled && (
							<div className="text-right">
								x{tierAmounts.get(tier.category)}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default ClaimDetails;
